<template>
  <div class="aroma-spider">
    <Radar class="aroma-spider__chart" :data="chartData" :options="chartOptions" />
    <div class="aroma-spider__labels">
      <img
        src="@/../assets/spider-graph-labels.svg"
        class="aroma-spider__labels__img"
        alt="Labels for accompanying chart, for a more accessible version, please visit the Table View"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Radar } from 'vue-chartjs'
import {
  Chart,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  type ChartData
} from 'chart.js'
import { opacityColors, contrastColors, lightOpacityColors } from '@/components/LotLookup'

Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'
import { computed } from 'vue'

const lotStore = useLotStore()

const plugins = {
  legend: {
    display: false
  },
  tooltip: {
    backgroundColor: '#282928'
  }
}

const interaction = {
  intersect: false,
  mode: 'index',
  axis: 'xy'
}

const labels = [
  'Berry',
  'Stone Fruit',
  'Pomme',
  'Melon',
  'Tropical',
  'Citrus',
  'Floral',
  'Herbal',
  'Vegetal',
  'Grassy',
  'Earthy',
  'Woody',
  'Spicy',
  'Sweet Aromatic',
  'Dried Fruit'
]

const chartData = computed(() => {
  let datasets = []
  if (lotStore.lots.length !== 1) {
    for (let i = 0; i < lotStore.lots.length; i++) {
      if (lotStore.lots[i].aromas && lotStore.lots[i].aromas.length > 0) {
        datasets.push({
          label: lotStore.lots[i].lotNumber ?? 'Lot ' + i + 1,
          data: getLotDataSet(lotStore.lots[i], labels),
          backgroundColor: opacityColors[i],
          borderColor: lightOpacityColors[i],
          pointBackgroundColor: contrastColors[i],
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: contrastColors[i]
        })
      }
    }
  } else {
    datasets.push({
      label: lotStore.lots[0].lotNumber ?? 'Lot 1',
      data: getLotDataSet(lotStore.lots[0], labels),
      backgroundColor: opacityColors[0],
      borderColor: lightOpacityColors[0],
      pointBackgroundColor: contrastColors[0],
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: contrastColors[0]
    })
  }

  return {
    labels: labels,
    datasets
  } as ChartData<'radar'>
})

const getLotDataValue = (lot: Lot, name: string) => {
  let datapoint = lot.aromas.find((aroma) => aroma.name === name)
  if (datapoint) {
    return datapoint.intensity / 100
  } else return 0
}

const getLotDataSet = (lot: Lot, labels: string[]) => {
  let dataset = []
  for (let i = 0; i < labels.length; i++) {
    dataset.push(getLotDataValue(lot, labels[i]))
  }
  return dataset
}

const chartOptions = computed(() => {
  return {
    responsive: true,
    maintainAspectRatio: true,
    interaction,
    scales: {
      r: {
        // display: false,
        angleLines: {
          // display: false
          color: '#CECFD6'
        },

        ticks: {
          display: true,
          color: '#002B49',
          stepSize: 0.25,
          font: {
            size: 10.24,
            weight: 'bold'
          },
          format: {
            style: 'percent'
          },
          callback: function (ticks: string) {
            return (parseFloat(ticks) * 100).toFixed() + '%'
          }
        },
        min: 0,
        max: 1,
        grid: {
          circular: true,
          color: '#CECFD6'
        },
        pointLabels: {
          color: '#002B49',
          font: {
            weight: 'bold',
            size: 10.24
          },
          callback: function (value: string) {
            return value.toUpperCase()
          },
          display: false
        }
      }
    },
    elements: {
      point: {
        pointStyle: false
      }
    },
    plugins: plugins
  }
})
</script>
<style scoped lang="scss">
.aroma-spider {
  max-width: 100%;
  width: 100%;
  padding: 4%;
  margin: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 1/1;

  &__chart {
    width: 100% !important;
    background: transparent;
    display: block;
    padding: 0;
    margin: 0;
    aspect-ratio: 1/1;
    height: auto !important;

    p {
      display: none;
    }
  }

  &__labels {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;

    &__img {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;
    }
  }
}
</style>
