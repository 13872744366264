import { computed } from 'vue'
import type { ChartOptions } from 'chart.js'
import useLotStore from '@/stores/LotStore'

const interaction = {
  intersect: false,
  mode: 'index',
  axis: 'y'
}

const plugins = {
  legend: {
    display: false
  },
  tooltip: {
    backgroundColor: '#282928',
    callbacks: {
      label: function (context: any) {
        let label = context.dataset.label || ''
        if (label) {
          label += ': '
        }
        const dataIndex = context.dataIndex
        if (context.dataset.data[dataIndex] === null) {
          return label + 'Unavailable'
        }
        const value = context.dataset.data[dataIndex] * 100 // Multiplying by 100 to convert to percentage
        label += parseFloat(value.toString()).toFixed(1) + '%' // Adding '%' after multiplying
        return label
      }
    }
  }
}

const survivablePlugins = {
  legend: {
    display: false
  },
  tooltip: {
    backgroundColor: '#282928',
    callbacks: {
      label: function (context: any) {
        let label = context.dataset.label || ''
        if (label) {
          label += ': '
        }
        const dataIndex = context.dataIndex
        if (context.dataset.data[dataIndex] === null) {
          return label + 'Unavailable'
        }
        label += context.dataset.data[dataIndex]
        return label
      }
    }
  }
}

const percentageChartOptions = computed(() => {
  const lotStore = useLotStore()
  let barPercentage = 1
  let categoryPercentage = 0.8
  if (lotStore.lots.length === 1) {
    barPercentage = 0.5
    categoryPercentage = 1
  }

  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: barPercentage,
    categoryPercentage: categoryPercentage,

    interaction,
    layout: {
      padding: {
        left: -8,
        bottom: -8,
        right: 0,
        top: 0
      },
      autoPadding: false
    },
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      x: {
        min: 0,
        max: 1,
        ticks: {
          stepSize: 100,
          padding: 0,
          format: {
            style: 'percent'
          },
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    plugins: plugins
  } as ChartOptions<'bar'>
})

const survivableCompoundsChartOptions = computed(() => {
  const lotStore = useLotStore()
  let barPercentage = 1
  let categoryPercentage = 0.75
  if (lotStore.lots.length === 1) {
    barPercentage = 0.5
    categoryPercentage = 1
  }

  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: barPercentage,
    categoryPercentage: categoryPercentage,
    interaction,
    layout: {
      padding: {
        left: -8,
        bottom: -8,
        right: 0,
        top: 0
      },
      autoPadding: false
    },
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      x: {
        max: 500,
        ticks: {
          padding: 0,
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    plugins: survivablePlugins
  } as ChartOptions<'bar'>
})

export { percentageChartOptions, survivableCompoundsChartOptions }
