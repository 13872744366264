import LotLookupSingle from '@/components/LotLookup/LotLookupSingle.vue'
import Lot from '@/types/Lot'

const lotColors = ['#D22630', '#002B49', '#D2AB89']

const contrastColors = ['#C41621', '#004A7D', '#C08759']

const opacityColors = ['rgba(210,38,48,0.2)', 'rgba(0,43,73,0.2)', 'rgba(210,171,137,0.2)']
const lightOpacityColors = ['rgba(210,38,48,0.7)', 'rgba(0,43,73,0.7)', 'rgba(210,171,137,0.7)']

export const getLotAlpha = (lot: Lot) => {
  if (lot.brewingValues) {
    const brewingValues = lot.brewingValues
    if (brewingValues.uvAlpha) {
      return { name: 'UV Alpha', value: brewingValues.uvAlpha }
    }
    if (brewingValues.uvIsoAlpha) {
      return { name: 'UV ISO Alpha', value: brewingValues.uvIsoAlpha }
    }
    if (brewingValues.hplcAlpha) {
      return { name: 'HPLC Alpha', value: brewingValues.hplcAlpha }
    }
    if (brewingValues.hplcRho) {
      return { name: 'HPLC Rho Alpha', value: brewingValues.hplcRho }
    }
    if (brewingValues.lcvAlpha) {
      return { name: 'LCV Alpha 7.4', value: brewingValues.lcvAlpha }
    }
    if (brewingValues.mebakAlpha) {
      return { name: 'Mebak Alpha 7.5', value: brewingValues.mebakAlpha }
    }
    if (brewingValues.hplcIsoAlpha) {
      return { name: 'HPLC Iso-Alpha', value: brewingValues.hplcIsoAlpha }
    }
    if (brewingValues.hplcHex) {
      return { name: 'HPLC Hexa Alpha', value: brewingValues.hplcHex }
    }
    if (brewingValues.hplcTet) {
      return { name: 'HPLC Tetra Alpha', value: brewingValues.hplcTet }
    }
  }
  return null
}

export default LotLookupSingle

export { lotColors, opacityColors, contrastColors, lightOpacityColors }
