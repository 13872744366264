import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import { type RemovableRef, useStorage } from '@vueuse/core'
import type LotAnalytics from '@/types/Lot'
import { toast } from '@yakimachief/ych-ts-component-library'

const lotNumberHistory: RemovableRef<string[]> = useStorage('lot-history', [])

const addLotToHistory = (lotNumber: string) => {
  if (lotNumberHistory.value.includes(lotNumber)) {
    const foundIdx = lotNumberHistory.value.findIndex((el) => el == lotNumber)
    lotNumberHistory.value.splice(foundIdx, 1)
  }
  lotNumberHistory.value.unshift(lotNumber)
}

const normalizeLotNumberString = (lotNumber: string) => {
  return lotNumber.toUpperCase().replace(/-/g, '')
}

const getInvalidLotNumbers = (inputRequestedLots: string[], inputFoundLots: string[]) => {
  let foundLots = inputFoundLots.map(normalizeLotNumberString)
  let requestedLots = inputRequestedLots.map(normalizeLotNumberString)
  return requestedLots.filter((lotNumber) => {
    return !foundLots.includes(lotNumber)
  })
}

export const useLotStore = defineStore('lot', () => {
  const lots = ref<LotAnalytics[]>([])
  const invalidLots = ref()
  const lookupErrors: Ref<string[]> = ref([])
  const lookupExceptions: Ref<string[]> = ref([])
  const loading = ref(false)
  const loaded = ref(false)

  const fetchLots = async function (lotNumbers: string[], entryFormat?: number | null) {
    lots.value = []
    invalidLots.value = []
    lookupErrors.value = []
    lookupExceptions.value = []
    loading.value = true
    loaded.value = false

    let searchParams = lotNumbers.map((lotNumber) => ['lotNumber[]', lotNumber])
    if (entryFormat) {
      searchParams.push(['entryFormat', entryFormat.toString()])
    }
    const urlSearchParams = new URLSearchParams(searchParams)

    const response = await fetch('/api/lot?' + urlSearchParams)
    if (response.ok) {
      const responseJsonObject = await response.json()
      if (responseJsonObject.message === 'success') {
        lookupErrors.value = responseJsonObject.data.errors
        lookupExceptions.value = responseJsonObject.data.exceptions
        let foundLotNumbers: string[] = []
        if (responseJsonObject.data.lots !== null) {
          foundLotNumbers = Object.keys(responseJsonObject.data.lots)
        }
        invalidLots.value = getInvalidLotNumbers(lotNumbers, foundLotNumbers)

        foundLotNumbers.forEach((lotNumber) => {
          addLotToHistory(lotNumber)
          let lot: LotAnalytics = responseJsonObject.data.lots[lotNumber]
          lots.value.push(lot)
        })
        loaded.value = true
      } else {
        lookupExceptions.value.push(response.status.toString())
      }
    } else {
      lookupExceptions.value.push(response.status.toString())
    }
    loading.value = false

    if (lots.value.length > 0) {
      invalidLots.value.forEach((lotNumber: string) => {
        toast.quickToast('Lot Not Found', lotNumber.toUpperCase(), 'warning')
      })
    }
  }

  const lotNumbers = computed(() => {
    let lotNumbers: string[] = []
    lots.value.forEach((lot) => {
      lotNumbers.push(lot.lotNumber)
    })
    return lotNumbers
  })

  const removeLot = (lotNumber: string) => {
    const lotIndex = lots.value.map((lot) => lot.lotNumber).indexOf(lotNumber)
    if (lotIndex > -1) {
      lots.value.splice(lotIndex, 1)
    }
  }

  return {
    lots,
    invalidLots,
    lookupErrors,
    lookupExceptions,
    fetchLots,
    lotNumbers,
    loading,
    loaded,
    lotNumberHistory,
    removeLot
  }
})

export default useLotStore
