// inputHandlers.ts

/**
 * Prevents non-numeric input in keyboard-related events.
 * Allows control keys for navigation and editing, and optionally common shortcuts.
 *
 * @param event - The KeyboardEvent from the user's interaction.
 */
export function filterNonNumericInput(event: KeyboardEvent): void {
  // Define keys that should always be allowed
  const allowedKeys: string[] = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End',
    'PageUp',
    'PageDown'
  ]

  // Define function to check if a key is for control actions or navigation
  function isControlOrNavigationKey(key: string): boolean {
    return allowedKeys.includes(key)
  }

  // Define function to check if a key is a valid number (including numpad keys)
  function isNumericKey(key: string): boolean {
    const numberKeys: string[] = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'NumPad0',
      'NumPad1',
      'NumPad2',
      'NumPad3',
      'NumPad4',
      'NumPad5',
      'NumPad6',
      'NumPad7',
      'NumPad8',
      'NumPad9',
      '.'
    ]
    return numberKeys.includes(key)
  }

  // Allow only one decimal point
  const target = event.target as HTMLInputElement
  const containsDecimal = target.value.includes('.')
  const isDecimalPoint = event.key === '.'

  // Define function to check for commonly used shortcuts like copy and paste
  function isCommonShortcut(key: string, event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && ['a', 'c', 'v', 'x'].includes(key.toLowerCase())
  }

  // Prevent default unless the key is a control key, a numeric key, or a common shortcut
  if (
    !isControlOrNavigationKey(event.key) &&
    !isNumericKey(event.key) &&
    !isCommonShortcut(event.key, event)
  ) {
    event.preventDefault()
  } else if (isDecimalPoint && containsDecimal) {
    event.preventDefault()
  }
}
