<template>
  <div class="oil-components">
    <Bar class="oil-components__chart" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
import type { ChartData } from 'chart.js'
import { lotColors } from '@/components/LotLookup'
import { percentageChartOptions as chartOptions } from '@/components/Charts/chartOptions'

Chart.register(...registerables)
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'
import { showKey as origShowKey } from '@/composables/filterSettings'
import { lotHasChartOilComponents } from '@/composables/hasChartOilComponents'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

function getLotDataSet(lot: Lot) {
  let data = []
  if (showKey('betaPinene')) {
    if (lot.oilComponents?.betaPinene != null) {
      data.push(lot.oilComponents?.betaPinene / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('myrcene')) {
    if (lot.oilComponents?.myrcene != null) {
      data.push(lot.oilComponents?.myrcene / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('linaloolOil')) {
    if (lot.oilComponents?.linalool != null) {
      data.push(lot.oilComponents?.linalool / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('caryophyllene')) {
    if (lot.oilComponents?.caryophyllene != null) {
      data.push(lot.oilComponents?.caryophyllene / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('farnesene')) {
    if (lot.oilComponents?.farnesene != null) {
      data.push(lot.oilComponents?.farnesene / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('humulene')) {
    if (lot.oilComponents?.humulene != null) {
      data.push(lot.oilComponents?.humulene / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('geraniolOil')) {
    if (lot.oilComponents?.geraniol != null) {
      data.push(lot.oilComponents?.geraniol / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('other')) {
    if (lot.oilComponents?.other != null) {
      data.push(lot.oilComponents?.other / 100)
    } else {
      data.push(null)
    }
  }
  return data
}

const chartData = computed(() => {
  let labels: string[] = []
  if (showKey('betaPinene')) {
    labels.push('β-Pinene')
  }
  if (showKey('myrcene')) {
    labels.push('Myrcene')
  }
  if (showKey('linaloolOil')) {
    labels.push('Linalool')
  }
  if (showKey('caryophyllene')) {
    labels.push('Caryophyllene')
  }
  if (showKey('farnesene')) {
    labels.push('Farnesene')
  }
  if (showKey('humulene')) {
    labels.push('Humulene')
  }
  if (showKey('geraniolOil')) {
    labels.push('Geraniol')
  }
  if (showKey('other')) {
    labels.push('Other')
  }
  const dataset = getLotDataSet(lotStore.lots[0])

  if (lotStore.lots.length === 1) {
    return {
      labels: labels,
      datasets: [
        {
          data: dataset,
          backgroundColor: lotColors[2]
        }
      ]
    } as ChartData<'bar'>
  }

  let datasets = []

  for (let i = 0; i < lotStore.lots.length; i++) {
    if (lotHasChartOilComponents(lotStore.lots[i])) {
      datasets.push({
        label: lotStore.lots[i].lotNumber ?? 'Lot ' + i + 1,
        data: getLotDataSet(lotStore.lots[i]),
        backgroundColor: lotColors[i]
      })
    }
  }
  return {
    labels,
    datasets
  } as ChartData<'bar'>
})
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.oil-components {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__chart {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    p {
      display: none;
    }
  }
}
</style>
