<template>
  <div class="filter-menu" v-if="open">
    <div class="filter-menu__top-row">
      <y-checkbox
        v-model="allSelected"
        class="filter-menu__top-row__checkbox"
        id="show-hide-all"
        @click="toggleSelectAll()"
      />
      <label for="show-hide-all" class="filter-menu__top-row__check-label">Show/Hide All</label>
    </div>
    <ul class="filter-menu__list">
      <li
        :class="{
          'filter-menu__list__item': true,
          'filter-menu__list__item--selected': isSelected(filter)
        }"
        v-for="(filter, index) in filterOptions"
        :key="index"
        @click="toggleFilter(filter)"
      >
        <y-icon icon="check" v-if="isSelected(filter)" class="filter-menu__list__item__icon" />
        {{ filter.label }}
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { YCheckbox, YIcon } from '@yakimachief/ych-ts-component-library'
import { filterSettings, filters, chartFilters, allFilters } from '@/composables/filterSettings'
import type { FilterType } from '@/composables/filterSettings'
import useLotStore from '@/stores/LotStore'

type FilterMenuProperties = {
  isChart?: boolean
  open?: boolean
}

const props = withDefaults(defineProps<FilterMenuProperties>(), {
  isChart: true,
  open: false
})

const lotStore = useLotStore()

const filterOptions = computed(() => {
  if (props.isChart) {
    if (lotStore.lots.length === 1) {
      return allFilters
    }
    return chartFilters.concat(filters)
  }
  return filters
})

const allSelected = computed(() => {
  let allSelected = true
  allFilters.forEach((filter) => {
    if (!isSelected(filter)) {
      allSelected = false
    }
  })
  return allSelected
})

const isSelected = (clickedFilter: FilterType) => {
  return filterSettings.value.some((filter: FilterType) => filter.key === clickedFilter.key)
}

const toggleFilter = (clickedFilter: FilterType) => {
  if (isSelected(clickedFilter)) {
    const index = filterSettings.value.findIndex(
      (filter: FilterType) => filter.key === clickedFilter.key
    )
    if (index > -1) {
      filterSettings.value.splice(index, 1)
    }
  } else {
    filterSettings.value.push(clickedFilter)
  }
}

const toggleSelectAll = () => {
  if (allSelected.value) {
    filterSettings.value = []
  } else {
    filterSettings.value = [...allFilters]
  }
}
</script>

<style scoped lang="scss">
.filter-menu {
  position: absolute;
  top: 2.75rem;
  right: 0;
  background-color: var(--ych-white);
  border-radius: var(--y-radius-md);
  z-index: 4;
  width: 17.5rem;
  border: 1px solid var(--ych-grey-200);
  overflow: hidden;
  box-shadow: var(--y-shadow-grey-xl);

  &__top-row {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
    border-bottom: 1px solid var(--ych-grey-200);
    padding: 0.25rem 0;
    background-color: var(--ych-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__checkbox {
      margin-left: -0.25rem;
    }

    &__check-label {
      font-size: var(--y-font-size-sm);
      line-height: var(--y-font-size-sm);
      font-weight: var(--y-font-weight-medium);
      color: var(--ych-grey-400);
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    max-height: 12.5rem;

    &__item {
      width: 100%;
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 0.75rem 0.5rem 2rem;
      color: var(--ych-blue);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      font-weight: var(--y-font-weight-medium);

      &--selected {
        font-weight: var(--y-font-weight-bold);
      }

      &:hover {
        background-color: var(--ych-grey-100);
        cursor: pointer;
      }

      &__icon {
        position: absolute;
        left: 0.75rem;
        font-size: 1rem;
        color: var(--ych-grey-400);
      }
    }
  }
}
</style>
