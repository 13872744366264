<template>
  <div class="menu-item">
    <RouterLink class="menu-item__link" v-if="routerLink != null" :to="routerLink">
      <i :class="iconClasses" />
      <h3 class="menu-item__text">{{ itemText }}</h3>
    </RouterLink>
    <a class="menu-item__link" :href="href" v-if="href != null">
      <i :class="iconClasses" />
      <h3 class="menu-item__text">{{ itemText }}</h3>
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

const props = defineProps({
  itemText: {
    type: String,
    required: true
  },
  iconSlug: {
    type: String,
    required: true
  },
  routerLink: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  isProductIcon: {
    type: Boolean,
    default: false
  }
})

const iconClasses = computed(() => ({
  'fa-solid': !props.isProductIcon,
  'fa-kit': props.isProductIcon,
  [`fa-${props.iconSlug || 'user'}`]: true
}))
</script>

<style scoped lang="scss">
.menu-item {
  &__text {
    margin-bottom: -1px;
    color: var(--ych-white);
  }
  &__link {
    padding: 1rem;
    cursor: pointer;
    background: var(--ych-red);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    text-decoration: none;
    color: var(--ych-white);

    &:hover {
      background: var(--ych-white);
      color: var(--ych-red);
      .menu-item__text {
        color: var(--ych-red);
      }
    }
  }
}
</style>
