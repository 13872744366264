<template>
  <div
    v-if="lotStore.lots.length === 0"
    class="lookup-errors flex flex-col w-full items-center grow justify-around gap-4 limit-width-md"
  >
    <div v-if="isUnexpectedError" class="lookup-errors__container flex-col justify-center">
      <div class="lookup-errors__title flex flex-row justify-center uppercase">
        <p class="font-medium">Error:</p>
      </div>
      <p>Sorry, we're having trouble reaching that page.</p>
      <p>
        If this error persists, please contact
        <a href="mailto:hops@yakimachief.com" target="_blank">hops@yakimachief.com</a>.
      </p>
    </div>
    <div
      v-else-if="lotStore.invalidLots?.length > 0"
      class="lookup-errors__container flex-col justify-center"
    >
      <div
        class="lookup-errors__title flex flex-row justify-center gap-1 items center"
        v-if="lotStore.invalidLots.length == 1"
      >
        <span class="font-medium lookup-errors__title__error">Lot not found:</span
        ><span class="lookup-errors__title__missing-lot">{{ lotStore.invalidLots[0] }}</span>
      </div>
      <div class="lookup-errors__title flex flex-row justify-center gap-1 items center" v-else>
        <span class="lookup-errors__title__error font-medium whitespace-nowrap"
          >Lots not found:</span
        >
        <span class="lookup-errors__title__missing-lot">{{ lotStore.invalidLots.join(', ') }}</span>
      </div>
    </div>
    <div
      class="lookup-errors__get-started relative"
      v-if="!lotStore.invalidLots && !isUnexpectedError"
    >
      <div class="lookup-errors__get-started__overlay"></div>
      <p class="lookup-errors__get-started__instructions">
        Please enter a lot number or comma-seperated list above to begin.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import useLotStore from '@/stores/LotStore'
import { computed } from 'vue'

const lotStore = useLotStore()

const isUnexpectedError = computed(() => {
  if (lotStore.lookupExceptions.length > 0) {
    return true
  }
  let unexpectedError = false
  lotStore.lookupErrors.forEach((error: any) => {
    const response = error.response ?? false
    if (response) {
      const message = response.message ?? false
      if (message) {
        switch (message) {
          case 'An expected resource was not found with the provided parameters.':
            break
          default:
            unexpectedError = true
        }
      }
    }
  })
  return unexpectedError
})
</script>
<style lang="scss">
.lookup-errors {
  * {
    font-size: var(--y-font-size-lg);
    color: var(--ych-grey-400);
  }

  &__title {
    &__error {
      text-transform: uppercase;
      font-family: var(--y-font-family-condensed);
    }
    &__missing-lot {
      text-transform: uppercase;
    }
  }
  &__container p {
    text-align: center;
  }
  &__get-started {
    width: 1200px;
    max-width: 100%;
    aspect-ratio: 3/2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: url(/resources/assets/hop-field.jpg) no-repeat 50% 50%;
    gap: 2rem;
    padding: 2rem;
    text-align: center;
    border-radius: var(--y-radius-lg);
    overflow: hidden;

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
    &__instructions {
      color: var(--ych-white);
      z-index: 1;
      font-weight: var(--y-font-weight-medium);
      white-space: pre-wrap;
    }
  }
}
@media screen and (max-width: 640px) {
  .lookup-errors__get-started {
    aspect-ratio: 3/4;
  }
}
</style>
