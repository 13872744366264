import { createRouter, createWebHistory } from 'vue-router'
import type VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LotLookupView from '@/views/LotLookupView.vue'
import CalculatorView from '@/views/CalculatorView.vue'
import ExtractConversionView from '@/views/ExtractConversionView.vue'
import IbuCalculatorView from '@/views/IbuCalculatorView.vue'
import CryoCalculator from '@/views/CryoCalculator.vue'

const routes: Readonly<VueRouter.RouteRecordRaw>[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: {
      title: 'Lot Lookup Home'
    }
  },
  {
    path: '/lookup',
    component: LotLookupView
  },
  {
    path: '/table',
    name: 'table',
    component: LotLookupView
  },
  {
    path: '/calculators',
    name: 'calculators',
    component: CalculatorView
  },
  {
    path: '/calculators/extract',
    name: 'extract-conversion',
    component: ExtractConversionView
  },
  {
    path: '/calculators/ibu',
    name: 'kettle-ibu-calculator',
    component: IbuCalculatorView
  },
  {
    path: '/calculators/cryo',
    name: 'cryo-calculator',
    component: CryoCalculator
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'FileNotFound',
    component: () => import('@/views/FileNotFoundView.vue')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
