const definitions = {
  analysisMethod: {
    title: 'Analysis Method',
    definition:
      'The specific technique and process used to achieve a desired measurement of a hop or beer product.'
  },
  lotNumber: {
    title: 'Lot Number',
    definition:
      'An alphanumeric identification number is assigned to a specific lot of hops produced at YCH. Lot Numbers can typically be found on the outside of YCH packaging.'
  },
  variety: {
    title: 'Variety',
    definition: 'A specific strain of hops.'
  },
  cropYear: {
    title: 'Crop Year',
    definition:
      'The year that a specific lot of hops was harvested. For lots produced in January, the crop year will reference the previous calendar year.'
  },
  alphaAcids: {
    title: 'Alpha Acids (humulones)',
    definition:
      'Compounds that provide most of the hop’s bitterness potential to beer. Alpha acid is bitter on its own, but the heat of the brewing process (boil and whirlpool) helps to isomerize Alpha acid into iso-alpha acid, which is about ten times as bitter as alpha and is more soluble in beer.'
  },
  betaAcids: {
    title: 'Beta Acids (lupulones)',
    definition:
      'Compounds that do not contribute significantly to beer flavor. They provide some antimicrobial properties, defending against gram-positive bacteria like <i>Lactobacillus</i> spp. Oxidized beta acids can provide some bitterness in beer, though the subsequent oxidation of alpha acids negates most of this effect.'
  },
  hsi: {
    title: 'HSI',
    definition:
      'Acronym for Hop Storage Index, which is a measure of the degree of degradation of a given hop sample. Generally, HSI is calculated using the UV Spectrophotometry method. As hops degrade, HSI values will increase. Once hops are pelletized, turned into Cryo Hops<sup>®</sup>, or extracted, the HSI should remain much more stable than in bale form. An HSI value under 0.350 is generally good quality, but the lower the number, the better.'
  },
  moisture: {
    title: 'Moisture',
    definition:
      'Proper moisture levels are important for hops’ stability over time. Ideally, hops will contain between 8 and 11% moisture by weight. '
  },
  totalOil: {
    title: 'Total Oil',
    definition:
      'Total Oil can be used to compare the intensity of two lots of the same variety (a lot with twice the total oil will likely be more intensely aromatic). However, since the majority of these oils do not survive the brewing process when comparing different varieties, the specific concentration of survivable compounds is a better method to use.'
  },
  oilByDist: {
    title: 'Oil by Distillation',
    definition:
      'The amount of oil contained in a hop sample. While total oil is important and can give a good sense of overall hop aroma intensity, most hop oils are not soluble in beer. The majority of a hops’ oil fraction is made up of mono- and sesqui-terpenes. These types of compounds are not very soluble in beer and are quite volatile. Because of this, the concentration of survivable compounds could be considered more important than total oil.'
  },
  bPinene: {
    title: 'β-Pinene',
    definition:
      'A monoterpene, β-pinene smells pine-like. It is far less abundant in hops than myrcene, making up only about 1% of the total oil, maximum. Like myrcene, β-pinene is very volatile and will only transfer to beer during dry hopping.'
  },
  myrcene: {
    title: 'Myrcene',
    definition:
      'A monoterpene and typically the largest component of a hop’s ‘total oil’ (up to about 75%, although some varieties have significantly less). Myrcene is extremely volatile, and while it contributes heavily to raw hop aroma, it will generally not survive the brewing process due to low solubility. Myrcene has an herbal, woody, and somewhat carrot-like aroma.'
  },
  linalool: {
    title: 'Linalool',
    definition:
      'A monoterpene alcohol commonly used as a fragrance and flavoring in cosmetics and candy. It has a strong fruity and floral aroma, commonly described as similar to the aroma of Froot Loops™ cereal. High levels of linalool can act as a ‘booster’ to increase fruity flavor in beer. Linalool is beer-soluble and commonly survives the brewing process.'
  },
  caryophyllene: {
    title: 'Caryophyllene',
    definition:
      'A sesquiterpene with a very woody aroma, sometimes cedar-like. Other aroma notes include floral and spicy. β-caryophyllene contributes to the character of noble hops and is found in lower levels in newer American hops. It generally does not survive the brewing process.'
  },
  farnesene: {
    title: 'Farnesene',
    definition:
      'A sesquiterpene exhibiting a noble and woody aroma profile. Interestingly, farnesene is only found in trace amounts in most modern varieties, and some actually have no detectable farnesene. Most of the varieties containing high levels of farnesene are traditional European varieties.'
  },
  humulene: {
    title: 'Humulene',
    definition:
      'A sesquiterpene exhibiting grassy, herbal, and woody aromas. It is highly volatile and generally will not survive the brewing process.'
  },
  geraniol: {
    title: 'Geraniol',
    definition:
      'Geraniol is a monoterpene alcohol that commonly survives late boil and whirlpool additions. Its aroma profile is geranium-like and citrusy. Geraniol is thought to be at least partially bio-transformed by certain yeast strains into β-citronellol during fermentation.'
  },
  other: {
    title: 'Other Oil',
    definition: 'The percentage of oil left after the other oil components are accounted for.'
  },
  isobutyl: {
    title: 'Isobutyl Isobutyrate',
    definition:
      'Isobutyl isobutyrate is an ester with a fruity aroma and flavor. Several sources have noted specific aromas like pineapple, grape skin, banana, and tropical.'
  },
  isoamyl: {
    title: 'Isoamyl Isobutyrate',
    definition:
      'An ester derived from hops that typically survives the brewing process. It lends a fruity, sweet, and tropical aroma.'
  },
  twoMethyl: {
    title: '2-Methylbutyl Isobutyrate',
    definition:
      'An ester derived from hops that typically survives the brewing process. Like most esters, it lends a fruity aroma, specifically apricot.'
  },
  twoNonanone: {
    title: '2-Nonanone',
    definition:
      'A ketone with a variety of different aromas. It can range from sweet and fruity to cheesy, buttery, and waxy.'
  },
  methylGeranate: {
    title: 'Methyl Geranate',
    definition:
      'A methyl ester derived from hops that typically survives the brewing process into the finished beer. It lends a fruity and floral aroma and is usually the only hop-derived methyl ester that survives into beer.'
  },
  threeMercapto: {
    title: '3-Mercaptohexanol',
    definition:
      'A polyfunctional thiol commonly found in hops. It has a tropical and grapefruit aroma. 3MH can be converted by yeast into 3MHA.'
  },
  loq: {
    title: 'Limit of Quantification (LOQ)',
    definition:
      'The smallest amount or the lowest concentration of a substance that can be quantitatively detected with a sufficient degree of confidence or statistical significance.'
  }
}

export function uppercaseExceptBeta(input: string) {
  return input.replace(/[^β]/g, function (match) {
    return match.toUpperCase()
  })
}

export default definitions
export type TooltipKey = keyof typeof definitions

export const getTooltip = (key: TooltipKey) => {
  return {
    content: `<span class="font-medium">${definitions[key].title}</span>: ${definitions[key].definition}`,
    html: true
  }
}
