<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-400"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-400"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 modal-background" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="transition-all flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-800"
            enter-from="opacity-0 translate-y-10 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-800"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-16 sm:scale-90"
          >
            <DialogPanel
              class="grower-profile relative transform overflow-hidden card text-left transition-all sm:my-8 w-full lg:max-w-[50rem] mx-0 lg:mx-0 sm:mx-12 sm:p-6"
            >
              <div class="grower-profile relative">
                <div class="grower-profile__top-row flex justify-between items-center pb-2">
                  <DialogTitle
                    >Grower Profiles<span v-if="farms.length <= 1"
                      >: {{ farms[0]?.grownBy }}</span
                    ></DialogTitle
                  >
                  <y-button
                    label=""
                    icon="xmark"
                    width="2.75rem"
                    background-color="transparent"
                    :mobile-fill="false"
                    @click="setIsOpen(false)"
                  />
                </div>

                <div class="grower-profile__wrapper flex flex-col gap-6">
                  <div
                    class="grower-profile__wrapper__content flex flex-col gap-4"
                    v-for="farm in farms"
                  >
                    <individual-grower-profile
                      :farm="farm"
                      :show-farm-name="farms.length > 1"
                    ></individual-grower-profile>
                  </div>
                </div>
                <div v-if="farms.length > 1" class="inner-shadow"></div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { YButton } from '@yakimachief/ych-ts-component-library'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import type { Farm } from '@/types/Lot'
import IndividualGrowerProfile from '@/components/IndividualGrowerProfile.vue'

type GrowerProfileProperties = {
  farms: Farm[]
  open: boolean
}
const props = withDefaults(defineProps<GrowerProfileProperties>(), {
  open: false
})

const emit = defineEmits(['update:open'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: var(--ych-black-20);
}

.grower-profile__wrapper {
  overflow-y: auto;
  max-height: 33rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;

  &__content:not(:first-of-type) {
    border-top: 1px solid var(--ych-grey-200);
    padding-top: 1.5rem;
  }

  &__content__main {
    flex-direction: var(--flex-direction);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.75rem;
  pointer-events: none;
  z-index: 10;
  background: -webkit-linear-gradient(90deg, rgba(22, 22, 22, 0.15), transparent);
  background: linear-gradient(0deg, rgba(22, 22, 22, 0.15), transparent);
}
</style>
