<template>
  <div class="survivables__container__row__labels__group flex justify-between">
    <small
      class="survivables__container__row__labels__group__title uppercase py-1 cursor-default"
      v-tooltip="getTooltip(definition)"
      >{{ label }}</small
    >
    <template v-if="showValuesInLegend">
      <small v-if="value">
        {{ value }}
      </small>
      <small class="py-1" v-else v-tooltip="getTooltip('loq')">LOQ</small>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import { getTooltip, type TooltipKey } from '@/composables/definitions'

const props = defineProps<{
  label: string
  definition: TooltipKey
  value: number
}>()

const lotStore = useLotStore()

const showValuesInLegend = computed(() => {
  return lotStore.lots.length === 1
})
</script>
