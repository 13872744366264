<template>
  <div class="extract-calculator flex flex-col w-full justify-start items-center px-4 gap-5 py-5">
    <!------ Title Row ------>
    <div class="limit-width-md w-full flex flex-col md:flex-row gap-4 justify-between items-center">
      <div class="flex gap-3 w-full items-center">
        <img class="product-logo" src="/img/ych-extract_blue.svg" alt="Extract Product Symbol" />
        <h1>Extract Conversion Calculator</h1>
      </div>
      <div class="flex w-full gap-4 justify-start md:justify-end print:hidden">
        <y-button
          @click="showResetModal = true"
          background-color="secondary"
          icon="rotate-right"
          id="reset-btn"
          v-if="inputIsDirty"
          label="Reset"
        />
        <y-button
          @click="window.print()"
          background-color="secondary"
          label="Print"
          icon="file-pdf"
          v-if="compInputCompleted"
        />
        <y-button
          @click="save()"
          background-color="secondary"
          label="Download"
          icon="file-csv"
          v-if="compInputCompleted"
        />
      </div>
    </div>
    <!------ Section 1: Units ------>
    <h2 class="sr-only">Calculation Inputs</h2>
    <div
      class="limit-width-md w-full card background-light-grey grid grid-cols-1 md:grid-cols-4 gap-4"
    >
      <y-dropdown
        label="Measurement Units"
        :options="measurementOptions"
        v-model="inputMeasurementSystem"
        class="grid w-full col-span-1"
        id="sel-measurement-system-conversion"
        :allow-no-selection="false"
      />
      <div class="grid col-span-1 md:col-span-3">
        <span class="font-medium leading-8">Please Note:</span>
        <p class="h-10 inline-flex items-center">
          Either the product you are converting from or the product you are converting to must be
          Extract.
        </p>
      </div>
    </div>
    <div class="limit-width-md w-full card background-light-grey flex flex-col">
      <h3 v-show="inputFrom">{{ inputFrom ? 'From ' + inputFrom.name : '' }}</h3>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
        <y-dropdown
          label="Convert From..."
          placeholder="Select product..."
          :options="products"
          v-model="inputFrom"
          class="grid w-full col-span-1"
          :allow-no-selection="true"
          @update:model-value="fromProductChg"
        />
        <div class="flex flex-col justify-start" v-show="inputFrom">
          <div class="flex justify-start items-start gap-1">
            <label for="from-utilization" class="extract-calculator__label">Utilization</label>
            <span
              v-tooltip="{
                html: true,
                content: `
          <h5>Utilization:</h5>
          <p class='text-white'>The percentage of alpha acids that are converted to isomerized alpha acids. Our defaults are usually good estimates, but every system is different.</p>
          `
              }"
            >
              <y-icon icon="circle-info-solid" />
            </span>
          </div>
          <y-input
            id="from-utilization"
            input-type="number"
            :min="0"
            :max="100"
            v-model="inputFromUtilization"
            units="%"
          />
        </div>
        <y-input
          v-show="inputFrom && !compInputFromExtract"
          id="from-alpha"
          input-type="number"
          :min="0"
          :max="100"
          :step="0.1"
          v-model="inputFromAlpha"
          label="Alpha"
          units="%"
        />
        <!-- This amount is extract specific -->
        <y-input
          v-show="inputFrom && compInputFromExtract"
          id="from-gma"
          input-type="number"
          :min="0"
          v-model="inputFromGma"
          label="Amount"
          units="GMA"
        />
        <y-input
          v-show="inputFrom && !compInputFromExtract"
          id="from-amount"
          input-type="number"
          :min="0"
          v-model="inputFromAmount"
          label="Amount"
          :units="compUnitMass"
        />
      </div>
    </div>
    <div class="limit-width-md w-full card background-light-grey flex flex-col">
      <h3 v-show="inputTo">{{ inputTo ? 'To ' + inputTo.name : '' }}</h3>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
        <y-dropdown
          label="Convert To..."
          placeholder="Select product..."
          :options="products"
          v-model="inputTo"
          class="grid w-full col-span-1"
          :allow-no-selection="true"
          @update:model-value="toProductChg"
        />

        <div class="flex flex-col justify-start" v-show="inputTo">
          <div class="flex justify-start items-start gap-1">
            <label for="to-utilization" class="extract-calculator__label">Utilization</label>
            <span
              v-tooltip="{
                html: true,
                content: `
          <h5>Utilization:</h5>
          <p class='text-white'>The percentage of alpha acids that are converted to isomerized alpha acids. Our defaults are usually good estimates, but every system is different.</p>
          `
              }"
            >
              <y-icon icon="circle-info-solid" />
            </span>
          </div>
          <y-input
            id="to-utilization"
            input-type="number"
            :min="0"
            :max="100"
            v-model="inputToUtilization"
            units="%"
          />
        </div>

        <y-input
          v-show="inputTo && !compInputToExtract"
          id="to-alpha"
          input-type="number"
          :min="0"
          :max="100"
          :step="0.1"
          v-model="inputToAlpha"
          label="Alpha"
          units="%"
        />

        <div
          class="flex flex-col form-group w-full relative pt-7 md:pt-0"
          v-show="inputTo && compInputToExtract"
        >
          <div class="absolute left-0 top-1 md:-top-5 flex justify-start items-start gap-1">
            <h4 class="whitespace-nowrap leading-8">Which tins are you using?</h4>
            <span
              v-tooltip="{
                html: true,
                content: `
          <h5>Which tins are you using?</h5>
          <p class='text-white'>Select the number of tins you’d like to use to achieve the desired bitterness.</p>
          `
              }"
            >
              <y-icon icon="circle-info-solid" />
            </span>
          </div>

          <y-input
            id="to-150s"
            input-type="number"
            :min="0"
            v-model="inputTo150s"
            label="150 GMA Tins"
            units="#"
          />
        </div>
        <y-input
          v-show="inputTo && compInputToExtract"
          id="to-300s"
          input-type="number"
          :min="0"
          v-model="inputTo300s"
          label="300 GMA Tins"
          units="#"
        />
      </div>
    </div>
    <div
      class="limit-width-md w-full card background-light-grey flex flex-col"
      v-if="compResultIsValid"
    >
      <h2 class="sr-only">Results</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        <div class="flex flex-col gap-2 justify-start items-center w-full" v-if="convertedTotalGma">
          <h3>Equivalent Total</h3>
          <div class="result-box background-red w-full px-6 py-3">
            <p class="text-white">{{ convertedTotalGma }}<sup>*</sup> GMA</p>
          </div>
          <small
            >*This is an estimated conversion value, your results may differ based on your brewing
            system.
          </small>
        </div>
        <div class="flex flex-col gap-2 justify-start items-center w-full" v-if="showRemainder">
          <h3>Remainder</h3>
          <div class="result-box background-blue w-full px-6 py-3">
            <p class="text-white">{{ convertedRemainderGma }} GMA</p>
          </div>
          <small
            >({{ convertedRemainderAsFromType }} {{ compUnitMass }}s of
            {{ compInputFromDisplay }})</small
          >
        </div>
        <div
          class="flex flex-col gap-2 justify-start items-center w-full"
          v-if="convertedLiquidToSolid"
        >
          <h3>Equivalent Total</h3>
          <div class="result-box background-red w-full px-6 py-3">
            <p class="text-white">
              {{ convertedLiquidToSolid }}<sup>*</sup> {{ compUnitMass }}s of
              {{ compInputToDisplay }}
            </p>
          </div>
          <small
            >*This is an estimated conversion value, your results may differ based on your brewing
            system</small
          >
        </div>
      </div>
    </div>
    <reset-calculator-modal
      :open="showResetModal"
      calculator-name="Extract Conversion Calculator"
      @confirmed="resetInputs"
    />
  </div>
</template>

<script setup lang="ts">
import { YButton, YDropdown, YIcon, YInput } from '@yakimachief/ych-ts-component-library'
import { ref, computed, Ref, watch } from 'vue'
import ResetCalculatorModal from '@/components/ResetCalculatorModal.vue'

//____________Types_______________
interface Product {
  name: string
  utilization: number
}

interface ConversionInput {
  name?: string
  utilization?: number
  alpha?: number
  gma?: number
  amount?: number
  extra?: any
}

//_____________Constants_____________
const pounds: string = 'lb'
const kilograms: string = 'kg'
const measurementOptions = ['Imperial', 'Metric']

//_____________References_____________
const calculate: Ref<boolean> = ref(true)
const showRemainder: Ref<boolean> = ref(false)
const showResetModal: Ref<boolean> = ref(false)

const products: Ref<Product[]> = ref([
  { name: 'Leaf Hops', utilization: 25 },
  { name: 'Cryo Hops', utilization: 35 },
  { name: 'American Noble Hops', utilization: 30 },
  { name: 'Hop Pellets', utilization: 30 },
  { name: 'Extract', utilization: 35 }
])

const inputMeasurementSystem: Ref<string> = ref('Imperial')

const inputFrom: Ref<ConversionInput | null> = ref(null)
const inputFromAmount: Ref<number | null> = ref(null)
const inputFromAlpha: Ref<number | null> = ref(null)
const inputFromGma: Ref<number | null> = ref(null)
const inputFromUtilization: Ref<number | null> = ref(null)
const inputFromExtra: Ref<number | null> = ref(null)

const inputTo: Ref<ConversionInput | null> = ref(null)
const inputToAlpha: Ref<number | null> = ref(null)
const inputToGma: Ref<number | null> = ref(null)
const inputToUtilization: Ref<number | null> = ref(null)
const inputToExtra: Ref<number | null> = ref(null)
const inputTo150s: Ref<number | null> = ref(null)
const inputTo300s: Ref<number | null> = ref(null)

const convertedTotalGma: Ref<number> = ref(0)
const convertedRemainderGma: Ref<number> = ref(0)
const convertedRemainderAsFromType: Ref<number> = ref(0)
const convertedLiquidToSolid: Ref<number> = ref(0)

//________Computed Properties_________
const compInputFromDisplay = computed(() => (inputFrom.value ? inputFrom.value.name : ''))
const compInputToDisplay = computed(() => (inputTo.value ? inputTo.value.name : ''))

const compResultIsValid = computed(() => {
  let result = convert()
  return result && !isNaN(result) && result > 0
})

const compUnitMass = computed(() => {
  return inputMeasurementSystem.value === 'Imperial' ? pounds : kilograms
})

const compInputFromExtract = computed(() =>
  inputFrom.value ? inputFrom.value.name === 'Extract' : false
)
const compInputToExtract = computed(() =>
  inputTo.value ? inputTo.value.name === 'Extract' : false
)

const compInputCompleted = computed(() => {
  let fromValid = false
  if (inputFrom.value?.name !== 'Extract') {
    fromValid =
      inputFrom.value && inputFromAmount.value && inputFromUtilization.value && inputFromAlpha.value
  } else {
    fromValid = inputFrom.value && inputFromUtilization.value && inputFromGma.value
  }

  let toValid = false
  if (inputTo.value?.name !== 'Extract') {
    toValid = inputTo.value && inputToUtilization.value && inputToAlpha.value
  } else {
    toValid = inputTo.value && inputToUtilization.value
  }

  return fromValid && toValid
})

//______________Watchers______________
watch(inputFrom, (newValue, oldValue) => {
  if (newValue !== null && newValue.name !== 'Extract') {
    inputTo.value = { name: 'Extract', utilization: 35 }
    toProductChg()
  }
  if (newValue !== null && newValue.name === 'Extract' && inputTo.value?.name === 'Extract') {
    inputTo.value = null
    inputToUtilization.value = null
  }
})

watch(inputTo, (newValue, oldValue) => {
  if (newValue !== null && newValue.name !== 'Extract') {
    inputFrom.value = { name: 'Extract', utilization: 35 }
    fromProductChg()
  }
  if (newValue !== null && newValue.name === 'Extract' && inputFrom.value?.name === 'Extract') {
    inputFrom.value = null
    inputFromUtilization.value = null
  }
})

//_____________Functions______________
function convert() {
  if (calculate.value && compInputCompleted.value) {
    convertedTotalGma.value = 0
    convertedRemainderGma.value = 0
    convertedRemainderAsFromType.value = 0
    convertedLiquidToSolid.value = 0

    if (inputFrom.value?.name !== 'Extract') {
      if (inputTo.value?.name !== 'Extract') {
        // solid to solid
      } else {
        // solid to liquid
        convertedTotalGma.value = helperPrecisionRound(
          (convertMass(inputFromAmount.value) *
            454 *
            (inputFromAlpha.value / 100) *
            (inputFromUtilization.value / 100)) /
            (inputToUtilization.value / 100),
          1
        )

        if (inputTo150s.value > 0 || inputTo300s.value > 0) {
          convertedRemainderGma.value = helperPrecisionRound(
            convertedTotalGma.value - inputTo150s.value * 150 - inputTo300s.value * 300,
            1
          )

          // do not allow negative remainders
          if (convertedRemainderGma.value < 0) {
            while (convertedRemainderGma.value < 0) {
              if (inputTo150s.value > 0) {
                inputTo150s.value--
              } else if (inputTo300s.value > 0) {
                inputTo300s.value--
              } else {
                break // both tins inputs are 0...
              }
              convertedRemainderGma.value = helperPrecisionRound(
                convertedTotalGma.value - inputTo150s.value * 150 - inputTo300s.value * 300,
                1
              )
            }
          }

          convertedRemainderAsFromType.value = helperPrecisionRound(
            (convertedRemainderGma.value * (inputToUtilization.value / 100)) /
              (454 * (inputFromUtilization.value / 100) * (inputFromAlpha.value / 100)),
            2
          )
          // Convert remainder to metric, if calculator is set to it
          if (compUnitMass.value === kilograms) {
            convertedRemainderAsFromType.value = helperPrecisionRound(
              convertedRemainderAsFromType.value * 0.4536,
              2
            )
          }

          showRemainder.value = true
        } else {
          showRemainder.value = false
        }
        return !isNaN(convertedTotalGma.value)
      }
    } else {
      showRemainder.value = false
      if (inputTo.value?.name !== 'Extract') {
        // liquid to solid
        convertedLiquidToSolid.value = helperPrecisionRound(
          unconvertMass(
            (inputFromGma.value * (inputFromUtilization.value / 100)) /
              (454 * (inputToUtilization.value / 100) * (inputToAlpha.value / 100))
          ),
          2
        )
        return !isNaN(convertedLiquidToSolid.value)
      } else {
        // liquid to liquid
      }
    }
  }
}

function fromProductChg() {
  inputFromUtilization.value = inputFrom.value ? inputFrom.value.utilization : null
}

function toProductChg() {
  inputToUtilization.value = inputTo.value ? inputTo.value.utilization : null
}

function resetInputs() {
  inputFrom.value = null
  inputFromAmount.value = null
  inputFromAlpha.value = null
  inputFromGma.value = null
  inputFromUtilization.value = null
  inputFromExtra.value = null
  inputTo.value = null
  inputToAlpha.value = null
  inputToGma.value = null
  inputToUtilization.value = null
  inputToExtra.value = null
  inputTo150s.value = null
  inputTo300s.value = null
  showResetModal.value = false
}

function inputIsDirty() {
  return inputFrom.value !== null || inputTo.value !== null
}

function helperPrecisionRound(number: number, precision: number): number {
  const factor: number = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

function convertMass(inputMass: number): number {
  switch (inputMeasurementSystem.value) {
    case 'Imperial':
      return inputMass // lb
    case 'Metric':
      return inputMass * 2.20462 // kg > lb
    default:
      return inputMass
  }
}

function unconvertMass(inputMass: number): number {
  switch (inputMeasurementSystem.value) {
    case 'Imperial':
      return inputMass // lb
    case 'Metric':
      return inputMass / 2.20462 // lb > kg
    default:
      return inputMass
  }
}

function stripStrangeCharacters(input: string): string {
  let result = ''
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      result += input.charAt(i)
    }
  }
  return result
}

function save() {
  // Assume your reactive properties are now refs and have been declared somewhere above
  let csv = 'From\r\nConvert From,Utilization,Alpha,GMA,Amount\r\n'
  csv += `${inputFrom.value.name},${inputFromUtilization.value},${inputFromAlpha.value},${inputFromGma.value},${inputFromAmount.value}\r\n\r\n`

  csv += 'To:\r\nConvert To,Utilization,Alpha,150 GMA Tins,300 GMA Tins\r\n'
  csv += `${inputTo.value.name},${inputToUtilization.value},${inputToAlpha.value},${inputTo150s.value},${inputTo300s.value}\r\n\r\n`

  // Results
  if (convertedTotalGma.value) {
    csv += 'Results:\r\nEquivalent Total (Estimated)'
    csv += showRemainder.value ? ',Remainder\r\n' : '\r\n'
    csv += `${convertedTotalGma.value} GMA`
    csv += showRemainder.value
      ? `,${convertedRemainderGma.value} GMA (${convertedRemainderAsFromType.value} ${compUnitMass.value}s of ${compInputFromDisplay.value})\r\n\r\n`
      : `\r\n\r\n`
  } else if (convertedLiquidToSolid.value) {
    csv += 'Results:\r\nEquivalent Total (Estimated)\r\n'
    csv += `${convertedLiquidToSolid.value} ${compUnitMass.value}s of ${compInputToDisplay.value}\r\n\r\n`
  }

  // Trigger the download
  const link = document.createElement('a')
  link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
  link.download = 'Recipe_Conversion.csv'
  document.body.appendChild(link) // Append link to body temporarily
  link.click()
  document.body.removeChild(link) // Clean up and remove the link
}
</script>
<style scoped lang="scss">
.extract-calculator {
  .product-logo {
    width: 100%;
    max-width: 4.5rem;
  }

  .background-light-grey {
    background-color: var(--ych-grey-100);
  }

  .background-red {
    background: var(--ych-red);
  }

  .background-blue {
    background: var(--ych-blue);
  }

  .result-box {
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    p {
      font-size: 2rem;
      padding: 0;
      margin: 0;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      font-weight: 900;
    }
  }
  &__label {
    line-height: 2rem;
    max-height: 2rem;
    display: inline-flex;
    font-weight: var(--y-font-weight-medium);
  }
}
</style>
