<template>
  <div class="lot-lookup-single flex flex-col gap-6 w-full" :style="customStyles">
    <div
      class="lot-lookup-single__top-row flex flex-col lg:flex-row gap-2 justify-center lg:justify-between items-center w-full"
    >
      <div class="lot-lookup-single__top-row__lot-info flex items-center gap-2">
        <img
          v-if="lot.coneImage && showConeImage"
          :src="lot.coneImage"
          class="lot-lookup-single__top-row__lot-info__cone-image"
          :alt="'Hop Cone Image for' + lot.variety"
          @error="showConeImage = false"
        />
        <div class="lot-lookup-single__top-row__lot-info__col flex flex-col gap-1">
          <h2 class="hidden collapse xl:visible xl:block">
            {{ lot.cropYear ? lot.cropYear : '' }}
            {{ lot.variety }}
            <span v-if="lot.cultivar"> • {{ lot.cultivar }}</span> •
            <span class="product inline-flex gap-1 items-center"
              >{{ lot.productName }}
              <y-icon
                class="table-item__info__lot-details__product-info__product"
                :icon="getProductSlug(lot.productName)"
                aria-hidden="true"
            /></span>
            <span v-if="showBinNumbers"> • Bin: {{ getBinDisplayString(lot?.harvestSample) }}</span>
          </h2>
          <h2 class="block visible xl:hidden xl:collapse items-center leading-7">
            {{ lot.cropYear ? lot.cropYear : '' }}
            {{ lot.variety }}
            <span v-if="lot.cultivar"> • {{ lot.cultivar }}</span>
            <br /><span class="product inline-flex gap-1 items-center"
              >{{ lot.productName }}
              <y-icon
                class="table-item__info__lot-details__product-info__product"
                :icon="getProductSlug(lot.productName)"
                aria-hidden="true"
            /></span>
            <span v-if="showBinNumbers"> • Bin: {{ getBinDisplayString(lot?.harvestSample) }}</span>
          </h2>
          <div
            class="lot-lookup-single__top-row__lot-info__col__row flex gap-x-4 gap-y-2 flex-wrap"
          >
            <div class="flex gap-1 items-center">
              <y-icon icon="calendar-days" />
              <span class="font-medium">Best Use Before:</span>{{ useBefore }}
            </div>
            <div class="flex gap-1 items-center">
              <y-icon icon="location-dot-solid" />
              <span class="font-medium">Farm(s):</span
              ><a
                v-if="lot?.farms.length > 0"
                href="#"
                @click.prevent="openGrowerModal = true"
                :title="farmList.join(', ')"
                >{{ farms }}</a
              ><span v-else>{{ farms }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="lot-lookup-single__top-row__buttons flex gap-2 flex-wrap justify-center md:justify-end print:hidden"
      >
        <y-button
          icon="scale-unbalanced-solid"
          background-color="transparent"
          label="Add to Compare"
          :mobile-fill="false"
          @click="openAddLotModal = true"
        />
        <LotSearchModal
          :open="openAddLotModal"
          @update:open="(state) => (openAddLotModal = state)"
        />
        <div v-if="false" class="relative" v-on-click-outside="closeFilterMenu">
          <y-button
            icon="filter-solid"
            background-color="transparent"
            label="Filters"
            :mobile-fill="false"
            @click="openFilterMenu = !openFilterMenu"
          />
          <FilterMenu :open="openFilterMenu" :is-chart="true" v-model="selectedFilters" />
        </div>
        <y-button
          icon="file-csv"
          background-color="transparent"
          label="Download"
          :mobile-fill="false"
          @click="download()"
        />
        <y-button
          icon="file-pdf"
          background-color="transparent"
          label="Print"
          :mobile-fill="false"
          @click="window.print()"
        />
      </div>
    </div>

    <div class="lot-lookup-single__results grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 w-full">
      <div
        class="lot-lookup-single__results__col flex flex-col w-full gap-4"
        v-if="showAlphaBrewingSurvivableChartColumn"
      >
        <alpha-acid />
        <brewing-values />
        <survivable-compounds />
      </div>

      <div
        class="lot-lookup-single__results__col flex flex-col w-full gap-4"
        v-if="showAromaChartColumn && hasAromaData"
      >
        <aroma-profile />
        <aromas />
      </div>
      <div
        v-if="showAromaChartColumn && !hasAromaData"
        class="lot-lookup-single__results__col flex flex-col w-full gap-4"
      >
        <div class="lot-result-card lot-lookup-single__results__col__aroma">
          <div
            class="flex justify-between p-4 items-center lot-lookup-single__results__col__aroma__title-row"
          >
            <div
              class="flex flex-col gap-1 lot-lookup-single__results__col__title-row__left-content"
            >
              <h4>Aroma Profile</h4>
            </div>
          </div>
          <div class="flex flex-col p-4 gap-2">
            <p>Sensory data for this lot is currently unavailable.</p>
            <p>
              Please email
              <a href="mailto:sensory@yakimachief.com">sensory@yakimachief.com</a> for more
              information.
            </p>
          </div>
        </div>
      </div>

      <div
        class="lot-lookup-single__results__col flex flex-col w-full gap-4"
        v-if="showOilComponentsChartColumn"
      >
        <TotalOil />
        <OilComponents />
        <OtherValues />
      </div>
    </div>

    <div class="lot-lookup-single__lower-section flex flex-col gap-4 print:hidden">
      <div class="lot-lookup-single__lower-section__analysis-methods flex flex-col gap-1">
        <small class="font-semibold">Analysis Methods:</small>
        <small class="font-normal"
          >UV Alpha by ASBC HOPS UV Spectro - 6A, HPLC Alpha by EBC Analytica V 7.7, Mebak Alpha 7.5
          by EBC Analytica LCV V 7.5, HPLC Beta by EBC Analytica V 7.7, UV Beta by ASBC HOPS Spectro
          - 6A, Total Oils by ASBC Hops - 13 (%v/w), Oil Profile by ASBC Hops - 17, Hop Storage
          Index by ASBC Hops - 12, HPLC by EBC Analytica V 7.7, ASBC HOPS - 4C</small
        >
        <small class="font-semibold pt-2">Terms of Use:</small>
        <small>
          <ol class="list-decimal list-inside md:list-outside">
            <li class="pb-1 font-normal">
              The YCH R&D and Quality laboratories possess the capability to analyze hop oil via
              GCMS-SCD technology and quantify a vast array of aromatic components. This technology
              allows us to explore the aroma potential of novel hop compounds—specifically
              beer-soluble compounds that survive the brewing process. For more information, view
              our
              <a href="/documents/Survivable-Compounds-Handbook-2022.pdf" target="_blank"
                >Survivable Compounds Handbook</a
              >.
            </li>
            <li class="font-normal">
              Sensory characteristics are determined by averaging the percentage of assessors who
              reported the presence of each aroma category on a “Check-All-That-Apply” (CATA)
              ballot. It should be noted that the aroma profile of a hop does not necessarily
              translate to the flavor of a beer. For questions about YCH’s Sensory Program, please
              contact your Regional Sales Manager or email
              <a href="mailto:sensory@yakimachief.com">sensory@yakimachief.com</a>.
            </li>
          </ol>
        </small>
      </div>
    </div>
    <grower-profile :farms="lot.farms" v-model:open="openGrowerModal" />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { vOnClickOutside } from '@vueuse/components'
import { YButton, YIcon } from '@yakimachief/ych-ts-component-library'
import GrowerProfile from '@/components/GrowerProfile.vue'
import useLotStore from '@/stores/LotStore'
import BrewingValues from '@/components/LotLookup/BrewingValues.vue'
import SurvivableCompounds from '@/components/LotLookup/SurvivableCompounds.vue'
import Aromas from '@/components/LotLookup/Aromas.vue'
import OilComponents from '@/components/LotLookup/OilComponents.vue'
import LotSearchModal from '@/components/LotSearchModal.vue'
import FilterMenu from '@/components/FilterMenu.vue'
import AlphaAcid from '@/components/LotLookup/AlphaAcid.vue'
import AromaProfile from '@/components/LotLookup/AromaProfile.vue'
import TotalOil from '@/components/LotLookup/TotalOil.vue'
import OtherValues from '@/components/LotLookup/OtherValues.vue'
import {
  showAlphaBrewingSurvivableChartColumn,
  showAromaChartColumn,
  showOilComponentsChartColumn
} from '@/composables/filterSettings'
import { getProductColor, getProductSlug } from '@/composables/iconsAndColors'
import { getBinDisplayString, useShowBinNumbers } from '@/composables/lotBinNumber'

const router = useRouter()
const lotStore = useLotStore()

const { showBinNumbers } = useShowBinNumbers()

const openGrowerModal = ref(false)
const openAddLotModal = ref(false)
const openFilterMenu = ref(false)
const selectedFilters = ref([])
const showConeImage = ref(true)

//_______Computed Properties_________
const lot = computed(() => {
  return lotStore.lots[0]
})

const useBefore = computed(() => {
  if (lot.value.bestBefore) {
    const bestBefore = new Date(lot.value.bestBefore)
    return bestBefore.toLocaleString('default', { month: 'long', year: 'numeric' })
  }
})

const farms = computed(() => {
  if (lot.value?.farms.length < 1) {
    return 'N/A'
  } else if (lot.value?.farms.length == 1) {
    return lot.value?.farms[0].grownBy
  } else return 'View All (' + lot.value?.farms.length.toString() + ')'
})

const farmList = computed(() => {
  return lot.value?.farms.map((farm) => farm.grownBy)
})

const productColor = computed(() => {
  return getProductColor(lot.value.productName)
})

const customStyles = computed(() => ({
  '--product-color': productColor.value
}))

//_______Functions_________
function closeFilterMenu() {
  if (openFilterMenu.value == true) {
    openFilterMenu.value = false
  }
}

function download() {
  const routeData = router.resolve({
    path: 'lookup/download',
    query: { 'lotNumber[]': lotStore.lotNumbers }
  })
  window.open(routeData.href, '_blank')
}

const hasAromaData = computed(() => {
  if (lot.value.aromas) {
    return lot.value.aromas.length > 0
  }
  return false
})
</script>
<style lang="scss">
.lot-lookup-single {
  &__top-row {
    &__lot-info {
      &__cone-image {
        height: 5.5rem;
        width: 4.5rem;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  &__results {
    &__col {
      &__alpha {
        &__title-row {
          background-color: var(--ych-red);
          height: 5rem;

          &__left-content,
          &__right-content,
          h4,
          .h1 {
            color: var(--ych-white);
          }
        }
      }

      &__aroma {
        &__title-row {
          background-color: var(--ych-blue);
          height: 5rem;

          &__left-content,
          &__right-content,
          h4,
          .h1 {
            color: var(--ych-white);
          }
        }
      }

      &__total-oil {
        &__title-row {
          background-color: var(--ych-kraft-dark);
          height: 5rem;

          &__left-content,
          &__right-content,
          h4,
          .h1 {
            color: var(--ych-white);
          }
        }
      }

      .brewing-values,
      .survivables,
      &__top-five,
      &__oils,
      &__other-values {
        &__title-row {
          background-color: var(--ych-grey-100);
          border-bottom: 2px solid var(--ych-grey-200);
        }
      }

      &__top-five__icons__icon {
        font-size: 2rem;
        color: var(--ych-red);
      }

      &__brewing-values__container__row,
      &__other-values__container__row {
        z-index: 1;

        &__labels {
          border-right: 1px solid var(--ych-grey-200);

          &__group {
            height: 1.5rem;
            align-items: center;
            gap: 0.5rem;

            small {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      &__oils__container__row,
      &__survivables__container__row {
        z-index: 1;
        height: 12rem;

        &__labels {
          border-right: 1px solid var(--ych-grey-200);

          &__group {
            height: 1.5rem;
            align-items: center;
            gap: 0.5rem;

            small {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.product {
  color: var(--product-color);
}

.striping {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

  div {
    height: 1.5rem;
    width: 100%;
    background-color: var(--ych-white);

    &:nth-child(even) {
      background-color: var(--ych-grey-100);
    }
  }
}
</style>
