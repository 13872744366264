<template>
  <div class="cryo-calculator">
    <div class="flex w-full justify-center px-4">
      <div
        class="limit-width-md w-full flex flex-col md:flex-row gap-4 justify-between items-center py-5"
      >
        <div class="flex gap-3 w-full items-center">
          <img class="product-logo" src="/img/ych-cryo_blue.svg" alt="Cryo Product Symbol" />
          <h1>Cryo<sup>&reg;</sup> Calculator</h1>
        </div>
        <div class="flex w-full gap-4 justify-start md:justify-end print:hidden">
          <y-button
            @click="showResetModal = true"
            background-color="secondary"
            icon="rotate-right"
            id="reset-btn"
            v-if="inputIsDirty"
            label="Reset"
          />

          <y-button
            @click="window.print()"
            background-color="secondary"
            label="Print"
            icon="file-pdf"
            v-if="formFilled"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full background-light-grey justify-center px-4 print:hidden">
      <h2 class="sr-only">Calculation Inputs</h2>
      <div class="limit-width-md w-full py-8">
        <!-- Section 1: Formula & Measurements -->

        <div class="flex justify-start items-start gap-1">
          <h3 class="leading-8">Currency &amp; Units</h3>
          <span
            v-tooltip="{
              html: true,
              content: `
          <h5>Currency &amp; Units</h5>
          <p class='text-white'>Select your preferred units of measure and currency.</p>
          `
            }"
          >
            <y-icon icon="circle-info-solid" />
          </span>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 pb-4 w-full gap-4">
          <y-dropdown
            label="Currency"
            :options="currencyOptions"
            v-model="selectedCurrency"
            class="grid w-full col-span-1"
            :allow-no-selection="false"
          />
          <y-dropdown
            label="Measurement Units"
            :options="measurementOptions"
            v-model="selectedMeasurement"
            class="grid w-full col-span-1"
            :allow-no-selection="false"
          />
        </div>

        <!-- Section 2: Brewery Info -->
        <div class="flex justify-start items-start gap-1">
          <h3 class="leading-8">Brewery Info</h3>
          <span
            v-tooltip="{
              html: true,
              content: `
          <h5>Brewery Info</h5>
          <p class='text-white'>Please ensure that all fields in this section are completed to view your results. Every input field requires a value to calculate your potential savings.</p>
          `
            }"
          >
            <y-icon icon="circle-info-solid" />
          </span>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 w-full gap-4 pb-4">
          <y-input
            id="batchSize"
            input-type="number"
            :min="0"
            v-model="batchSize"
            label="Standard Batch Size"
            :units="currentBarrelage"
          />
          <y-input
            id="revenuePerBbl"
            input-type="number"
            :min="0"
            :step="0.01"
            v-model="beerRevenue"
            :label="'Beer Revenue / ' + currentBarrelage"
            :units="currencySymbol + '/' + currentBarrelage"
          />

          <div class="flex flex-col justify-start">
            <div class="flex justify-start items-start gap-1">
              <label for="standardFvYield" class="cryo-calculator__label">Standard FV Yield</label>
              <span
                v-tooltip="{
                  html: true,
                  content: `
          <h5>Standard FV Yield</h5>
          <p class='text-white'>The percentage of beer you normally get out of a fermenter divided by the amount of wort that went in.</p>
          `
                }"
              >
                <y-icon icon="circle-info-solid" />
              </span>
            </div>
            <y-input
              id="standardFvYield"
              input-type="number"
              :min="0"
              :max="100"
              v-model="standardFvYield"
              @update:model-value="limitStandardFvYield"
              units="%"
            />
          </div>

          <div class="flex flex-col justify-start">
            <div class="flex justify-start items-start gap-1">
              <label for="FvYieldIncrease" class="cryo-calculator__label">FV Yield Increase</label>
              <span
                v-tooltip="{
                  html: true,
                  content: `
          <h5>FV Yield Increase</h5>
          <p class='text-white'>Your projected FV Yield Increase from switching to Cryo Hops. On average, you can expect a 6% boost, with a potential range of 2-10% depending on the extent of T-90 replacement.</p>
          `
                }"
              >
                <y-icon icon="circle-info-solid" />
              </span>
            </div>
            <y-input
              id="FvYieldIncrease"
              input-type="number"
              :min="0"
              :max="100"
              v-model="yieldIncrease"
              units="%"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 w-full gap-4">
          <div class="flex flex-col justify-start">
            <div class="flex justify-start items-start gap-1">
              <label for="hopsUsed" class="cryo-calculator__label">T-90 Used per Batch</label>
              <span
                v-tooltip="{
                  html: true,
                  content: `
          <h5>T-90 Used per Batch</h5>
          <p class='text-white'>Total amount of T90 pellets used in the dry hop, per batch.</p>
          `
                }"
              >
                <y-icon icon="circle-info-solid" />
              </span>
            </div>
            <y-input
              id="hopsUsed"
              input-type="number"
              :min="0"
              :step="0.1"
              v-model="hopsUsed"
              :units="currentWeight"
            />
          </div>

          <y-input
            id="t90Cost"
            input-type="number"
            :min="0"
            :step="0.1"
            v-model="t90Cost"
            :label="'T-90 Cost / ' + currentWeight"
            :units="currencySymbol + '/' + currentWeight"
          />
          <y-input
            id="cryoCost"
            input-type="number"
            :min="0"
            :step="0.1"
            v-model="cryoCost"
            :label="'Cryo&reg; Cost / ' + currentWeight"
            :units="currencySymbol + '/' + currentWeight"
          />

          <div class="flex flex-col justify-start">
            <div class="flex justify-start items-start gap-1">
              <label for="numBatchesSwitched" class="cryo-calculator__label whitespace-nowrap"
                >Batches Switched to Cryo&trade; / Year</label
              >
              <span
                v-tooltip="{
                  html: true,
                  content: `
          <h5>Batches Switched to Cryo&trade; / Year</h5>
          <p class='text-white'>How many batches per year are you planning on switching from T-90s to Cryo?</p>
          `
                }"
              >
                <y-icon icon="circle-info-solid" />
              </span>
            </div>
            <y-input
              id="numBatchesSwitched"
              input-type="number"
              :min="0"
              v-model="numBatchesSwitched"
              units="#"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Section 3: Calculated Values -->
    <div class="flex w-full background-cryo justify-center px-4">
      <div class="flex flex-col gap-4 limit-width-md w-full py-8">
        <h2 class="uppercase w-100 text-white text-center pb-1 h1">
          T-90 vs. Cryo Hops<sup>&reg;</sup> Results
        </h2>
        <div class="validation flex w-full pt-2 justify-center" v-if="validationMessage">
          <!-- Display if Standard FV Yield is more than 100% -->
          <p class="alert alert--warning mx-auto">
            <y-icon class="alert__icon" icon="triangle-exclamation-solid" />
            <span>{{ validationMessage }}</span>
          </p>
        </div>
        <div class="flex w-full pt-2 justify-center" v-if="!formFilled">
          <!-- Display if one of the input elements is null -->
          <div class="alert alert--info mx-auto">
            <y-icon class="alert__icon" icon="circle-info-solid" />
            <span
              >Please complete all the fields above to view your potential savings for replacing
              T-90 with Cryo Hops<sup>&reg;</sup>.</span
            >
          </div>
        </div>
        <div class="flex w-full pt-2 justify-center" v-if="!formYieldValid">
          <!-- Display if Standard FV Yield + FV Yield Increase is more than 100% -->
          <div class="alert alert--warning mx-auto">
            <y-icon class="alert__icon" icon="triangle-exclamation-solid" />
            <span
              >Please adjust your FV Yield values to be less than or equal to 100% combined.</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-4" v-if="formFilled && formYieldValid">
          <!-- Display if none of the input elements are null -->
          <p class="text-white text-center">
            Compare your standard revenue to what you can expect from replacing T-90 pellets with
            Cryo Hops® at 50% by weight. This calculator assumes an FV Yield Increase of 6%, which
            is the average value we’ve seen from brewers<span v-if="yieldIncrease !== 6"
              >, but the calculations below are based on your projected FV Yield Increase of
              {{ yieldIncrease }}%</span
            >. Replacing higher T-90 pellet doses will typically result in greater efficiency gains.
          </p>

          <div class="flex flex-col md:flex-row gap-4 pb-4">
            <div class="flex flex-col pb-2 w-full">
              <h4 class="text-center text-white w-100 pb-1">Revenue Increase / Year</h4>
              <div class="result-box background-red w-full px-6 py-3">
                <p id="revenueIncreasePerYear" class="text-white">
                  {{ currencySymbol }}{{ revenueIncreasePerYear }}
                </p>
              </div>
            </div>
            <div class="flex flex-col pb-2 w-full">
              <h4 class="text-center text-white w-100 pb-1">Revenue Increase / Batch</h4>
              <div class="result-box background-blue w-full px-6 py-3">
                <p id="cryoRevenueIncreasePerBatch" class="text-white">
                  {{ currencySymbol }}{{ cryoRevenueIncreasePerBatch }}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-col">
            <h3 class="uppercase w-100 text-white text-center pb-2">Calculation Details</h3>
            <span class="text-center text-white w-100 py-1 mb-1 background-blue"
              >T90 Pellets @ {{ standardFvYield }}% FV Yield</span
            >
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 mb-4">
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Revenue</h4>
                <div class="result-box bg-white">
                  <p id="potentialT90Revenue">{{ currencySymbol }}{{ potentialT90Revenue }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Revenue / Batch</h4>
                <div class="result-box bg-white">
                  <p id="t90RevenuePerBatch">{{ currencySymbol }}{{ t90RevenuePerBatch }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Cost of Hops / Batch</h4>
                <div class="result-box bg-white">
                  <p id="t90CostofHopsPerBatch">{{ currencySymbol }}{{ t90CostPerBatch }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Beer Output / Batch</h4>
                <div class="result-box bg-white">
                  <p id="t90BeerOutputPerBatch">{{ t90BeerPerBatch }} {{ currentBarrelage }}</p>
                </div>
              </div>
            </div>
            <span class="text-center text-white w-100 py-1 mb-1 background-blue"
              >Cryo Hops<sup>&reg;</sup> @ {{ yieldIncrease + standardFvYield }}% FV Yield</span
            >
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 mb-4">
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Potential Revenue</h4>
                <div class="result-box bg-white">
                  <p id="potentialCryoRevenue">{{ currencySymbol }}{{ potentialCryoRevenue }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Revenue / Batch</h4>
                <div class="result-box bg-white">
                  <p id="cryoRevenuePerBatch">{{ currencySymbol }}{{ cryoRevenuePerBatch }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Cost of Hops / Batch</h4>
                <div class="result-box bg-white">
                  <p id="cryoCostofHopsPerBatch">{{ currencySymbol }}{{ cryoCostPerBatch }}</p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white">Beer Output / Batch</h4>
                <div class="result-box bg-white">
                  <p id="cryoBeerOutputPerBatch">{{ cryoBeerPerBatch }} {{ currentBarrelage }}</p>
                </div>
              </div>
            </div>
            <span class="text-center text-white w-100 py-1 mb-1 background-blue"
              >@ {{ yieldIncrease }}% FV Yield Increase</span
            >
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 mb-4">
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white whitespace-nowrap">Revenue Increase / Year</h4>
                <div class="result-box bg-white">
                  <p id="revenueIncreasePerYear">
                    {{ currencySymbol }}{{ revenueIncreasePerYear }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white whitespace-nowrap">Revenue Increase / Batch</h4>
                <div class="result-box bg-white">
                  <p id="cryoRevenueIncreasePerBatch">
                    {{ currencySymbol }}{{ cryoRevenueIncreasePerBatch }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white whitespace-nowrap">
                  Beer Output Increase / Year
                </h4>
                <div class="result-box bg-white">
                  <p id="beerOutputIncreasePerYear">
                    {{ extraBeerPerYear }} {{ currentBarrelage }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-1 pb-2">
                <h4 class="text-center text-white whitespace-nowrap">
                  Beer Output Increase / Batch
                </h4>
                <div class="result-box bg-white">
                  <p id="beerOutputPerBatch">{{ beerPerBatchIncrease }} {{ currentBarrelage }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section 4: Testimonials -->
    <div class="flex w-full justify-center px-4 background-blue print:hidden">
      <div class="limit-width-md w-full py-8">
        <div class="row">
          <h2 class="text-center text-white uppercase w-100 py-2 h1">
            <span class="sr-only">Testimonial Section: </span>We’ll let the brewers speak for
            themselves
          </h2>
          <div class="testimonials w-100 pt-4">
            <swiper-container
              class="swiper-slider-container"
              :slides-per-view="1"
              :space-between="spaceBetween"
              :centered-slides="false"
              :loop="true"
              :pagination="{
                clickable: true
              }"
              :autoplay-delay="4500"
              :autoplay-disable-on-interaction="false"
              :injectStyles="[
                `
                                  span.swiper-pagination-bullet {
                                    background: #fff;
                                    opacity: .6;
                                  }
                                  span.swiper-pagination-bullet-active-main {
                                    background: #fff;
                                    opacity: 1;
                                  }
                                  `
              ]"
              :pagination-dynamic-bullets="true"
              :autoplay="{
                delay: 6000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
              }"
              :breakpoints="{
                576: {
                  slidesPerView: 1,
                  spaceBetween: 16
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 16
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 24
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 32
                }
              }"
              @progress="onProgress"
            >
              <swiper-slide class="swiper-slide-container" v-for="testimonial in testimonials">
                <Testimonial :logo-src="testimonial.logo" :logo-alt="testimonial.logo_alt">
                  <template v-slot:quote>
                    <div v-html="testimonial.content"></div>
                  </template>
                  <template v-slot:name>
                    <!-- Name,<br />Brewery,<br />Location -->
                    {{ testimonial.attribution }} {{ testimonial.brewery }}<br />
                    {{ testimonial.location }}
                  </template>
                </Testimonial>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
    </div>
    <!-- Resources -->
    <div class="resources flex w-full justify-center px-4">
      <div class="flex flex-col items-center justify-center limit-width-md w-full py-8">
        <div class="flex flex-col sm:flex-row w-100 justify-center gap-2 sm:gap-4">
          <div class="flex w-full sm:w-40 flex-col items-center justify-center mb-1">
            <img class="product-logo" src="/img/ych-cryo_blue.svg" alt="Cryo Product Symbol" />
          </div>
          <div class="flex w-full flex-col py-3 print:hidden">
            <div class="flex w-full justify-center sm:justify-start text-center sm:text-left">
              <h2 class="uppercase h1">
                <span class="sr-only">Resources & Support: </span>Cryo Hops<sup>&reg;</sup> Product
                Sheets
              </h2>
            </div>
            <div class="flex">
              <small class="mb-1 w-100">
                Download our CRYO HOPS<sup>&reg;</sup> Product Sheets
              </small>
            </div>
            <div
              class="flex flex-col md:flex-row justify-center md:justify-start links gap-1 md:gap-2 pt-1 pb-3"
            >
              <div class="pr-2">
                <a
                  href="https://www.yakimachief.com/media/documents/Cryo_Hops_Product_Data_Sheet.pdf"
                  target="_blank"
                  class="uppercase"
                >
                  <y-icon icon="file-pdf" />
                  Product Data Sheet</a
                >
              </div>
              <div class="pr-2">
                <a
                  href="https://www.yakimachief.com/media/documents/Cryo_Hops_Product_Overview_Sheet_1.pdf"
                  target="_blank"
                  class="uppercase"
                >
                  <y-icon icon="file-pdf" />
                  Product Overview Sheet</a
                >
              </div>
              <div>
                <a
                  href="https://www.yakimachief.com/media/documents/Cryo_Hops_FAQs.pdf"
                  target="_blank"
                  class="uppercase"
                >
                  <y-icon icon="file-pdf" />
                  Cryo Hops&reg; vs Others FAQ</a
                >
              </div>
            </div>
            <div class="flex w-full">
              <y-button
                background-color="secondary"
                size="sm"
                :show-icon="false"
                :mobile-fill="false"
                onclick="window.open('https://www.yakimachief.com/commercial/contact','_blank');"
                ><span>Question about Cryo Hops<sup>&reg;</sup>? Contact us!</span></y-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="how-to-video print:hidden flex w-full justify-center px-4">
      <div class="limit-width-md w-full py-8">
        <div class="flex w-100 justify-center">
          <div class="flex flex-col items-center justify-center pt-2">
            <iframe
              width="640"
              height="360"
              src="https://www.youtube.com/embed/6drrl9UuEdo"
              title="How to use the Cryo Calculator"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <reset-calculator-modal
      :open="showResetModal"
      calculator-name="Cryo Calculator"
      @confirmed="resetInputs"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import Testimonial from './Testimonial.vue'
import { YButton, YDropdown, YIcon, YInput } from '@yakimachief/ych-ts-component-library'
import { register } from 'swiper/element/bundle'
import 'swiper/css'
import 'swiper/css/pagination'
import axios from 'axios'
import ResetCalculatorModal from '@/components/ResetCalculatorModal.vue'

interface TestimonialData {
  id: number
  logo: string
  logo_alt: string
  content: string
  attribution: string
  brewery: string
  location: string
  created_at: string
  updated_at: string
  deleted_at: string | null
}

//_________SWIPER PLUGIN_________
register()
const spaceBetween = 16
const onProgress = (e) => {
  const [swiper, progress] = e.detail
}

//_________VARIABLES/REFERENCES_________
const selectedCurrency = ref('USD')
const validationMessage = ref<string | null>(null)
const selectedMeasurement = ref('Imperial')
const batchSize = ref<string | null>(null)
const beerRevenue = ref<string | null>(null)
const hopsUsed = ref<string | null>(null)
const t90Cost = ref<string | null>(null)
const cryoCost = ref<string | null>(null)
const standardFvYield = ref<string | null>(null)
const yieldIncrease = ref(6)
const numBatchesSwitched = ref<string | null>(null)
const cryoToT90Ratio = ref(0.5)
const showResetModal = ref(false)
const testimonials: Ref<TestimonialData[]> = ref([])
const inputIsDirty = ref(false)
const formFilled = ref(false)
const formYieldValid = ref(true)
const currencyOptions = ['USD', 'EUR', 'GBP', 'CAD', 'RMB']
const measurementOptions = ['Imperial', 'Metric']

//_________COMPUTED PROPERTIES_________
const currencySymbol = computed(() => {
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    CAD: 'C$',
    RMB: '¥'
  }
  return currencySymbols[selectedCurrency.value] ?? '$'
})

const currentWeight = computed(() => {
  const currentWeightUnit = {
    Imperial: 'lb',
    Metric: 'kg'
  }
  return currentWeightUnit[selectedMeasurement.value] ?? 'lb'
})

const currentBarrelage = computed(() => {
  const currentBarrelageUnit = {
    Imperial: 'bbl',
    Metric: 'hL'
  }
  return currentBarrelageUnit[selectedMeasurement.value] ?? 'bbl'
})

const yieldIncreaseLimit = computed(() => {
  // The average Cryo FV Yield (%)
  let limitValue = yieldIncrease
  if (standardFvYield && standardFvYield.value + yieldIncrease.value > 100) {
    limitValue = 100 - standardFvYield.value
  }
  return limitValue
})

const t90BeerPerBatch = computed(() => {
  // Standard Beer per batch (bbl)
  if (standardFvYield && batchSize) {
    let t90BeerPerBatch = (standardFvYield.value / 100) * batchSize.value
    return t90BeerPerBatch.toFixed(1)
  }
})

const cryoBeerPerBatch = computed(() => {
  // Potential Extra Beer per batch (bbl)
  if (standardFvYield && batchSize) {
    let cryoBeerPerBatch = ((standardFvYield.value + yieldIncrease.value) / 100) * batchSize.value
    return cryoBeerPerBatch.toFixed(1)
  }
})

const beerPerBatchIncrease = computed(() => {
  // Average Extra Beer per batch (bbl)
  if (cryoBeerPerBatch && t90BeerPerBatch) {
    let beerPerBatchIncrease = cryoBeerPerBatch.value - t90BeerPerBatch.value
    return beerPerBatchIncrease.toFixed(1)
  }
})

const extraBeerPerYear = computed(() => {
  // Average Extra Beer Per Year (bbl)
  if (numBatchesSwitched) {
    let extraBeerPerYear = beerPerBatchIncrease.value * numBatchesSwitched.value
    return extraBeerPerYear.toFixed(1)
  }
})

const t90RevenuePerBatch = computed(() => {
  // Formula for potential T-90 revenue per batch ($USD):
  if (standardFvYield && batchSize && beerRevenue) {
    let potentialT90Revenue =
      (standardFvYield.value / 100) * batchSize.value * beerRevenue.value -
      Number(t90CostPerBatch.value?.replace(/,/g, ''))
    potentialT90Revenue = Math.round(potentialT90Revenue)
    return potentialT90Revenue.toLocaleString()
  }
})

const cryoRevenuePerBatch = computed(() => {
  // Formula for potential Cryo revenue per batch ($USD):
  if (standardFvYield && batchSize && beerRevenue) {
    let cryoRevenuePerBatch =
      ((standardFvYield.value + yieldIncrease.value) / 100) * batchSize.value * beerRevenue.value -
      Number(cryoCostPerBatch.value?.replace(/,/g, ''))
    cryoRevenuePerBatch = Math.round(cryoRevenuePerBatch)
    return cryoRevenuePerBatch.toLocaleString()
  }
})

const t90CostPerBatch = computed(() => {
  // Formula for cost of T90 per batch ($):
  if (hopsUsed && t90Cost) {
    let t90CostPerBatch = hopsUsed.value * t90Cost.value
    t90CostPerBatch = Math.round(t90CostPerBatch)
    return t90CostPerBatch.toLocaleString()
  }
})

const cryoCostPerBatch = computed(() => {
  // Formula for cost of T90 per batch ($):
  if (hopsUsed && cryoCost) {
    let cryoCostPerBatch = cryoToT90Ratio.value * hopsUsed.value * cryoCost.value
    cryoCostPerBatch = Math.round(cryoCostPerBatch)
    return cryoCostPerBatch.toLocaleString()
  }
})

const cryoRevenueIncreasePerBatch = computed(() => {
  // Formula for potential extra revenue per batch ($USD):
  let cryoRevenueIncreasePerBatch =
    Number(cryoRevenuePerBatch.value?.replace(/,/g, '')) -
    Number(t90RevenuePerBatch.value?.replace(/,/g, ''))
  cryoRevenueIncreasePerBatch = Math.round(cryoRevenueIncreasePerBatch)
  return cryoRevenueIncreasePerBatch.toLocaleString()
})

const revenueIncreasePerYear = computed(() => {
  // Potential Revenue Increase per Year ($)
  if (numBatchesSwitched) {
    let revenueIncreasePerYear =
      Number(cryoRevenueIncreasePerBatch.value?.replace(/,/g, '')) * numBatchesSwitched.value
    revenueIncreasePerYear = Math.round(revenueIncreasePerYear)
    return revenueIncreasePerYear.toLocaleString()
  }
})

const potentialT90Revenue = computed(() => {
  // Total T90 Revenue ($)
  if (numBatchesSwitched) {
    let potentialT90Revenue =
      Number(t90RevenuePerBatch.value?.replace(/,/g, '')) * numBatchesSwitched.value
    potentialT90Revenue = Math.round(potentialT90Revenue)
    return potentialT90Revenue.toLocaleString()
  }
})

const potentialCryoRevenue = computed(() => {
  // Total Cryo Revenue (at average FV Yield Increase) ($)
  let potentialCryoRevenue =
    Number(revenueIncreasePerYear.value?.replace(/,/g, '')) +
    Number(potentialT90Revenue.value?.replace(/,/g, ''))
  potentialCryoRevenue = Math.round(potentialCryoRevenue)
  return potentialCryoRevenue.toLocaleString()
})

//_________WATCHERS_________
watch(batchSize, (newValue) => {
  if (newValue !== null) {
    batchSize.value = coerceNegative(newValue, 'Batch Size')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(beerRevenue, (newValue) => {
  if (newValue !== null) {
    beerRevenue.value = coerceNegative(newValue, 'Beer Revenue')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(hopsUsed, (newValue) => {
  if (newValue !== null) {
    hopsUsed.value = coerceNegative(newValue, 'Hops Used')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(t90Cost, (newValue) => {
  if (newValue !== null) {
    t90Cost.value = coerceNegative(newValue, 'T-90 Cost')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(cryoCost, (newValue) => {
  if (newValue !== null) {
    cryoCost.value = coerceNegative(newValue, 'Cryo Cost')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(standardFvYield, (newValue) => {
  if (newValue !== null) {
    standardFvYield.value = coerceNegative(newValue, 'FV Yield')
  }
  updateFormFilled()
  updateFormValid()
  updateInputDirty()
})

watch(numBatchesSwitched, (newValue) => {
  if (newValue !== null) {
    numBatchesSwitched.value = coerceNegative(newValue, 'Number of Batches Switched')
  }
  updateFormFilled()
  updateInputDirty()
})

watch(yieldIncrease, (newValue) => {
  if (newValue !== null) {
    yieldIncrease.value = coerceNegative(newValue, 'Yield Increase')
  }
  updateFormFilled()
  updateFormValid()
})

//_________FUNCTIONS_________
function updateFormFilled() {
  // Check if any input is null
  let inputs = [
    batchSize.value,
    beerRevenue.value,
    hopsUsed.value,
    t90Cost.value,
    cryoCost.value,
    standardFvYield.value,
    numBatchesSwitched.value,
    yieldIncrease.value
  ]
  formFilled.value = inputs.every((input) => input !== null && input !== '')
}

function updateInputDirty() {
  // Check if any input (other than yieldIncrease because that is set to 6 by default) has a value
  let inputs: (string | null)[] = [
    batchSize.value,
    beerRevenue.value,
    hopsUsed.value,
    t90Cost.value,
    cryoCost.value,
    standardFvYield.value,
    numBatchesSwitched.value
  ]
  inputIsDirty.value = inputs.some((input) => input !== null && input !== '')
}

function updateFormValid() {
  if (standardFvYield && yieldIncrease && standardFvYield.value + yieldIncrease.value > 100) {
    formYieldValid.value = false
  } else {
    formYieldValid.value = true
  }
}

function limitStandardFvYield(value) {
  if (standardFvYield && standardFvYield.value > 100) {
    standardFvYield.value = 100
    validationMessage.value = 'Standard FV Yield cannot exceed 100%'
    setTimeout(() => {
      validationMessage.value = null
    }, 5000)
  } else {
    validationMessage.value = null
  }
}

function coerceNegative(value, name) {
  if (value == '' || value < 0) {
    return null
  }
  return value
}

function resetInputs() {
  batchSize.value = null
  beerRevenue.value = null
  hopsUsed.value = null
  t90Cost.value = null
  cryoCost.value = null
  yieldIncrease.value = 6
  standardFvYield.value = null
  numBatchesSwitched.value = null
  selectedCurrency.value = 'USD'
  selectedMeasurement.value = 'Imperial'
  showResetModal.value = false
}

onMounted(() => {
  axios.get('/api/cryo/testimonials').then((response) => {
    response.data.testimonials.forEach((testimonial: string) => {
      testimonials.value.push(testimonial)
    })
  })
})
</script>
<style scoped lang="scss">
.cryo-calculator {
  h2.text-white {
    color: var(--ych-white);
  }

  .product-logo {
    width: 100%;
    max-width: 4.5rem;
  }

  .alert {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    color: var(--ych-white);
    border: 2px solid;
    background-color: var(--ych-white);

    &__icon {
      font-size: 1.4rem;
    }

    &--info {
      border-color: var(--ych-blue);
      color: var(--ych-blue);
    }

    &--warning {
      border-color: var(--ych-warning);
      color: var(--ych-blue);

      .alert__icon {
        color: var(--ych-warning);
      }
    }

    &--error {
      border-color: var(--ych-red);
      color: var(--ych-blue);

      .alert__icon {
        color: var(--ych-red);
      }
    }
  }

  &__label {
    line-height: 2rem;
    max-height: 2rem;
    display: inline-flex;
    font-weight: var(--y-font-weight-medium);
  }

  .background-cryo {
    background: var(--ych-cryo);
  }

  .background-red {
    background: var(--ych-red);
  }

  .background-blue {
    background: var(--ych-blue);
  }

  .background-light-grey {
    background-color: var(--ych-grey-100);
  }

  .result-box {
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    p {
      font-size: 2rem;
      padding: 0;
      margin: 0;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      font-weight: 900;
    }
  }
  .swiper-slider-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2.5rem;
  }
  .swiper-slide-container {
    height: auto;
    align-self: stretch;
  }

  .resources .product-logo {
    width: 100%;
    max-width: 9.375rem;
  }

  .how-to-video {
    background-image: url(/public/img/cryo-hops.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #002b49;
  }
}

@media (min-width: 990px) {
  .resources .links div:nth-child(-n + 2) {
    border-right: 2px solid #002b49;
  }
  .resources .links div {
    width: auto;
  }
}
</style>
