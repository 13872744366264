import type Lot from '@/types/Lot'

type GroupedLot = {
  index: number
  lot: Lot
}
type GroupedLots = {
  key: string
  product: string
  variety: string
  lots: GroupedLot[]
}

export default function groupLots<T extends Record<string, any>>(
  lots: Lot[],
  property1: keyof Lot,
  property2: keyof Lot
): GroupedLots[] {
  const grouped: GroupedLots[] = []

  const groupsMap: Map<string, { index: number; lot: Lot }[]> = new Map()

  lots.forEach((lot, index) => {
    const key = `${lot[property1]}-${lot[property2]}`
    if (!groupsMap.has(key)) {
      groupsMap.set(key, [])
    }
    groupsMap.get(key)?.push({ index, lot: { ...lot } })
  })

  groupsMap.forEach((lotsWithOriginalIndex, key) => {
    grouped.push({
      key,
      product: lotsWithOriginalIndex[0].lot.productName,
      variety: lotsWithOriginalIndex[0].lot.variety,
      lots: lotsWithOriginalIndex
    })
  })

  return grouped
}

export type { GroupedLots, GroupedLot }
