<template>
  <div class="brewing-values">
    <Bar class="brewing-values__chart" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
import type { ChartData } from 'chart.js'
import { percentageChartOptions as chartOptions } from './chartOptions'
import { lotColors } from '@/components/LotLookup'
import { showKey as origShowKey } from '@/composables/filterSettings'

const showKey = (keyName: string) => true

Chart.register(...registerables)
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'
import { lotHasChartBrewingValues } from '@/composables/hasChartBrewingValues'
import {
  lotsHaveHplcHexaAlpha,
  lotsHaveHplcIsoAlpha,
  lotsHaveHplcRho,
  lotsHaveHplcTetraAlpha,
  lotsHaveLcvAlpha74,
  lotsHaveUvIsoAlpha
} from '@/composables/lotAlpha'

const lotStore = useLotStore()

const labels = computed(() => {
  let labels = []
  if (showKey('uvAlpha')) {
    labels.push('UV Alpha')
  }

  if (showKey('uvIsoAlpha') && lotsHaveUvIsoAlpha.value) {
    labels.push('UV Iso-Alpha')
  }
  if (showKey('uvBeta')) {
    labels.push('UV Beta')
  }
  if (showKey('hplcAlpha')) {
    labels.push('HPLC Alpha')
  }
  if (showKey('hplcBeta')) {
    labels.push('HPLC Beta')
  }
  if (showKey('mebakAlpha')) {
    labels.push('Mebak Alpha')
  }
  if (showKey('lcvAlpha') && lotsHaveLcvAlpha74.value) {
    labels.push('LCV Alpha 7.4')
  }
  if (showKey('hplcRho') && lotsHaveHplcRho.value) {
    labels.push('HPLC Rho Alpha')
  }
  if (showKey('hlpcIsoAlpha') && lotsHaveHplcIsoAlpha.value) {
    labels.push('HPLC Iso-Alpha')
  }
  if (showKey('hexAlpha') && lotsHaveHplcHexaAlpha.value) {
    labels.push('HPLC Hexa Alpha')
  }
  if (showKey('tetAlpha') && lotsHaveHplcTetraAlpha.value) {
    labels.push('HPLC Tetra Alpha')
  }
  return labels
})

const chartData = computed(() => {
  let datasets = []
  if (lotStore.lots.length !== 1) {
    for (let i = 0; i < lotStore.lots.length; i++) {
      if (lotHasChartBrewingValues(lotStore.lots[i])) {
        datasets.push({
          label: lotStore.lots[i].lotNumber ?? 'Lot ' + i + 1,
          data: getLotDataSet(lotStore.lots[i]),
          backgroundColor: lotColors[i]
        })
      }
    }
  } else {
    datasets.push({
      label: lotStore.lots[0].lotNumber ?? 'Lot 1',
      data: getLotDataSet(lotStore.lots[0]),
      backgroundColor: lotColors[2]
    })
  }

  return {
    labels: labels.value,
    datasets
  } as ChartData<'bar'>
})

const getLotDataSet = (lot: Lot) => {
  let data = []
  if (showKey('uvAlpha')) {
    if (lot.brewingValues?.uvAlpha != null) {
      data.push((lot.brewingValues?.uvAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveUvIsoAlpha.value && showKey('uvIsoAlpha')) {
    if (lot.brewingValues?.uvIsoAlpha != null) {
      data.push((lot.brewingValues?.uvIsoAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('uvBeta')) {
    if (lot.brewingValues?.uvBeta != null) {
      data.push((lot.brewingValues?.uvBeta ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('hplcAlpha')) {
    if (lot.brewingValues?.hplcAlpha != null) {
      data.push((lot.brewingValues?.hplcAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('hplcBeta')) {
    if (lot.brewingValues?.hplcBeta != null) {
      data.push((lot.brewingValues?.hplcBeta ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('mebakAlpha')) {
    if (lot.brewingValues?.mebakAlpha != null) {
      data.push((lot.brewingValues?.mebakAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveLcvAlpha74.value && showKey('lcvAlpha')) {
    if (lot.brewingValues?.lcvAlpha != null) {
      data.push((lot.brewingValues?.lcvAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveHplcRho.value && showKey('hplcRho')) {
    if (lot.brewingValues?.hplcRho != null) {
      data.push((lot.brewingValues?.hplcRho ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveHplcIsoAlpha.value && showKey('hlpcIsoAlpha')) {
    if (lot.brewingValues?.hplcIsoAlpha != null) {
      data.push((lot.brewingValues?.hplcIsoAlpha ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveHplcHexaAlpha.value && showKey('hexAlpha')) {
    if (lot.brewingValues?.hplcHex != null) {
      data.push((lot.brewingValues?.hplcHex ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  if (lotsHaveHplcTetraAlpha.value && showKey('tetAlpha')) {
    if (lot.brewingValues?.hplcTet != null) {
      data.push((lot.brewingValues?.hplcTet ?? 0) / 100)
    } else {
      data.push(null)
    }
  }
  return data
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.brewing-values {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__chart {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    p {
      display: none;
    }
  }
}
</style>
