<template>
  <div v-if="showKey('uvAlphaChart')" class="lot-result-card" :class="cssBaseClassName">
    <div class="flex justify-between p-4 items-center" :class="cssBaseClassName + '__title-row'">
      <div class="flex flex-col gap-1" :class="cssBaseClassName + '__title-row__left-content'">
        <h4>Alpha Acid</h4>
        <small>vs Typical Variety Range</small>
      </div>
      <div
        class="flex flex-col items-center"
        :class="cssBaseClassName + '__title-row__right-content'"
      >
        <span class="h1" v-if="alpha?.value">{{ alpha?.value }}</span>
        <small>{{ alpha ? alpha.name : 'Percentage' }} (%)</small>
      </div>
    </div>
    <div
      class="p-2 flex flex-col gap-2"
      :class="cssBaseClassName + '__chart'"
      v-if="hasAlphaToDisplay"
    >
      <AlphaAcidsChartSingleLot v-if="lotStore.lots.length === 1" />
      <AlphaAcidsChart
        v-else
        :grouped-lots="lots"
        v-for="lots in groupedLots"
        :high-alpha="highAlpha"
      />
    </div>
    <div class="flex flex-col p-4 gap-2" v-else>
      <p v-if="!hasChartBrewingValues">
        Brewing Value data for this lot is unavailable at this time.
      </p>
      <p v-else>Alpha acid data for this lot is unavailable at this time.</p>
      <p>
        Please email <a href="mailto:quality@yakimachief.com">quality@yakimachief.com</a> for more
        information.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import { showKey } from '@/composables/filterSettings'
import AlphaAcidsChart from '@/components/Charts/AlphaAcidsChart.vue'
import AlphaAcidsChartSingleLot from '@/components/Charts/AlphaAcidsChartSingleLot.vue'
import groupLots from '@/composables/groupLots'
import hasChartBrewingValues from '@/composables/hasChartBrewingValues'
import { getLotAlpha } from '@/components/LotLookup/index'

const lotStore = useLotStore()

const uvAlpha = computed(() => {
  if (lotStore.lots.length === 1) {
    return lotStore.lots[0].brewingValues?.uvAlpha
  }
})

const cssBaseClassName = computed(() => {
  if (lotStore.lots.length === 1) {
    return 'lot-lookup-single__results__col__alpha'
  }
  return 'lot-lookup-multi__results__col__alpha'
})

const groupedLots = computed(() => {
  return groupLots(lotStore.lots, 'variety', 'productName')
})

const hasAlphaToDisplay = computed(() => {
  let hasAlpha = false
  lotStore.lots.forEach((lot) => {
    if (getLotAlpha(lot) !== null) {
      hasAlpha = true
    }
  })
  return hasAlpha
})
const alpha = computed(() => {
  if (lotStore.lots.length === 1) {
    return getLotAlpha(lotStore.lots[0])
  }
  return null
})
const highAlpha = computed(() => {
  let high = 28
  lotStore.lots.forEach((lot) => {
    const alpha = getLotAlpha(lot)
    if (alpha && alpha.value > high) {
      high = Math.ceil(alpha.value * 1.05)
    }
    if (lot.brewingRanges && lot.brewingRanges.alphaHigh > high) {
      high = Math.ceil(lot.brewingRanges.alphaHigh * 1.05)
    }
  })
  if (high > 100) {
    return 100
  }
  return high
})
</script>
