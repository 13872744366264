<template>
  <div class="survivables">
    <Bar class="survivables__chart" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
import type { ChartData } from 'chart.js'
import { lotColors } from '@/components/LotLookup'
import { survivableCompoundsChartOptions as chartOptions } from '@/components/Charts/chartOptions'

Chart.register(...registerables)
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'
import { showKey as origShowKey } from '@/composables/filterSettings'
import { lotHasChartSurvivables } from '@/composables/hasChartSurvivableCompounds'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

function getLotDataSet(lot: Lot) {
  let data = []
  if (showKey('isobutylIsobutyrate')) {
    if (lot.survivables?.isobutylIsobutyrate != null) {
      data.push(lot.survivables?.isobutylIsobutyrate)
    } else {
      data.push(null)
    }
  }
  if (showKey('isoamylIsobutyrate')) {
    if (lot.survivables?.isoamylIsobutyrate != null) {
      data.push(lot.survivables?.isoamylIsobutyrate)
    } else {
      data.push(null)
    }
  }
  if (showKey('twoMethylbutylIsobutyrate')) {
    if (lot.survivables?.twoMethylbutylIsobutyrate != null) {
      data.push(lot.survivables?.twoMethylbutylIsobutyrate)
    } else {
      data.push(null)
    }
  }
  if (showKey('linaloolSurvivable')) {
    if (lot.survivables?.linalool != null) {
      data.push(lot.survivables?.linalool)
    } else {
      data.push(null)
    }
  }
  if (showKey('geraniolSurvivable')) {
    if (lot.survivables?.geraniol != null) {
      data.push(lot.survivables?.geraniol)
    } else {
      data.push(null)
    }
  }
  if (showKey('twoNonanone')) {
    if (lot.survivables?.twoNonanone != null) {
      data.push(lot.survivables?.twoNonanone)
    } else {
      data.push(null)
    }
  }
  if (showKey('methylGeranate')) {
    if (lot.survivables?.methylGeranate != null) {
      data.push(lot.survivables?.methylGeranate)
    } else {
      data.push(null)
    }
  }
  if (showKey('threeMercaptohexanol')) {
    if (lot.survivables?.threeMercaptohexanol != null) {
      data.push(lot.survivables?.threeMercaptohexanol)
    } else {
      data.push(null)
    }
  }
  return data
}

const chartData = computed(() => {
  const dataset = getLotDataSet(lotStore.lots[0])

  let labels = []
  if (showKey('isobutylIsobutyrate')) {
    labels.push('Isobutyl Isobutyrate')
  }
  if (showKey('isoamylIsobutyrate')) {
    labels.push('Isoamyl Isobutyrate')
  }
  if (showKey('twoMethylbutylIsobutyrate')) {
    labels.push('2-Methylbutyl Isobutyrate')
  }
  if (showKey('linaloolSurvivable')) {
    labels.push('Linalool')
  }
  if (showKey('geraniolSurvivable')) {
    labels.push('Geraniol')
  }
  if (showKey('twoNonanone')) {
    labels.push('2-Nonanone')
  }
  if (showKey('methylGeranate')) {
    labels.push('Methyl Geranate')
  }
  if (showKey('threeMercaptohexanol')) {
    labels.push('3-Mercaptohexanol')
  }

  if (lotStore.lots.length === 1) {
    return {
      labels: labels,
      datasets: [
        {
          data: dataset,
          backgroundColor: lotColors[2]
        }
      ]
    } as ChartData<'bar'>
  }

  let datasets = []

  for (let i = 0; i < lotStore.lots.length; i++) {
    if (lotHasChartSurvivables(lotStore.lots[i])) {
      datasets.push({
        label: lotStore.lots[i].lotNumber ?? 'Lot ' + i + 1,
        data: getLotDataSet(lotStore.lots[i]),
        backgroundColor: lotColors[i]
      })
    }
  }
  return {
    labels,
    datasets
  } as ChartData<'bar'>
})
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.survivables {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__chart {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    p {
      display: none;
    }
  }
}
</style>
