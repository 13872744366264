import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'

export const hasChartBrewingValues = computed(() => {
  const lotStore = useLotStore()
  let hasBrewingValues = false
  lotStore.lots.forEach((lot) => {
    if (lotHasChartBrewingValues(lot)) {
      hasBrewingValues = true
    }
  })
  return hasBrewingValues
})

export function lotHasChartBrewingValues(lot: Lot) {
  if (lot.brewingValues) {
    if (
      lot.brewingValues.uvAlpha ||
      lot.brewingValues.uvBeta ||
      lot.brewingValues.hplcAlpha ||
      lot.brewingValues.hplcBeta ||
      lot.brewingValues.mebakAlpha ||
      lot.brewingValues.hplcTet
    ) {
      return true
    }
  }
  return false
}

export default hasChartBrewingValues
