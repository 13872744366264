<template>
  <div ref="resizeRef" class="single-alpha-chart flex flex-row grow h-12">
    <svg ref="svgRef" class="h-full w-full" />
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, watchEffect, computed } from 'vue'
import { select, scaleLinear } from 'd3'
import useLotStore from '@/stores/LotStore'
import { useResizeObserver } from '@/composables/resizeObserver'
import { getLotAlpha } from '@/components/LotLookup'

const lotStore = useLotStore()

const svgRef = ref(null)
const { resizeRef, resizeState } = useResizeObserver()

const alpha = computed(() => {
  if (lotStore.lots.length === 1) {
    const alpha = getLotAlpha(lotStore.lots[0])
    if (alpha) {
      return alpha.value
    }
  }
  return null
})

const low = computed(() => {
  if (lotStore.lots.length === 1) {
    if (alpha.value && lotStore.lots[0].brewingRanges?.alphaLow !== undefined) {
      if (alpha.value < lotStore.lots[0].brewingRanges?.alphaLow) {
        return Math.floor(alpha.value)
      }
    }
    return lotStore.lots[0].brewingRanges?.alphaLow ?? 0
  }
  return 0
})

const rangeLow = computed(() => {
  if (lotStore.lots.length === 1) {
    return lotStore.lots[0].brewingRanges?.alphaLow ?? 0
  }
  return 0
})

const high = computed(() => {
  if (lotStore.lots.length === 1) {
    if (alpha.value && lotStore.lots[0].brewingRanges?.alphaHigh !== undefined) {
      if (alpha.value > lotStore.lots[0].brewingRanges?.alphaHigh) {
        return Math.ceil(alpha.value)
      }
    } else if (alpha.value && alpha.value > 28) {
      const high = Math.ceil(alpha.value * 1.05)
      if (high > 100) {
        return 100
      }
      return high
    }
    return lotStore.lots[0].brewingRanges?.alphaHigh ?? 28
  }
  return 0
})

const rangeHigh = computed(() => {
  if (lotStore.lots.length === 1) {
    return lotStore.lots[0].brewingRanges?.alphaHigh ?? 0
  }
  return 0
})

const avg = computed(() => {
  if (lotStore.lots.length === 1) {
    return lotStore.lots[0].brewingRanges?.alphaAve ?? null
  }
  return 0
})

onMounted(() => {
  const svg = select(svgRef.value)
  watchEffect(() => {
    const { width, height } = resizeState.dimensions
    // Clear out SVG on resize
    svg.text('')
    const fivePercent = (high.value - low.value) * 0.05
    const scaleLow = low.value - fivePercent
    const scaleHigh = high.value + fivePercent
    const center = height / 2

    // X axis data scaler
    const xScaler = scaleLinear()
      .domain([scaleLow, scaleHigh])
      .range([40, width - 40])

    // range box
    svg
      .append('rect')
      .attr('x', xScaler(rangeLow.value))
      .attr('y', center - 4)
      .attr('width', xScaler(rangeHigh.value) - xScaler(rangeLow.value))
      .attr('height', 8)
      .attr('stroke', 'none')
      .attr('fill', 'rgba(42,47,87,0.22)')

    // Horizontal Line
    svg
      .append('line')
      .attr('y1', center)
      .attr('y2', center)
      .attr('x1', xScaler(scaleLow))
      .attr('x2', xScaler(scaleHigh))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Left bound line
    svg
      .append('line')
      .attr('y1', center + 8)
      .attr('y2', center - 8)
      .attr('x1', xScaler(scaleLow))
      .attr('x2', xScaler(scaleLow))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Right bound line
    svg
      .append('line')
      .attr('y1', center + 8)
      .attr('y2', center - 8)
      .attr('x1', xScaler(scaleHigh))
      .attr('x2', xScaler(scaleHigh))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Average value line
    if (avg.value !== null) {
      svg
        .append('line')
        .attr('y1', center + 4)
        .attr('y2', center - 4)
        .attr('x1', xScaler(avg.value))
        .attr('x2', xScaler(avg.value))
        .attr('stroke-width', 2)
        .attr('stroke', 'var(--ych-grey-400)')
    }

    // UV Alpha
    svg
      .append('line')
      .attr('y1', center + 6)
      .attr('y2', center - 6)
      .attr('x1', xScaler(alpha.value))
      .attr('x2', xScaler(alpha.value))
      .attr('stroke', 'var(--ych-red)')
      .attr('stroke-width', 4)
      .style('stroke-linecap', 'round')

    // Low Value
    svg
      .append('text')
      .attr('x', 32)
      .attr('y', height / 2)
      .attr('dy', '.35em')
      .style('text-anchor', 'end')
      .text(low.value)

    // High value
    svg
      .append('text')
      .attr('x', width - 32)
      .attr('y', height / 2)
      .attr('dy', '.35em')
      .text(high.value)
  })
})
</script>
