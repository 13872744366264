<template>
  <div class="ibu-calc flex flex-col w-full justify-start items-center px-4 gap-5 py-5">
    <Title
      :show-reset="inputIsDirty"
      :show-save="inputCompleted && additionsCompleted"
      @reset="showResetModal = true"
      @save="save"
    />

    <FormulaAndMeasurements />
    <TimeVolumeAndGravity />
    <div class="flex flex-col card background-light-grey limit-width-md w-full gap-2">
      <div class="flex w-full justify-between items-center">
        <h3>Kettle Additions</h3>
        <div class="flex flex-row gap-2 items-center">
          <small>Add/Remove Kettle Additions</small>
          <y-button
            icon="minus"
            size="sm"
            width="2rem"
            title="Remove"
            :mobile-fill="false"
            @click="destroyAddition"
          />
          <y-button
            icon="plus"
            size="sm"
            width="2rem"
            title="Add"
            :mobile-fill="false"
            @click="createAddition"
          />
        </div>
      </div>

      <KettleAddition
        v-model="additions[index]"
        v-for="(addition, index) in additions"
        :key="addition.id"
        :class="{ 'kettle-addition': index !== 0 }"
      />
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full limit-width-md">
      <div class="flex flex-col gap-2 justify-start items-center w-full">
        <h3>Status</h3>
        <div class="result-box background-blue w-full px-6 py-3">
          <div class="text-white" v-if="inputCompleted">
            <y-icon icon="square-check" />
            Recipe Configuration Completed
          </div>
          <div class="text-white" v-else>
            <y-icon icon="square" />
            Recipe Configuration Not Completed
          </div>

          <div class="text-white" v-if="additions.length > 0 && !additionsCompleted">
            <y-icon icon="square" />
            {{ additionText }} Not Completed
          </div>
          <div class="text-white" v-else-if="additions.length > 0">
            <y-icon icon="square-check" />
            {{ additionText }} Completed
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 justify-start items-center w-full" v-if="inputIsDirty">
        <h3>Total IBUs</h3>
        <div class="result-box background-red w-full px-6 py-3" v-if="ibus">
          <div class="text-white">&approx; {{ ibus }}<sup>*</sup></div>
        </div>
        <span v-if="!inputCompleted"> All inputs are required to estimate IBUs </span>
        <span v-else-if="!additionsCompleted"> All additions are required to estimate IBUs </span>
        <small v-if="ibus"
          ><sup>*</sup> This is an estimated IBU value, your results may differ based on your
          brewing system</small
        >
      </div>
    </div>
  </div>
  <a ref="downloadLink" id="dl-placeholder" class="d-none" />
  <reset-calculator-modal
    :open="showResetModal"
    calculator-name="IBU Calculator"
    @confirmed="resetInputs"
    @update:open="(openState) => (showResetModal = openState)"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { YButton, YIcon } from '@yakimachief/ych-ts-component-library'
import KettleAddition from '@/components/IbuCalculator/KettleAddition.vue'
import Title from '@/components/IbuCalculator/Title.vue'
import FormulaAndMeasurements from '@/components/IbuCalculator/FormulaAndMeasurements.vue'
import TimeVolumeAndGravity from '@/components/IbuCalculator/TimeVolumeAndGravity.vue'
import {
  additions,
  boilTime,
  preBoilVolume,
  postBoilVolume,
  preBoilGravity,
  postBoilGravity,
  calculateMyGravity
} from './composables/refs'
import {
  calculatedPreBoilGravity,
  unitGravity,
  unitMass,
  unitTemp,
  unitVolume
} from '@/components/IbuCalculator/composables/computed'
import {
  createAddition,
  destroyAddition,
  precisionRound,
  stripStrangeCharacters
} from '@/components/IbuCalculator/composables/utils'
import useVarietyStore from '@/stores/VarietyStore'
import ResetCalculatorModal from '@/components/ResetCalculatorModal.vue'
import { formula, time } from '@/components/IbuCalculator/composables/consts'

const varietyStore = useVarietyStore()
varietyStore.fetchVarieties()

const showReset = ref(false)
const showResetModal = ref(false)
const showSave = ref(false)
const downloadLink = ref<HTMLAnchorElement | null>(null)

if (additions.value.length === 0) {
  createAddition()
}

const inputCompleted = computed(() => {
  return (
    boilTime.value &&
    preBoilVolume.value &&
    postBoilVolume.value &&
    postBoilVolume.value < preBoilVolume.value &&
    (preBoilGravity.value || (calculateMyGravity.value && calculatedPreBoilGravity.value)) &&
    postBoilGravity.value
  )
})

const inputIsDirty = computed(() => {
  return (
    boilTime.value !== undefined ||
    preBoilVolume.value !== undefined ||
    postBoilVolume.value !== undefined ||
    preBoilGravity.value !== undefined ||
    postBoilGravity.value !== undefined ||
    calculateMyGravity.value !== false ||
    additions.value.length !== 1 ||
    additions.value[0].product.name !== undefined ||
    additions.value[0].product.utilizationPercent !== undefined ||
    additions.value[0].additionTemp !== undefined ||
    additions.value[0].time !== undefined ||
    additions.value[0].alpha !== undefined ||
    additions.value[0].location !== undefined ||
    additions.value[0].timeUntil175 !== undefined ||
    additions.value[0].amount !== undefined ||
    additions.value[0].variety !== undefined ||
    additions.value[0].gma !== undefined ||
    additions.value[0].ibus !== undefined
  )
})

const resetInputs = () => {
  showResetModal.value = false
  boilTime.value = undefined
  preBoilVolume.value = undefined
  postBoilVolume.value = undefined
  preBoilGravity.value = undefined
  postBoilGravity.value = undefined
  calculateMyGravity.value = false
  additions.value = []
  createAddition()
}

const additionText = computed(() => {
  if (additions.value.length > 1) {
    return 'Additions'
  }
  return 'Addition'
})

const additionsCompleted = computed(() => {
  if (additions.value.length === 0) {
    return false
  }
  let completed = true
  additions.value.forEach((addition) => {
    if (addition.ibus === undefined || isNaN(addition.ibus)) {
      completed = false
    }
  })
  return completed
})

const ibus = computed(() => {
  if (inputCompleted.value && additionsCompleted.value) {
    let ibus = 0
    return precisionRound(
      additions.value.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.ibus ?? 0)
      }, ibus),
      1
    )
  }
})

const save = () => {
  let csv =
    'Recipe:\r\n\r\nFormula,Boil Time,Pre-boil Volume,Post-boil Volume,Pre-boil Gravity,Post-boil Gravity,IBUs\r\n'
  csv += `${formula},${boilTime.value} ${time},${preBoilVolume.value} ${unitVolume.value},${postBoilVolume.value} ${unitVolume.value},${preBoilGravity.value} ${unitGravity.value},${postBoilGravity.value} ${unitGravity.value},${ibus.value}\r\n\r\n`
  csv +=
    'Additions:\r\n\r\nAddition,Product,Utilization Percentage,Variety,Location,Boil Time,Time Until 175,Amount,Alpha,Addition Temp,IBU\r\n'
  for (let addition of additions.value) {
    csv += `${addition.id + 1},${addition.product.name},${addition.product.utilizationPercent} %,${stripStrangeCharacters(addition.variety ?? '')},${addition.location},${addition.time ? addition.time + ' ' + time : ''},${addition.timeUntil175 ? addition.timeUntil175 + ' ' + time : ''},${addition.amount} ${unitMass.value},${addition.alpha} %,${addition.additionTemp ? addition.additionTemp + ' ' + stripStrangeCharacters(unitTemp.value ?? '') : ''},${addition.ibus}\r\n`
  }
  if (downloadLink.value !== null) {
    downloadLink.value.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
    )
    downloadLink.value.setAttribute('download', 'Recipe_IBUs.csv')
    downloadLink.value.click()
  }
}
</script>

<style scoped lang="scss">
.ibu-calc {
  .product-logo {
    max-width: 4rem;
  }

  .y-dropdown__label {
    font-weight: var(--y-font-weight-medium);
  }

  .kettle-addition {
    padding-top: 1rem;
    border-top: 1px solid var(--ych-grey-200);
    margin-top: 1rem;
  }

  .background-light-grey {
    background-color: var(--ych-grey-100);
  }

  .background-red {
    background: var(--ych-red);
  }

  .background-blue {
    background: var(--ych-blue);
  }

  .result-box {
    margin: 0;
    height: 6rem;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    div {
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      font-weight: 900;
    }
  }
}
</style>
<style lang="scss">
.ibu-calc {
  &__label {
    line-height: 2rem;
    max-height: 2rem;
    display: inline-flex;
    font-weight: var(--y-font-weight-medium);
  }
}
</style>
