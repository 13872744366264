<template>
  <div v-if="showKey('totalOilChart')" :class="cssBaseClassName" class="lot-result-card">
    <div :class="cssBaseClassName + '__title-row'" class="flex justify-between p-4 items-center">
      <div :class="cssBaseClassName + '__title-row__left-content'" class="flex flex-col gap-1">
        <h4>Total Oil</h4>
        <small>vs Typical Variety Range</small>
      </div>
      <div
        :class="cssBaseClassName + '__title-row__right-content'"
        class="flex flex-col items-center"
      >
        <span class="h1" v-if="totalOil">{{ totalOil }}</span>
        <small>mL/100g</small>
      </div>
    </div>
    <div :class="cssBaseClassName + '__chart'" class="p-2 flex flex-col gap-2" v-if="hasTotalOil">
      <TotalOilChartSingle v-if="lotStore.lots.length === 1" />
      <TotalOilChart
        v-else
        :grouped-lots="groupedLot"
        v-for="groupedLot in groupedLots"
        :max-oil="maxOil"
      />
    </div>
    <div class="flex flex-col p-4 gap-2" v-else>
      <p>Oil Component data for this lot is unavailable at this time.</p>
      <p>
        Please email <a href="mailto:quality@yakimachief.com">quality@yakimachief.com</a> for more
        information.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import { showKey } from '@/composables/filterSettings'
import TotalOilChartSingle from '@/components/Charts/TotalOilChartSingle.vue'
import TotalOilChart from '@/components/Charts/TotalOilChart.vue'
import groupLots from '@/composables/groupLots'

const lotStore = useLotStore()

const totalOil = computed(() => {
  if (lotStore.lots.length === 1) {
    return lotStore.lots[0].oilComponents?.totalOil
  }
})

const maxOil = computed(() => {
  let max = 8
  lotStore.lots.forEach((lot) => {
    if (lot.oilComponents?.totalOil !== undefined && lot.oilComponents?.totalOil > max) {
      max = Math.ceil(lot.oilComponents.totalOil)
    }
  })
  if (max === 8) {
    return 8
  }
  return Math.ceil(max + max * 0.05)
})

const cssBaseClassName = computed(() => {
  if (lotStore.lots.length === 1) {
    return 'lot-lookup-single__results__col__total-oil'
  }
  return 'lot-lookup-multi__results__col__total-oil'
})

const groupedLots = computed(() => {
  return groupLots(lotStore.lots, 'variety', 'productName')
})

const hasTotalOil = computed(() => {
  let hasTotalOil = false
  lotStore.lots.forEach((lot) => {
    if (lot.oilComponents?.totalOil) {
      hasTotalOil = true
    }
  })
  return hasTotalOil
})
</script>
