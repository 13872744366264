import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'

export const hasChartSurvivableCompounds = computed(() => {
  const lotStore = useLotStore()
  let hasSurvivables = false
  lotStore.lots.forEach((lot) => {
    if (lotHasChartSurvivables(lot)) {
      hasSurvivables = true
    }
  })
  return hasSurvivables
})

export function lotHasChartSurvivables(lot: Lot) {
  if (lot.survivables) {
    if (
      lot.survivables.isobutylIsobutyrate ||
      lot.survivables.isoamylIsobutyrate ||
      lot.survivables.twoMethylbutylIsobutyrate ||
      lot.survivables.linalool ||
      lot.survivables.geraniol ||
      lot.survivables.twoNonanone ||
      lot.survivables.methylGeranate ||
      lot.survivables.threeMercaptohexanol
    ) {
      return true
    }
  }
  return false
}

export default hasChartSurvivableCompounds
