<template>
  <div class="search-box-component flex gap-4 w-full items-center justify-center">
    <div
      :class="lotSearchClasses"
      :style="{ position: 'relative', 'max-width': width }"
      :aria-expanded="isOpen"
    >
      <div class="lot-search__top-content" v-on-click-outside="() => (isOpen = false)">
        <YIcon class="lot-search__top-content__search-icon" icon="magnifying-glass" />
        <input
          ref="queryInput"
          class="lot-search__top-content__input h3"
          v-model="query"
          :placeholder="placeholder"
          @click="isOpen = true"
          @keyup.enter="runSearch"
        />
        <button
          :class="[
            'lot-search__top-content__submit-icon',
            query ? 'lot-search__top-content__submit-icon--active' : ''
          ]"
          @click="runSearch"
        >
          <y-icon class="" icon="arrow-right" />
        </button>
      </div>
      <transition name="slide-fade" mode="in-out">
        <div class="lot-search__options" v-if="isOpen">
          <div class="lot-search__options__label">Recent Searches</div>
          <div
            v-if="filteredOption.length === 0 && query !== ''"
            class="lot-search__options__not-found"
          >
            No matching entries in search history
          </div>
          <ul>
            <li
              v-for="(lotNumber, index) in filteredOption"
              :key="index"
              :value="lotNumber"
              class="lot-search__options__option h3"
              @click="addToQuery(lotNumber)"
              :class="[isSelected(lotNumber) ? 'lot-search__options__option--selected' : '']"
            >
              <YIcon
                class="lot-search__options__option__search-icon"
                :icon="isSelected(lotNumber) ? 'check' : 'magnifying-glass'"
              />
              <span class="lot-search__options__option__text">{{ lotNumber }}</span>
              <YIcon class="lot-search__options__option__append-icon" icon="arrow-up-left" />
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <y-button
      background-color="secondary"
      icon="qrcode"
      :mobile-fill="false"
      width="2.75rem"
      @click="openQrScanner = true"
    />
    <qr-scanner v-model:open="openQrScanner" v-model="query" @updateFormat="setFormat" />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

import { YButton, YIcon } from '@yakimachief/ych-ts-component-library'
import type { Property } from 'csstype'
import { useRoute, useRouter } from 'vue-router'
import useLotStore from '@/stores/LotStore'
import QrScanner from '@/components/QrScanner.vue'
import lotSearchQueryString from '@/composables/lotSearchQueryString'
import { buildQueryParamObject, useShowBinNumbers } from '@/composables/lotBinNumber'

type LotSearchProperties = {
  freshSearch: boolean
  addLots?: boolean
  placeholder?: string
  disabled?: boolean
  width?: Property.MaxWidth
}

const props = withDefaults(defineProps<LotSearchProperties>(), {
  freshSearch: false,
  addLots: false,
  placeholder: 'Search...',
  disabled: false,
  width: '100%'
})

const router = useRouter()
const route = useRoute()
const lotStore = useLotStore()
const entryFormat = ref(100) //Default to Hand Entered
const { showBinNumbers } = useShowBinNumbers()

const getCurrentUrlLots = () => {
  const lotsQuery = route.query['lots[]']
  setFormat(2) // Direct Link

  if (Array.isArray(lotsQuery)) {
    return lotsQuery.join(',')
  } else if (lotsQuery) {
    return lotsQuery
  }
  return ''
}

const getQueryDefaultValue = () => {
  if (props.addLots || props.freshSearch) {
    return ''
  }
  if (lotSearchQueryString.value !== '') {
    return lotSearchQueryString.value
  }
  return getCurrentUrlLots()
}

const query = ref(getQueryDefaultValue())
const queryInput = ref(null)

const isOpen = ref(false)
const runningSearch = ref(false)

const filteredOption = computed(() =>
  query.value.trim() === ''
    ? lotStore.lotNumberHistory
    : lotStore.lotNumberHistory.filter((option) => {
        const lastIndex = query.value.lastIndexOf(',')
        const lotNumberQuery = query.value.substring(lastIndex + 1)
        return option.toUpperCase().includes(lotNumberQuery.toUpperCase())
      })
)

function setFormat(format: number) {
  entryFormat.value = format
}

function addToQuery(lotNumber: string) {
  if (!isSelected(lotNumber)) {
    const lastIndex = query.value.lastIndexOf(',')
    const existingQuery = query.value.substring(0, lastIndex)
    if (lastIndex === -1) {
      query.value = lotNumber + ','
    } else {
      query.value = existingQuery + ', ' + lotNumber + ','
    }
    setFormat(100) // Hand Entered
  }

  if (queryInput.value !== null) {
    queryInput.value.focus()
    isOpen.value = true
  }
}

const lotSearchClasses = computed(() => ({
  'lot-search': true,
  'lot-search--disabled': isDisabled.value
}))

const searchLotNumbers = computed(() => {
  const currentSearchStringLots = query.value
    .split(',')
    .map((v: string) => v.trim())
    .filter((lotNumber) => lotNumber)
  if (props.addLots) {
    let queryStringLots = route.query['lots[]'] ?? []
    if (Array.isArray(queryStringLots)) {
      return queryStringLots.concat(currentSearchStringLots) as string[]
    }
    return [queryStringLots].concat(currentSearchStringLots) as string[]
  }
  return currentSearchStringLots
})

const isSelected = (lotNumber: string) => {
  return searchLotNumbers.value.includes(lotNumber)
}

const runSearch = async () => {
  if (query.value !== '' && runningSearch.value === false) {
    lotSearchQueryString.value = query.value
    isOpen.value = false
    runningSearch.value = true
    await lotStore.fetchLots(searchLotNumbers.value, entryFormat.value)
    router.replace({
      path: 'lookup',
      query: buildQueryParamObject(showBinNumbers.value, lotStore.lotNumbers)
    })
    runningSearch.value = false
  }
}

const isDisabled = computed(() => {
  return props.disabled || runningSearch.value || lotStore.loading
})

//If lot is removed from store update the search string
if (
  !lotStore.loading &&
  !props.addLots &&
  !props.freshSearch &&
  lotStore.lookupExceptions.length === 0 &&
  lotStore.lotNumbers.length > 0
) {
  query.value = lotStore.lotNumbers.join(', ')
}
const openQrScanner = ref(false)

lotStore.$subscribe(() => {
  if (!lotStore.loading) {
    query.value = lotStore.lotNumbers.join(', ')
  }
})
</script>

<style scoped lang="scss">
.lot-search {
  border-radius: var(--y-radius-md);
  font-size: var(--y-font-size-md);
  font-family: var(--y-font-family-sans-serif);
  font-weight: var(--y-font-weight-medium);
  line-height: 1.5rem;
  letter-spacing: 0.005rem;
  background: var(--ych-white);
  min-width: 11rem;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &:focus-within {
    outline: 0.25rem auto Highlight;
    outline: 0.25rem auto -webkit-focus-ring-color;
  }

  &__top-content {
    width: 100%;
    border: 1px solid var(--ych-grey-400);
    border-radius: var(--y-radius-md);
    display: inline-flex;
    align-items: center;

    &__input {
      width: 100%;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 2.75rem;
      padding: 0.5rem 2.75rem 0.5rem 2.75rem;
      color: var(--ych-blue);
      border-radius: var(--y-radius-md);
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    &__search-icon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.75rem;
      font-size: 1.25rem;
      background: transparent;
      border: none;
      color: var(--ych-grey-400);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    &__submit-icon {
      position: absolute;
      top: 1px;
      right: 0;
      width: 2.75rem;
      height: 2.75rem;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      color: var(--ych-black-30);
      border-radius: 0 var(--y-radius-md) var(--y-radius-md) 0;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &--active {
        background: var(--ych-red);
        color: var(--ych-white);
        border-radius: 0 var(--y-radius-md) var(--y-radius-md) 0;
        pointer-events: all;
      }
    }
  }

  &[aria-expanded='true'] {
    .lot-search__top-content {
      border-radius: var(--y-radius-md) var(--y-radius-md) 0 0;
      border-bottom: none;
    }

    .lot-search__top-content__submit-icon {
      border-radius: 0 var(--y-radius-md) 0 0;
      border-bottom: none;
    }

    .lot-search__top-content__icon {
      transform: rotate(-180deg);
      transition: transform 0.2s ease;
    }

    &:focus-within {
      outline: none;
    }
  }

  &__options {
    background: var(--ych-white);
    color: var(--ych-blue);
    max-height: 15rem; /* 240px */
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    z-index: 10;
    top: 100%;
    border-radius: 0 0 var(--y-radius-md) var(--y-radius-md);
    border-right: 1px solid var(--ych-grey-400);
    border-bottom: 1px solid var(--ych-grey-400);
    border-left: 1px solid var(--ych-grey-400);

    &__option {
      width: 100%;
      height: 2.75rem;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.25rem;
      padding: 0.5rem 2.75rem 0.5rem 2.75rem;
      position: relative;

      &:hover {
        background: var(--ych-grey-100);
      }

      &__search-icon {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.75rem;
        font-size: 1.25rem;
        background: transparent;
        border: none;
        color: var(--ych-grey-400);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      }

      &__append-icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem;
        font-size: 1.25rem;
        background: transparent;
        border: none;
        color: var(--ych-grey-400);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      }

      &__text {
        width: 100%;
        height: 2.75rem;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        padding: 0;
        align-items: center;
      }

      &--selected {
        .lot-search__options__option__text {
          font-weight: var(--y-font-weight-bold);
        }
      }
    }

    &__label {
      border-top: 1px solid var(--ych-grey-200);
      width: calc(100% - 1rem);
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      font-size: var(--y-font-size-sm);
      line-height: var(--y-font-size-sm);
      font-weight: var(--y-font-weight-medium);
      color: var(--ych-grey-400);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      pointer-events: none;
    }

    &__not-found {
      width: 100%;
      height: 2.75rem;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.25rem;
      padding: 0 0.75rem;
      color: var(--ych-grey-400);
    }
  }

  &--disabled {
    background: var(--ych-grey-100);
    color: var(--ych-black-30);
    pointer-events: none;

    .lot-search__top-content {
      border: 1px solid var(--ych-grey-200);

      &__input,
      &__input::placeholder,
      &__search-icon,
      &__submit-icon {
        color: var(--ych-black-30);
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 250ms ease-in;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-1rem);
  opacity: 0;
}
</style>
