import { computed } from 'vue'
import {
  barrels,
  centigrade,
  degreesPlato,
  fahrenheit,
  gallons,
  grams,
  hectoliters,
  kilograms,
  liters,
  ounces,
  pounds,
  specificGravity
} from '@/components/IbuCalculator/composables/consts'
import {
  gravityUnits,
  measurementScale,
  measurementSystem,
  postBoilGravity,
  postBoilVolume,
  preBoilVolume
} from '@/components/IbuCalculator/composables/refs'
import { precisionRound } from '@/components/IbuCalculator/composables/utils'

export const unitVolume = computed(() => {
  switch (measurementSystem.value) {
    case 'Imperial':
      switch (measurementScale.value) {
        case 'Home Brew':
          return gallons
        case 'Commercial':
          return barrels
      }
      break
    case 'Metric':
      switch (measurementScale.value) {
        case 'Home Brew':
          return liters
        case 'Commercial':
          return hectoliters
      }
  }
})

export const unitGravity = computed(() => {
  switch (gravityUnits.value) {
    case 'Specific Gravity':
      return specificGravity
    case 'Plato':
      return degreesPlato
  }
})

export const unitMass = computed(() => {
  switch (measurementSystem.value) {
    case 'Imperial':
      switch (measurementScale.value) {
        case 'Home Brew':
          return ounces
        case 'Commercial':
          return pounds
      }
      break
    case 'Metric':
      switch (measurementScale.value) {
        case 'Home Brew':
          return grams
        case 'Commercial':
          return kilograms
      }
      break
  }
})

export const unitTemp = computed(() => {
  switch (measurementSystem.value) {
    case 'Imperial':
      return fahrenheit
    case 'Metric':
      return centigrade
  }
})

export const calculatedPreBoilGravity = computed(() => {
  let result = precisionRound(
    (postBoilGravity.value - 1) * 1000 * (postBoilVolume.value / preBoilVolume.value / 1000) + 1,
    3
  )
  return isNaN(result) ? '*' : result
})
