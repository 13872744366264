<template>
  <div
    v-if="showKey('aromas') && hasSensory"
    :class="classBase + '__col__top-five lot-result-card'"
  >
    <div
      :class="classBase + '__col__top-five__title-row flex justify-start px-4 py-2 items-center'"
    >
      <h4>Top 5 Aromas</h4>
    </div>
    <div
      class="flex py-3 justify-between text-center"
      :class="[classBase + '__col__top-five__icons']"
      v-for="(lot, lotIndex) in lotsWithAromas"
      key="index"
    >
      <div
        :class="'flex flex-col gap-2 items-center w-full px-1'"
        v-for="(aroma, index) in lot.aromas.slice(0, 5)"
        :key="index"
        v-tooltip="{
          content: `<span class='font-medium'>${aroma.name}: </span><span>${aroma.intensity}%</span>`,
          html: true
        }"
      >
        <y-icon
          :class="classBase + '__col__top-five__icons__icon'"
          :icon="aroma.slug"
          :color="lotIndex < 3 ? lotColors[lotIndex] : '#D22630'"
        />
        <small
          class="uppercase font-semibold"
          :class="classBase + '__col__top-five__icons__icon__subtitle'"
          v-if="isSingleLot"
          >{{ aroma.name }}</small
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { YIcon } from '@yakimachief/ych-ts-component-library'
import useLotStore from '@/stores/LotStore'
import { showKey } from '@/composables/filterSettings'

const lotStore = useLotStore()
import { lotColors } from '@/components/LotLookup'

const isSingleLot = computed(() => {
  return lotStore.lots.length === 1
})

const classBase = computed(() => {
  if (isSingleLot.value) {
    return 'lot-lookup-single__results'
  }
  return 'lot-lookup-multi__results'
})

const hasSensory = computed(() => {
  let hasSensory = false
  lotStore.lots.forEach((lot) => {
    if (lot.aromas && lot.aromas.length > 0) {
      hasSensory = true
    }
  })
  return hasSensory
})

const lotsWithAromas = computed(() => {
  return lotStore.lots.filter((lot) => lot.aromas && lot.aromas.length > 0)
})
</script>
