<template>
  <div class="lot-table flex items-start">
    <div
      class="lot-table__labels print:hidden"
      :class="{ 'lot-table__labels__sample-height-adjusted': showBinNumbers }"
      v-if="!isChart"
    >
      <ul class="lot-table__labels__list grey-border-bottom" v-if="showFirstTableGroup">
        <lot-lookup-table-row-header-list-item
          id="best-before"
          show-key-name="bestBefore"
          name="Best Use Before"
        />
        <lot-lookup-table-row-header-list-item id="farms" show-key-name="farms" name="Farm(s)" />
      </ul>
      <ul class="lot-table__labels__list grey-border-bottom" v-if="showTableBrewingValues">
        <li
          class="lot-table__labels__list__overlay h2"
          v-if="brewingValuesTableItemCount === 9"
          aria-hidden="true"
        >
          Brewing Values
        </li>
        <lot-lookup-table-row-header-list-item
          id="uv-alpha"
          tooltip="alphaAcids"
          show-key-name="uvAlpha"
          name="UV Alpha"
          category="Brewing Value"
        />

        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveUvIsoAlpha"
          id="uv-iso-alpha"
          tooltip="alphaAcids"
          show-key-name="uvIsoAlpha"
          name="UV Iso-Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="uv-beta"
          tooltip="betaAcids"
          show-key-name="uvBeta"
          name="UV Beta"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="hsi"
          tooltip="hsi"
          show-key-name="hsi"
          name="HSI"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="hplc-alpha"
          tooltip="alphaAcids"
          show-key-name="hplcAlpha"
          name="HPLC Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveLcvAlpha74"
          id="lcv-alpha"
          tooltip="alphaAcids"
          show-key-name="lcvAlpha"
          name="LCV Alpha 7.4"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveHplcAreaPurity"
          id="hlpc-area-purity"
          tooltip="alphaAcids"
          show-key-name="hplcAreaPurity"
          name="HPLC Area Purity"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveHplcRho"
          id="hlpc-rho-alpha"
          tooltip="alphaAcids"
          show-key-name="hplcRho"
          name="HPLC Rho Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveHplcIsoAlpha"
          id="iso-alpha"
          tooltip="alphaAcids"
          show-key-name="hlpcIsoAlpha"
          name="HPLC Iso-Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveHplcHexaAlpha"
          id="hexa-alpha"
          tooltip="alphaAcids"
          show-key-name="hexAlpha"
          name="HPLC Hexa Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          v-if="lotsHaveHplcTetraAlpha"
          id="tet-alpha"
          tooltip="alphaAcids"
          show-key-name="tetAlpha"
          name="HPLC Tetra Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="hplc-beta"
          tooltip="betaAcids"
          show-key-name="hplcBeta"
          name="HPLC Beta"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="cohumulone"
          show-key-name="hplcCohumulone"
          name="HPLC Cohumulone"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="colupulone"
          show-key-name="hplcColupulone"
          name="HPLC Colupulone"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="mebak-alpha"
          tooltip="alphaAcids"
          show-key-name="mebakAlpha"
          name="Mebak Alpha"
          category="Brewing Value"
        />
        <lot-lookup-table-row-header-list-item
          id="moisture"
          tooltip="moisture"
          show-key-name="moisture"
          name="Moisture"
          category="Brewing Value"
        />
      </ul>

      <ul class="lot-table__labels__list grey-border-bottom">
        <li
          class="lot-table__labels__list__overlay h2"
          v-if="oilTableItemCount === 9"
          aria-hidden="true"
        >
          Oil Components
        </li>
        <lot-lookup-table-row-header-list-item
          id="total-oil"
          tooltip="totalOil"
          show-key-name="totalOil"
          name="Total Oil"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="b-pinene"
          tooltip="bPinene"
          show-key-name="betaPinene"
          name="β-Pinene"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="myrcene"
          tooltip="myrcene"
          show-key-name="myrcene"
          name="Myrcene"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="linalool-oil"
          tooltip="linalool"
          show-key-name="linaloolOil"
          name="Linalool"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="caryophyllene"
          tooltip="caryophyllene"
          show-key-name="caryophyllene"
          name="Caryophyllene"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="farnesene"
          tooltip="farnesene"
          show-key-name="farnesene"
          name="Farnesene"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="humulene"
          tooltip="humulene"
          show-key-name="humulene"
          name="Humulene"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="geraniol-oil"
          tooltip="geraniol"
          show-key-name="geraniolOil"
          name="Geraniol"
          category="Oil Component"
          :odd="false"
        />
        <lot-lookup-table-row-header-list-item
          id="other-oil"
          tooltip="other"
          show-key-name="other"
          name="Other Oil"
          category="Oil Component"
          :odd="false"
        />
      </ul>

      <ul class="lot-table__labels__list grey-border-bottom" v-if="showTableSurvivables">
        <li
          class="lot-table__labels__list__overlay h2"
          v-if="survivablesTableItemCount === 8"
          aria-hidden="true"
        >
          Survivables
        </li>
        <lot-lookup-table-row-header-list-item
          id="isobutyl"
          tooltip="isobutyl"
          show-key-name="isobutylIsobutyrate"
          name="Isobutyl Isobutyrate"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="isoamyl"
          tooltip="isoamyl"
          show-key-name="isoamylIsobutyrate"
          name="Isoamyl Isobutyrate"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="two-methyl"
          tooltip="twoMethyl"
          show-key-name="twoMethylbutylIsobutyrate"
          name="2-Methylbutyl Isobutyrate"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="linalool-survivable"
          tooltip="linalool"
          show-key-name="linaloolSurvivable"
          name="Linalool"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="geraniol-survivable"
          tooltip="geraniol"
          show-key-name="geraniolSurvivable"
          name="Geraniol"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="two-nonanone"
          tooltip="twoNonanone"
          show-key-name="twoNonanone"
          name="2-Nonanone"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="methyl-geranate"
          tooltip="methylGeranate"
          show-key-name="methylGeranate"
          name="Methyl Geranate"
          category="Survivable"
        />
        <lot-lookup-table-row-header-list-item
          id="three-mercapto"
          tooltip="threeMercapto"
          show-key-name="threeMercaptohexanol"
          name="3-Mercaptohexanol"
          category="Survivable"
        />
      </ul>

      <ul class="lot-table__labels__list" v-if="showKey('aromas')">
        <lot-lookup-table-row-header-list-item
          id="aroma-top-five"
          show-key-name="aromas"
          name="Aroma Profile"
          category="Top Five"
        />
      </ul>
    </div>
    <div class="lot-table__wrapper flex w-full max-w-full relative overflow-hidden">
      <div
        class="lot-table__wrapper__container flex p-2 items-center gap-2 w-full overflow-x-auto justify-start"
      >
        <lot-lookup-table-item
          v-for="(lot, index) in lotStore.lots"
          :lot="lot"
          :lot-number="lot.lotNumber"
          :is-chart="isChart"
          :lot-color="index < 3 ? lotColors[index] : '#ffffff'"
          :count="index"
        />
        <div class="lot-table__wrapper__container__button-wrapper">
          <div
            class="lot-table__wrapper__container__button-wrapper__add-button"
            @click="openLotSearchModal = true"
            role="button"
          >
            <h4 class="lot-table__wrapper__container__button-wrapper__add-button__text">
              Add Lots
            </h4>
            <y-icon
              class="lot-table__wrapper__container__button-wrapper__add-button__icon"
              icon="plus"
            />
          </div>
        </div>
        <div class="shadow__left" aria-hidden="true"></div>
        <div class="shadow__right" aria-hidden="true"></div>
      </div>
    </div>
    <lot-search-modal v-model:open="openLotSearchModal" />
  </div>
</template>
<script lang="ts" setup>
import LotLookupTableItem from '@/components/LotLookup/LotLookupTableItem.vue'
import { YIcon } from '@yakimachief/ych-ts-component-library'
import useLotStore from '@/stores/LotStore'
import LotSearchModal from '@/components/LotSearchModal.vue'
import { ref } from 'vue'
import {
  showKey,
  showFirstTableGroup,
  showTableBrewingValues,
  showTableSurvivables,
  survivablesTableItemCount,
  oilTableItemCount,
  brewingValuesTableItemCount
} from '@/composables/filterSettings'
import LotLookupTableRowHeaderListItem from '@/components/LotLookup/LotLookupTableRowHeaderListItem.vue'
import {
  lotsHaveHplcAreaPurity,
  lotsHaveHplcHexaAlpha,
  lotsHaveHplcIsoAlpha,
  lotsHaveHplcRho,
  lotsHaveHplcTetraAlpha,
  lotsHaveLcvAlpha74,
  lotsHaveUvIsoAlpha
} from '@/composables/lotAlpha'
import { useShowBinNumbers } from '@/composables/lotBinNumber'

const lotStore = useLotStore()
const { showBinNumbers } = useShowBinNumbers()

type LotTableProperties = {
  isChart?: boolean
  lotNumbers?: string[]
}
const props = withDefaults(defineProps<LotTableProperties>(), {
  isChart: true
})

const lotColors = ['#D22630', '#002B49', '#D2AB89']

const openLotSearchModal = ref(false)
</script>
<style lang="scss" scoped>
.grey-border-bottom {
  border-bottom: 1px solid var(--ych-grey-200);
}

.lot-table {
  &__labels {
    width: 13rem;
    min-width: 13rem;
    margin-top: 7rem;
    box-shadow: var(--y-shadow-grey-sm);
    overflow: hidden;
    border-radius: 0.25rem 0 0 0.25rem;

    &__sample-height-adjusted {
      margin-top: 8.25rem;
    }

    &__list {
      background-color: var(--ych-white);
      position: relative;

      &__label {
        width: 100%;
        text-align: right;
        padding: 0.25rem 0.5rem 0.25rem 2rem;
        height: 1.5rem;
        font-size: var(--y-font-size-sm);
        line-height: 1rem;
        font-weight: var(--y-font-weight-medium);
        white-space: nowrap;
        cursor: default;

        &:nth-child(odd of .lot-table__labels__list__label.odd) {
          background-color: var(--ych-grey-100);
        }

        &:nth-child(even of .lot-table__labels__list__label.even) {
          background-color: var(--ych-grey-100);
        }
      }

      &__overlay.h2 {
        position: absolute;
        left: -49%;
        top: 0;
        transform: rotate(-90deg) translateY(50%);
        z-index: 2;
        pointer-events: none;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.12);
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: calc(100% + 0.75vh);
        white-space: nowrap;
      }
    }
  }

  &__wrapper {
    height: auto;
    border-radius: var(--y-radius-md);

    &__container {
      border-radius: var(--y-radius-md);
      background-color: var(--ych-grey-100);
      box-shadow:
        0px 1.5px 4px 1px rgba(40, 41, 40, 0.1) inset,
        0px 1px 3px 0px rgba(40, 41, 40, 0.2) inset;

      &__button-wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__add-button {
          width: 6rem;
          height: 6rem;
          background-color: var(--ych-white);
          border-radius: var(--y-radius-md);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;
          padding: 1rem;
          box-shadow: var(--y-shadow-grey-lg);

          &__text {
            white-space: nowrap;
          }

          &__icon {
            color: var(--ych-grey-400);
            font-size: 2.5rem;
          }

          &:hover {
            background-color: var(--ych-blue);
            cursor: pointer;

            .lot-table__wrapper__container__button-wrapper__add-button__text,
            .lot-table__wrapper__container__button-wrapper__add-button__icon {
              color: var(--ych-white);
            }
          }
        }
      }
    }
  }
}

.shadow {
  &__left {
    width: 0.5rem;
    height: 100%;
    pointer-events: none;
    z-index: 10;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(to right, rgba(22, 22, 22, 0.15), transparent);
    background: linear-gradient(to right, rgba(22, 22, 22, 0.15), transparent);
  }

  &__right {
    width: 0.5rem;
    height: 100%;
    pointer-events: none;
    z-index: 10;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    background: -webkit-linear-gradient(to left, rgba(22, 22, 22, 0.15), transparent);
    background: linear-gradient(to left, rgba(22, 22, 22, 0.15), transparent);
  }
}

@media (max-width: 768px) {
  .lot-table__labels {
    width: 10rem;
    min-width: 10rem;

    &__list {
      &__label {
        padding-left: 0.5rem;
      }

      &__overlay.h2 {
        display: none;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .lot-table__labels {
    width: 12rem;
    min-width: 12rem;
  }
}

@media print {
  .lot-table__wrapper__container {
    flex-direction: column;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    overflow-y: visible;
  }
  .table-item {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .lot-table__wrapper__container__button-wrapper,
  .lot-table__wrapper__container__button-wrapper__add-button {
    display: none;
  }
}
</style>
