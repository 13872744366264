<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-400"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-400"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 modal-background" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="transition-all flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-800"
            enter-from="opacity-0 translate-y-10 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-800"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-16 sm:scale-90"
          >
            <DialogPanel
              class="reset-modal relative transform overflow-hidden card text-left transition-all sm:my-8 w-full lg:max-w-[30rem] mx-0 lg:mx-0 sm:mx-12 sm:p-6"
            >
              <div class="reset-modal flex flex-col gap-4">
                <div class="reset-modal__top-row flex justify-between items-center">
                  <DialogTitle>Reset Inputs?</DialogTitle>
                  <y-button
                    icon="xmark"
                    width="2.75rem"
                    background-color="transparent"
                    :mobile-fill="false"
                    @click="setIsOpen(false)"
                  />
                </div>

                <div class="reset-modal__content flex flex-col relative">
                  <div class="flex flex-col gap-4">
                    <p>
                      Resetting will <span class="font-medium">clear all inputs</span> in the
                      {{ calculatorName }}. <br />
                      Do you wish to proceed?
                    </p>
                    <y-button
                      background-color="primary"
                      @click="resetConfirmed"
                      icon="arrow-right"
                      label="Continue"
                      icon-position="right"
                      :mobile-fill="false"
                    />
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { YButton } from '@yakimachief/ych-ts-component-library'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

type ResetModalProperties = {
  open: boolean
  calculatorName: string
}
const props = withDefaults(defineProps<ResetModalProperties>(), {
  open: false,
  calculatorName: 'calculator'
})

const emit = defineEmits(['update:open', 'confirmed'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}

const resetConfirmed = function () {
  emit('confirmed')
  setIsOpen(false)
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: var(--ych-black-20);
}
</style>
