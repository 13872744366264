import {
  additions,
  gravityUnits,
  measurementScale,
  measurementSystem
} from '@/components/IbuCalculator/composables/refs'
import type { KettleAddition } from '@/components/IbuCalculator/types/KettleAddition'

export const precisionRound = (number: number, precision: number) => {
  let factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export const createAddition = () => {
  const emptyAddition: KettleAddition = {
    id: additions.value.length,
    product: {
      name: undefined,
      utilizationPercent: undefined
    },
    variety: undefined,
    location: undefined,
    time: undefined,
    timeUntil175: undefined,
    amount: undefined,
    alpha: undefined,
    gma: undefined,
    additionTemp: undefined,
    ibus: undefined
  }
  additions.value.push({ ...emptyAddition })
}

export const destroyAddition = () => {
  if (additions.value.length > 0) {
    additions.value.pop()
  }
}

export const convertTemperature = (inputTemperature: number) => {
  switch (measurementSystem.value) {
    case 'Imperial':
      return inputTemperature
    case 'Metric':
      return inputTemperature * (9 / 5) + 32
  }
  return 0
}

export const convertGravity = (inputGravity: number) => {
  switch (gravityUnits.value) {
    case 'Specific Gravity':
      return inputGravity // sg
    case 'Plato':
      return 1 + inputGravity / (258.6 - 227.1 * (inputGravity / 258)) // *p > sg
  }
}

export const unConvertTemperature = (inputTemperature: number) => {
  switch (measurementSystem.value) {
    case 'Imperial':
      return inputTemperature
    case 'Metric':
      return (inputTemperature - 32) * (5 / 9)
  }
  return 0
}

export const calculateIsomerizationPctQuadratic = (temperature: number) => {
  return (0.0473 * Math.pow(temperature, 2) - 15.601 * temperature + 1281.8) / 100
}

export const convertVolume = (inputVolume: number) => {
  switch (measurementSystem.value) {
    case 'Imperial':
      switch (measurementScale.value) {
        case 'Home Brew':
          return inputVolume // gallons
        case 'Commercial':
          return inputVolume * 31 // barrels > gallons
      }
      break
    case 'Metric':
      switch (measurementScale.value) {
        case 'Home Brew':
          return inputVolume * 0.264172 // liters > gallons
        case 'Commercial':
          return inputVolume * 26.4172 // hectoliters > gallons
      }
      break
  }
  return 0
}

export const stripStrangeCharacters = (input: string) => {
  let result = ''
  for (var i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      result += input.charAt(i)
    }
  }
  return result
}

export const convertMass = (inputMass: number) => {
  switch (measurementSystem.value) {
    case 'Imperial':
      switch (measurementScale.value) {
        case 'Home Brew':
          return inputMass // oz
        case 'Commercial':
          return inputMass * 16 // lb > oz
      }
      break
    case 'Metric':
      switch (measurementScale.value) {
        case 'Home Brew':
          return inputMass * 0.035274 // g > oz
        case 'Commercial':
          return inputMass * 35.274 // kg > oz
      }
  }
  return 0
}
