<template>
  <div
    class="flex justify-between"
    :class="{
      'lot-lookup-single__results__col__oils__container__row__labels__group':
        lotStore.lots.length === 1,
      'lot-lookup-multi__results__col__oils__container__row__labels__group':
        lotStore.lots.length !== 1
    }"
  >
    <small
      class="py-1 cursor-default normal-case"
      :class="{
        'lot-lookup-single__results__col__oils__container__row__labels__group__title':
          lotStore.lots.length === 1,
        'lot-lookup-multi__results__col__oils__container__row__labels__group__title':
          lotStore.lots.length !== 1
      }"
      v-tooltip="getTooltip(definition)"
      >{{ uppercaseExceptBeta(label) }}</small
    >
    <small class="py-1" v-if="showValuesInLegend">{{ compoundValue }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import { getTooltip } from '@/composables/definitions'
import type { TooltipKey } from '@/composables/definitions'
import { uppercaseExceptBeta } from '@/composables/definitions'

const props = defineProps<{
  label: string
  value: number
  definition: TooltipKey
}>()

const lotStore = useLotStore()

const compoundValue = computed(() => {
  if (props.value) {
    return props.value.toFixed(1).toString() + '%'
  }
})

const showValuesInLegend = computed(() => {
  return lotStore.lots.length === 1
})
</script>
