<template>
  <div class="flex flex-col">
    <h4>Addition {{ addition.id + 1 }}</h4>

    <div class="grid grid-cols-1 lg:grid-cols-5 gap-4">
      <y-dropdown
        label="Variety"
        v-model="addition.variety"
        :options="varietyStore.varieties"
        :allowNoSelection="false"
      />
      <y-dropdown
        label="Product"
        v-model="addition.product"
        :options="productOptions"
        :allowNoSelection="false"
      />
      <div>
        <div class="flex justify-start items-start gap-1">
          <label for="utilization" class="ibu-calc__label">Utilization</label>
          <span
            v-tooltip="{
              html: true,
              content: `
          <h5>Utilization</h5>
          <p class='text-white'>The percentage of alpha acids that are converted to isomerized alpha acids. Our defaults are usually good estimates, but every system is different</p>
          `
            }"
          >
            <y-icon icon="circle-info-solid" />
          </span>
        </div>
        <y-input
          input-type="number"
          id="utilization"
          units="%"
          :min="0"
          :max="100"
          v-model="addition.product.utilizationPercent"
          @keydown="filterNonNumericInput"
        />
      </div>
      <y-input
        v-if="usingExtract"
        label="GMA"
        input-type="number"
        :min="0"
        v-model="addition.gma"
        @keydown="filterNonNumericInput"
      />
      <y-input
        v-else
        label="Alpha"
        input-type="number"
        :min="0"
        units="%"
        v-model="addition.alpha"
        @keydown="filterNonNumericInput"
      />
      <y-input
        v-if="!usingExtract"
        label="Amount"
        v-model="addition.amount"
        :units="unitMass"
        input-type="number"
        @keydown="filterNonNumericInput"
      />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-5 gap-4">
      <y-dropdown
        label="Location"
        :options="['Kettle', 'Flameout', 'Whirlpool']"
        :allow-no-selection="false"
        v-model="addition.location"
      />

      <y-input
        label="Boil Time"
        input-type="number"
        :units="time"
        v-model="addition.time"
        v-if="addition.location === 'Kettle'"
        @keydown="filterNonNumericInput"
      />
      <div v-if="addition.location === 'Flameout' || addition.location === 'Whirlpool'">
        <div class="flex justify-start items-start gap-1">
          <label for="time-until-175" class="ibu-calc__label flex gap-1"
            >Time Until<span v-if="measurementSystem === 'Imperial'">175&degF</span
            ><span v-else>79.5&degC</span></label
          >
          <span
            v-tooltip="{
              html: true,
              content: `
          <h5>Isomerization Time</h5>
          <p class='text-white'>The approximate amount of time, in minutes, it takes for your wort to cool to 79.5&deg;C/175&deg;F after adding hops</p>

          `
            }"
          >
            <y-icon icon="circle-info-solid" />
          </span>
        </div>
        <y-input
          id="time-until-175"
          input-type="number"
          :units="time"
          v-model="addition.timeUntil175"
          @keydown="filterNonNumericInput"
        />
      </div>
      <div v-if="addition.location === 'Whirlpool'">
        <div class="flex justify-between w-full">
          <div class="flex justify-start items-start gap-1">
            <label for="addition-temp" class="ibu-calc__label">Addition Temp</label>
            <span
              v-tooltip="{
                html: true,
                content: `
          <h5>Addition Temp</h5>
          <p class='text-white'>Temperature of the wort when hops are added (min 79.5&deg;C/175&deg;F) </p>
          `
              }"
            >
              <y-icon icon="circle-info-solid" />
            </span>
          </div>
          <span v-if="!validAdditionTemp" class="ibu-calc__label ibu-calc__addition-low-warning"
            >Too Low</span
          >
        </div>
        <y-input
          input-type="number"
          v-model="addition.additionTemp"
          :units="unitTemp"
          @keydown="filterNonNumericInput"
          @change="adjustLowAdditionTemp"
        />
      </div>
      <div class="flex flex-col" v-if="ibus">
        <label :for="'ibu-contribution-' + addition.id" class="ibu-calc__label">Contribution</label>
        <div
          :id="'ibu-contribution-' + addition.id"
          class="text-lg p-2 rounded-md y-bg-color-red text-white w-full h-10 flex justify-center items-center font-bold"
        >
          {{ ibus }} IBUs
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, watchPostEffect } from 'vue'
import { KettleAddition } from '@/components/IbuCalculator/types/KettleAddition'
import { YDropdown, YIcon, YInput } from '@yakimachief/ych-ts-component-library'
import useVarietyStore from '@/stores/VarietyStore'
import { productOptions, time } from '@/components/IbuCalculator/composables/consts'
import { filterNonNumericInput } from '@/components/IbuCalculator/composables/inputHandlers'
import {
  calculatedPreBoilGravity,
  unitMass,
  unitTemp
} from '@/components/IbuCalculator/composables/computed'
import {
  calculateIsomerizationPctQuadratic,
  convertGravity,
  convertMass,
  convertTemperature,
  convertVolume,
  precisionRound,
  unConvertTemperature
} from '@/components/IbuCalculator/composables/utils'
import {
  boilTime,
  calculateMyGravity,
  measurementSystem,
  postBoilGravity,
  postBoilVolume,
  preBoilGravity,
  preBoilVolume
} from '@/components/IbuCalculator/composables/refs'

const addition = defineModel<KettleAddition>({ required: true })

const varietyStore = useVarietyStore()

const usingExtract = computed(() => {
  return addition.value.product.name === 'Extract'
})

const ibus = computed(() => {
  calculateIbus()
  return addition.value.ibus
})

const calculateIbus = () => {
  if (inputCompleted.value) {
    let aau = calculateAAU()
    let util = calculateUtil()
    let volmult = calculateVolumeMultiplier()
    let productutil = calculateProductUtilization()
    addition.value.ibus = precisionRound(aau * util * volmult * productutil, 1)
  } else {
    addition.value.ibus = undefined
  }
}

const calculateProductUtilization = () => {
  let utilizationPercent = addition.value.product.utilizationPercent ?? 0
  if (utilizationPercent < 30) {
    return 1 - Math.abs((utilizationPercent - 30) / 30)
  } else if (utilizationPercent === 30) {
    return 1
  } else {
    return (utilizationPercent - 30) / 30 + 1
  }
}

const calculateAAU = () => {
  if (usingExtract.value) {
    return (addition.value.gma ?? 0) * 0.035274 * 100
  }

  return convertMass(addition.value.amount ?? 0) * (addition.value.alpha ?? 0)
}

const calculateUtil = () => {
  return calculateGravity() * calculateTime()
}

const calculateGravity = () => {
  switch (addition.value.location) {
    case 'Kettle':
      return 1.65 * Math.pow(0.000125, calculateSpecificGravity() - 1)
    case 'Flameout':
    case 'Whirlpool':
      return 1.65 * Math.pow(0.000125, compPostBoilGravity.value - 1)
  }
  return 0
}

const calculateSpecificGravity = () => {
  return (
    compPostBoilGravity.value -
    ((compPostBoilGravity.value - compPreBoilGravity.value) / boilTime.value) *
      ((addition.value.time ?? 0) / 2)
  )
}

const calculateTime = () => {
  switch (addition.value.location) {
    case 'Kettle':
      return (1 - Math.pow(Math.E, -0.04 * (addition.value.time ?? 0))) / 4.15
    case 'Flameout':
      addition.value.additionTemp = Math.ceil(unConvertTemperature(212) * 2) / 2
    // 212 is temp in *F
    case 'Whirlpool':
      return (
        (1 -
          Math.pow(
            Math.E,
            -0.04 * (addition.value.timeUntil175 ?? 0) * calculateWeightedAverageIsomerizationPct()
          )) /
        4.15
      )
  }
  return 0
}

const calculateWeightedAverageIsomerizationPct = () => {
  let additionTemp = convertTemperature(addition.value.additionTemp ?? 0)
  let temp1 = calculateIsomerizationPctQuadratic(additionTemp)
  let temp2 = calculateIsomerizationPctQuadratic(additionTemp - (additionTemp - 175) / 4)
  let temp3 = calculateIsomerizationPctQuadratic(additionTemp - 2 * ((additionTemp - 175) / 4))
  let temp4 = calculateIsomerizationPctQuadratic(additionTemp - 3 * ((additionTemp - 175) / 4))
  let temp5 = calculateIsomerizationPctQuadratic(additionTemp - 4 * ((additionTemp - 175) / 4))
  return (temp1 + temp2 + temp3 + temp4 + temp5) / 5
}

const calculateVolumeMultiplier = () => {
  switch (addition.value.location) {
    case 'Kettle':
      return 74.9 / calculateVolume()
    case 'Flameout':
    case 'Whirlpool':
      return 74.9 / convertVolume(postBoilVolume.value)
  }
  return 0
}

const calculateVolume = () => {
  let convertedPreboilVolume = convertVolume(preBoilVolume.value)
  let convertedPostboilVolume = convertVolume(postBoilVolume.value)
  return (
    convertedPreboilVolume -
    ((convertedPreboilVolume - convertedPostboilVolume) / boilTime.value) *
      ((addition.value.time ?? 0) / 2)
  )
}

const compPostBoilGravity = computed(() => {
  return convertGravity(postBoilGravity.value) ?? 0
})

const compPreBoilGravity = computed(() => {
  if (calculateMyGravity.value) {
    if (calculatedPreBoilGravity.value !== '*') {
      return convertGravity(calculatedPreBoilGravity.value) ?? 0
    }
    return 0
  } else {
    return convertGravity(preBoilGravity.value) ?? 0
  }
})

const inputCompleted = computed(() => {
  if (!addition.value.variety || !addition.value.product || !addition.value.location) {
    return false
  } else if (usingExtract.value) {
    if (!addition.value.gma) {
      return false
    }
  } else if (!addition.value.alpha || !addition.value.amount) {
    return false
  }

  switch (addition.value.location) {
    case 'Kettle':
      return !!addition.value.time
    case 'Flameout':
      return !!addition.value.timeUntil175
    case 'Whirlpool':
      return (
        !!addition.value.timeUntil175 && !!addition.value.additionTemp && validAdditionTemp.value
      )
  }
})

const additionTemp = computed(() => {
  if (addition.value.additionTemp !== undefined) {
    return convertTemperature(addition.value.additionTemp) ?? 0
  }
  return 0
})

const adjustLowAdditionTemp = () => {
  if (!validAdditionTemp.value) {
    addition.value.additionTemp = Math.ceil(unConvertTemperature(175) * 2) / 2
  }
}

const validAdditionTemp = computed(() => {
  return additionTemp.value >= 175
})

watchPostEffect(() => {
  const product = addition.value.product
  if (product.utilizationPercent !== undefined && product.utilizationPercent !== '') {
    const numericValue = parseFloat(product.utilizationPercent)
    if (!isNaN(numericValue)) {
      product.utilizationPercent = Math.max(0, Math.min(numericValue, 100))
    }
  }
})
watchPostEffect(() => {
  if (addition.value.alpha !== undefined && addition.value.alpha !== '') {
    const numericValue = parseFloat(addition.value.alpha)
    if (!isNaN(numericValue)) {
      addition.value.alpha = Math.max(0, Math.min(numericValue, 100))
    }
  }
})
</script>

<style lang="scss" scoped>
.ibu-calc {
  &__addition-low-warning {
    font-size: var(--y-font-size-sm);
    font-weight: var(--y-font-weight-medium);
    color: var(--ych-red);
  }
}
</style>
