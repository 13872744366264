<template>
  <div class="limit-width-md w-full flex flex-col md:flex-row gap-4 justify-between items-center">
    <div class="flex gap-3 w-full items-center">
      <img class="product-logo" src="/img/ych-ibu_blue.svg" alt="IBU Icon" />
      <h1>Kettle Addition IBUs</h1>
    </div>
    <div class="flex w-full gap-4 justify-start md:justify-end print:hidden">
      <YButton v-if="showReset" icon="rotate-left" label="Reset" @click="() => emit('reset')" />
      <YButton v-if="showSave" icon="download" label="Save" @click="() => emit('save')" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { YButton } from '@yakimachief/ych-ts-component-library'

const props = defineProps<{ showReset: boolean; showSave: boolean }>()
const emit = defineEmits(['reset', 'save'])
</script>
<style scoped>
.product-logo {
  max-width: 4.5rem;
}
</style>
