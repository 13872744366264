<template>
  <div class="flex flex-col card background-light-grey limit-width-md w-full gap-2">
    <div class="flex justify-between items-center w-full">
      <h3>Time, Volume & Gravity</h3>
      <y-button
        @click="calculateMyGravity = !calculateMyGravity"
        size="sm"
        :mobile-fill="false"
        background-color="secondary"
        class="ibu-calc__gravity-calc-button"
      >
        {{ calculateMyGravity ? 'Change Pre-boil Gravity' : 'Calculate Pre-boil Gravity' }}
      </y-button>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-5 w-full gap-4 pb-4">
      <div>
        <label for="boil-time" class="ibu-calc__label">Boil Time</label>
        <y-input id="boil-time" :units="time" input-type="number" v-model="boilTime" width="100%" />
      </div>
      <div>
        <label for="pre-boil-volume" class="ibu-calc__label">Pre-Boil Volume</label>
        <y-input
          id="pre-boil-volume"
          :units="unitVolume"
          input-type="number"
          v-model="preBoilVolume"
          width="100%"
        />
      </div>

      <div>
        <div class="flex justify-start items-start gap-1">
          <label for="post-boil-volume" class="ibu-calc__label"> Post-boil Volume </label>
          <span v-if="invalidPostBoilVolume" v-tooltip="'Must Be Less Than Pre-Boil'">
            <y-icon icon="circle-info-solid" class="ibu-calc__error_icon" />
          </span>
        </div>
        <y-input
          id="post-boil-volume"
          :units="unitVolume"
          input-type="number"
          v-model="postBoilVolume"
          width="100%"
          :class="{ 'ibu-calc__input--invalid': invalidPostBoilVolume }"
        />
      </div>
      <div>
        <div class="flex justify-start items-start gap-1">
          <label for="pre-boil-gravity" class="ibu-calc__label justify-start gap-1">
            <span v-if="calculateMyGravity">Estimated Pre-boil Gravity</span>
            <span v-else>Pre-boil Gravity</span>
          </label>
          <span v-tooltip="toolTip"> <y-icon icon="circle-info-solid" /></span>
        </div>
        <y-input
          width="100%"
          id="pre-boil-gravity"
          :units="unitGravity"
          input-type="number"
          :model-value="calculateMyGravity ? calculatedPreBoilGravity : preBoilGravity"
          @update:model-value="
            (newValue) => {
              if (!calculateMyGravity) {
                preBoilGravity = newValue
              }
            }
          "
          :disabled="calculateMyGravity"
        />
      </div>
      <div>
        <label for="post-boil-gravity" class="ibu-calc__label">Post-boil Gravity</label>
        <y-input
          id="post-boil-gravity"
          :units="unitGravity"
          input-type="number"
          v-model="postBoilGravity"
          width="100%"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { YIcon, YInput, YButton } from '@yakimachief/ych-ts-component-library'
import {
  boilTime,
  calculateMyGravity,
  postBoilGravity,
  postBoilVolume,
  preBoilGravity,
  preBoilVolume
} from '@/components/IbuCalculator/composables/refs'
import { time } from '@/components/IbuCalculator/composables/consts'
import {
  calculatedPreBoilGravity,
  unitGravity,
  unitVolume
} from '@/components/IbuCalculator/composables/computed'

const toolTip = {
  html: true,
  content: `
  <h5>Pre-Boil Gravity</h5>
  <p class="text-white hyphens-none">If the pre-boil gravity is not known, an estimated pre-boil gravity can be calculated after specifying post-boil gravity</p>
  `
}

const invalidPostBoilVolume = computed(() => {
  if (preBoilVolume.value) {
    return preBoilVolume.value < postBoilVolume.value
  }
  return false
})
</script>
<style lang="scss" scoped>
.ibu-calc {
  &__gravity-calc-button {
    font-size: var(--y-font-size-sm);
    font-weight: var(--y-font-weight-medium);
    color: var(--ych-red);
  }

  &__error_icon {
    color: var(--ych-red);
  }

  &__input--invalid {
    border: solid 1px;
    border-color: var(--ych-red-light);
    border-radius: var(--y-radius-md);
  }
}
</style>
