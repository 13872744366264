import { ref, computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import {
  lotsHaveHplcAreaPurity,
  lotsHaveHplcHexaAlpha,
  lotsHaveHplcIsoAlpha,
  lotsHaveHplcRho,
  lotsHaveHplcTetraAlpha,
  lotsHaveLcvAlpha74,
  lotsHaveUvIsoAlpha
} from '@/composables/lotAlpha'

interface FilterType {
  label: string
  key: string
}

type FilterKey = (typeof allFilters)[number]['key']

const filters: FilterType[] = [
  { label: 'Best Use Before', key: 'bestBefore' },
  { label: 'Farms', key: 'farms' },
  { label: 'UV Alpha', key: 'uvAlpha' },
  { label: 'UV Iso-Alpha', key: 'uvIsoAlpha' },
  { label: 'UV Beta', key: 'uvBeta' },
  { label: 'HSI', key: 'hsi' },
  { label: 'HPLC Alpha', key: 'hplcAlpha' },
  { label: 'LCV Alpha 7.4', key: 'lcvAlpha' },
  { label: 'HPLC Area Purity', key: 'hplcAreaPurity' },
  { label: 'HPLC Rho Alpha', key: 'hplcRho' },
  { label: 'HPLC Iso-Alpha', key: 'hlpcIsoAlpha' },
  { label: 'HPLC Hexa Alpha', key: 'hexAlpha' },
  { label: 'HPLC Tetra Alpha', key: 'tetAlpha' },
  { label: 'HPLC Beta', key: 'hplcBeta' },
  { label: 'HPLC Cohumulone', key: 'hplcCohumulone' },
  { label: 'HPLC Colupulone', key: 'hplcColupulone' },
  { label: 'Mebak Alpha', key: 'mebakAlpha' },
  { label: 'Moisture', key: 'moisture' },
  { label: 'Total Oil', key: 'totalOil' },
  { label: 'B-Pinene', key: 'betaPinene' },
  { label: 'Myrcene', key: 'myrcene' },
  { label: 'Linalool (%)', key: 'linaloolOil' },
  { label: 'Caryophyllene', key: 'caryophyllene' },
  { label: 'Farnesene', key: 'farnesene' },
  { label: 'Humulene', key: 'humulene' },
  { label: 'Geraniol', key: 'geraniolOil' },
  { label: 'Other Oil', key: 'other' },
  { label: 'Isobutyl Isobutyrate', key: 'isobutylIsobutyrate' },
  { label: 'Isoamyl Isobutyrate', key: 'isoamylIsobutyrate' },
  { label: '2-Methylbutyl Isobutyrate', key: 'twoMethylbutylIsobutyrate' },
  { label: 'Linalool (ppm)', key: 'linaloolSurvivable' },
  { label: 'Geraniol (ppm)', key: 'geraniolSurvivable' },
  { label: '2-Nonanone', key: 'twoNonanone' },
  { label: 'Methyl Geranate', key: 'methylGeranate' },
  { label: '3-Mercaptohexanol', key: 'threeMercaptohexanol' },
  { label: 'Top 5 Aromas', key: 'aromas' }
]

const chartFilters: FilterType[] = [
  { label: 'Alpha Acids Chart', key: 'uvAlphaChart' },
  { label: 'Aroma Profile Graph', key: 'aromaProfileGraph' },
  { label: 'Total Oil Chart', key: 'totalOilChart' }
]

const individualRecordFilters: FilterType[] = [{ label: 'Spray Record Notes', key: 'sprayNotes' }]

const allFilters = chartFilters.concat(filters).concat(individualRecordFilters)

const filterSettings = ref([...allFilters])
const rowFocus = ref()

const showKey = (key: FilterKey): boolean => {
  return filterSettings.value.some((filter: FilterType) => filter.key === key)
}

const showFirstTableGroup = computed(() => {
  return showKey('bestBefore') || showKey('farms')
})

const showTableBrewingValues = computed(() => {
  return brewingValuesTableItemCount.value > 0
})

const brewingValuesTableItemCount = computed(() => {
  let count = 0
  if (showKey('uvAlpha')) {
    count++
  }
  if (showKey('uvBeta')) {
    count++
  }
  if (showKey('hsi')) {
    count++
  }
  if (showKey('hplcAlpha')) {
    count++
  }
  if (showKey('hplcBeta')) {
    count++
  }
  if (showKey('hplcCohumulone')) {
    count++
  }
  if (showKey('hplcColupulone')) {
    count++
  }
  if (showKey('mebakAlpha')) {
    count++
  }
  if (showKey('moisture')) {
    count++
  }
  if (showKey('uvIsoAlpha') && lotsHaveUvIsoAlpha.value) {
    count++
  }
  if (showKey('lcvAlpha') && lotsHaveLcvAlpha74.value) {
    count++
  }
  if (showKey('hplcAreaPurity') && lotsHaveHplcAreaPurity.value) {
    count++
  }
  if (showKey('hplcRho') && lotsHaveHplcRho.value) {
    count++
  }
  if (showKey('hlpcIsoAlpha') && lotsHaveHplcIsoAlpha.value) {
    count++
  }
  if (showKey('tetAlpha') && lotsHaveHplcTetraAlpha.value) {
    count++
  }
  if (showKey('hexAlpha') && lotsHaveHplcHexaAlpha.value) {
    count++
  }
  return count
})

const showTableOilComponents = computed(() => {
  return oilTableItemCount.value > 0
})

const oilTableItemCount = computed(() => {
  let count = 0
  if (showKey('totalOil')) {
    count++
  }
  if (showKey('betaPinene')) {
    count++
  }
  if (showKey('myrcene')) {
    count++
  }
  if (showKey('linaloolOil')) {
    count++
  }
  if (showKey('caryophyllene')) {
    count++
  }
  if (showKey('farnesene')) {
    count++
  }
  if (showKey('humulene')) {
    count++
  }
  if (showKey('geraniolOil')) {
    count++
  }
  if (showKey('other')) {
    count++
  }
  return count
})

const showTableSurvivables = computed(() => {
  return survivablesTableItemCount.value > 0
})

const survivablesTableItemCount = computed(() => {
  let count = 0
  if (showKey('isobutylIsobutyrate')) {
    count++
  }
  if (showKey('isoamylIsobutyrate')) {
    count++
  }
  if (showKey('twoMethylbutylIsobutyrate')) {
    count++
  }
  if (showKey('linaloolSurvivable')) {
    count++
  }
  if (showKey('geraniolSurvivable')) {
    count++
  }
  if (showKey('twoNonanone')) {
    count++
  }
  if (showKey('methylGeranate')) {
    count++
  }
  if (showKey('threeMercaptohexanol')) {
    count++
  }
  return count
})

const brewingValueChartItemCount = computed(() => {
  const showKey = (key: string) => true
  let count = 0
  if (showKey('uvAlpha')) {
    count++
  }
  if (showKey('uvBeta')) {
    count++
  }
  if (showKey('hplcAlpha')) {
    count++
  }
  if (showKey('hplcBeta')) {
    count++
  }
  if (showKey('mebakAlpha')) {
    count++
  }
  if (showKey('uvIsoAlpha') && lotsHaveUvIsoAlpha.value) {
    count++
  }
  if (showKey('lcvAlpha') && lotsHaveLcvAlpha74.value) {
    count++
  }
  if (showKey('hplcRho') && lotsHaveHplcRho.value) {
    count++
  }
  if (showKey('hlpcIsoAlpha') && lotsHaveHplcIsoAlpha.value) {
    count++
  }
  if (showKey('tetAlpha') && lotsHaveHplcTetraAlpha.value) {
    count++
  }
  if (showKey('hexAlpha') && lotsHaveHplcHexaAlpha.value) {
    count++
  }
  return count
})

const survivableChartItemCount = computed(() => {
  return 8
  let count = 0
  if (showKey('isobutylIsobutyrate')) {
    count++
  }
  if (showKey('isoamylIsobutyrate')) {
    count++
  }
  if (showKey('twoMethylbutylIsobutyrate')) {
    count++
  }
  if (showKey('linaloolSurvivable')) {
    count++
  }
  if (showKey('geraniolSurvivable')) {
    count++
  }
  if (showKey('twoNonanone')) {
    count++
  }
  if (showKey('methylGeranate')) {
    count++
  }
  if (showKey('threeMercaptohexanol')) {
    count++
  }
  return count
})

const oilComponentChartItemCount = computed(() => {
  return 8
  let count = 0
  if (showKey('betaPinene')) {
    count++
  }
  if (showKey('myrcene')) {
    count++
  }
  if (showKey('linaloolOil')) {
    count++
  }
  if (showKey('caryophyllene')) {
    count++
  }
  if (showKey('farnesene')) {
    count++
  }
  if (showKey('humulene')) {
    count++
  }
  if (showKey('geraniolOil')) {
    count++
  }
  if (showKey('other')) {
    count++
  }
  return count
})

const otherComponentsChartItemCount = computed(() => {
  const lotStore = useLotStore()

  const showKey = (key: string) => true

  let count = 0
  if (showKey('hsi') && lotStore.lots.length === 1) {
    count++
  }
  if (showKey('hplcCohumulone')) {
    count++
  }
  if (showKey('hplcColupulone')) {
    count++
  }
  if (showKey('moisture')) {
    count++
  }
  if (showKey('sprayNotes') && lotStore.lots.length === 1) {
    count++
  }
  if (showKey('hplcAreaPurity') && lotsHaveHplcAreaPurity.value) {
    count++
  }
  return count
})

const showAlphaBrewingSurvivableChartColumn = computed(() => {
  let count = 0
  if (showKey('uvAlphaChart')) {
    count++
  }
  count += brewingValueChartItemCount.value
  count += survivableChartItemCount.value
  return count > 0
})

const showAromaChartColumn = computed(() => {
  return showKey('aromaProfileGraph') || showKey('aromas')
})

const showOilComponentsChartColumn = computed(() => {
  let count = 0
  if (showKey('totalOilChart')) {
    count++
  }
  count += oilComponentChartItemCount.value
  count += otherComponentsChartItemCount.value
  return count > 0
})

export {
  filterSettings,
  rowFocus,
  filters,
  chartFilters,
  individualRecordFilters,
  allFilters,
  showKey,
  showFirstTableGroup,
  showTableBrewingValues,
  showTableOilComponents,
  showTableSurvivables,
  brewingValueChartItemCount,
  survivableChartItemCount,
  showAlphaBrewingSurvivableChartColumn,
  showAromaChartColumn,
  oilComponentChartItemCount,
  otherComponentsChartItemCount,
  showOilComponentsChartColumn,
  survivablesTableItemCount,
  oilTableItemCount,
  brewingValuesTableItemCount
}
export type { FilterType, FilterKey }
