<template>
  <div class="table-item flex flex-col p-4" :style="customStyles">
    <div class="table-item__top-row flex justify-between items-center w-full">
      <div class="table-item__top-row__left-content flex gap-2">
        <div
          class="table-item__top-row__left-content__color-block print:hidden"
          v-if="isChart"
        ></div>
        <h3 class="whitespace-nowrap" :aria-describedby="'lot-info-' + count">
          {{ lot.lotNumber }}
        </h3>
      </div>
      <y-button
        label=""
        icon="xmark"
        background-color="transparent"
        width="2.75rem"
        size="md"
        @click="removeLot(lot.lotNumber)"
        class="print:hidden"
      />
    </div>
    <div class="table-item__info flex flex-col gap-1" aria-hidden="true">
      <div
        class="table-item__info__lot-details flex flex-col"
        aria-label="Lot Information"
        :id="'lot-info-' + count"
      >
        <h4 class="truncate" :title="lot.variety" aria-label="Variety">
          {{ lot.variety }}
        </h4>
        <div class="table-item__info__lot-details__product-info flex gap-1 items-center">
          <y-icon
            class="table-item__info__lot-details__product-info__product"
            :icon="getProductSlug(lot.productName)"
            font-size="1rem"
            aria-hidden="true"
          />
          <h4
            class="table-item__info__lot-details__product-info__product whitespace-nowrap"
            aria-label="Product"
          >
            {{ lot.productName }}
          </h4>
          <h4
            class="table-item__info__lot-details__product-info__cultivar whitespace-nowrap"
            v-if="lot.cultivar"
            aria-label="Cultivar"
          >
            {{ lot.cultivar }}
          </h4>
        </div>
        <div class="table-item__info__lot-details__product-info flex gap-1 items-center">
          <h4 v-if="showBinNumbers" aria-label="Cultivar">
            Bin: {{ getBinDisplayString(props.lot?.harvestSample) }}
          </h4>
        </div>
      </div>
      <div class="table-item__info__chart-table flex flex-col" v-if="isChart">
        <div
          class="table-item__info__chart-table__pair flex justify-between items-center"
          v-if="showKey('bestBefore')"
        >
          <small class="table-item__info__chart-table__pair__title">Best Use Before:</small>
          <small class="font-normal">{{ bestBefore }}</small>
        </div>
        <div
          class="table-item__info__chart-table__pair flex justify-between items-center"
          v-if="showKey('farms')"
        >
          <small class="table-item__info__chart-table__pair__title">Farm(s):</small>
          <a
            href="#"
            @click.prevent="openGrowerModal = true"
            v-if="lot.farms.length > 0"
            class="print:hidden"
          >
            <small>{{ farms }}</small>
          </a>
          <small v-else class="print:hidden">N/A</small>
          <small
            class="hidden print:block text-right font-normal overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <template v-for="(farm, index) in lot.farms" :key="index">
              <template v-if="index > 0">,</template>
              <span>{{ farm.grownBy }}</span>
            </template>
          </small>
        </div>
        <div
          class="table-item__info__chart-table__pair flex justify-between items-center"
          v-if="showKey('hsi')"
        >
          <small
            v-if="lot.brewingValues?.hsi"
            class="table-item__info__chart-table__pair__title"
            v-tooltip="getTooltip('hsi')"
            >HSI:</small
          >
          <small v-if="lot.brewingValues?.hsi" class="font-normal">{{
            lot.brewingValues.hsi
          }}</small>
        </div>
      </div>
      <div class="table-item__info__table-table flex flex-col" v-if="!isChart">
        <div class="grey-border-bottom" v-if="showFirstTableGroup">
          <lot-lookup-table-item-row show-key-name="bestBefore" described-by="best-before">
            <small class="hidden print:block">Best Use Before:</small>
            <small class="font-normal">{{ bestBefore }}</small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="farms" described-by="farms">
            <a
              v-if="lot.farms.length > 0"
              href="#"
              @click.prevent="openGrowerModal = true"
              class="print:hidden"
            >
              <small class="font-normal">{{ farms }}</small>
            </a>
            <small class="hidden print:block">Farm(s):</small>
            <small
              class="hidden print:block font-normal overflow-hidden text-ellipsis whitespace-nowrap"
            >
              <template v-for="(farm, index) in lot.farms" :key="index">
                <template v-if="index > 0">,</template>
                <span>{{ farm.grownBy }}</span>
              </template>
            </small>
          </lot-lookup-table-item-row>
        </div>
        <div class="grey-border-bottom" v-if="showTableBrewingValues">
          <lot-lookup-table-item-row show-key-name="uvAlpha" described-by="uv-alpha">
            <small class="hidden print:block">UV Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.uvAlpha"
              aria-describedby="uv-alpha"
            >
              {{ lot.brewingValues.uvAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveUvIsoAlpha"
            show-key-name="uvIsoAlpha"
            described-by="uv-iso-alpha"
          >
            <small class="hidden print:block">UV Iso-Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.uvIsoAlpha"
              aria-describedby="uv-iso-alpha"
            >
              {{ lot.brewingValues.uvIsoAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="uvBeta" described-by="uv-beta">
            <small class="hidden print:block">UV Beta:</small>
            <small class="font-normal" v-if="lot.brewingValues?.uvBeta">
              {{ lot.brewingValues.uvBeta }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="hsi" described-by="hsi">
            <small class="hidden print:block">HSI:</small>
            <small class="font-normal" v-if="lot.brewingValues?.hsi">
              {{ lot.brewingValues.hsi }}
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="hplcAlpha" described-by="hplc-alpha">
            <small class="hidden print:block">HPLC Alpha:</small>
            <small class="font-normal" v-if="lot.brewingValues?.hplcAlpha">
              {{ lot.brewingValues.hplcAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveLcvAlpha74"
            show-key-name="lcvAlpha"
            described-by="lcvAlpha"
          >
            <small class="hidden print:block">LCV Alpha 7.4:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.lcvAlpha"
              aria-describedby="lcv-alpha"
            >
              {{ lot.brewingValues.lcvAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveHplcAreaPurity"
            show-key-name="hplcAreaPurity"
            described-by="hlpc-area-purity"
          >
            <small class="hidden print:block">HPLC Area Purity:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.hplcAreaPurity"
              aria-describedby="hlpc-area-purity"
            >
              {{ lot.brewingValues.hplcAreaPurity }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveHplcRho"
            show-key-name="hplcRho"
            described-by="hlpc-rho-alpha"
          >
            <small class="hidden print:block">HPLC Rho Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.hplcRho"
              aria-describedby="hlpc-rho-alpha"
            >
              {{ lot.brewingValues.hplcRho }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveHplcIsoAlpha"
            show-key-name="hlpcIsoAlpha"
            described-by="iso-alpha"
          >
            <small class="hidden print:block">HPLC Iso-Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.hplcIsoAlpha"
              aria-describedby="iso-alpha"
            >
              {{ lot.brewingValues.hplcIsoAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveHplcHexaAlpha"
            show-key-name="hexAlpha"
            described-by="hex-alpha"
          >
            <small class="hidden print:block">HPLC Hexa Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.hplcHex"
              aria-describedby="uv-alpha"
            >
              {{ lot.brewingValues.hplcHex }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            v-if="lotsHaveHplcTetraAlpha"
            show-key-name="tetAlpha"
            described-by="tet-alpha"
          >
            <small class="hidden print:block">HPLC Tetra Alpha:</small>
            <small
              class="font-normal"
              v-if="lot.brewingValues?.hplcTet"
              aria-describedby="uv-alpha"
            >
              {{ lot.brewingValues.hplcTet }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="hplcBeta" described-by="hplc-beta">
            <small class="hidden print:block">HPLC Beta:</small>
            <small class="font-normal" v-if="lot.brewingValues?.hplcBeta">
              {{ lot.brewingValues.hplcBeta }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="hplcCohumulone" described-by="cohumulone">
            <small class="hidden print:block">HPLC Cohumulone:</small>
            <small class="font-normal" v-if="lot.brewingValues?.hplcCohumulone">
              {{ lot.brewingValues.hplcCohumulone }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="hplcColupulone" described-by="colupulone">
            <small class="hidden print:block">HPLC Colupulone:</small>
            <small class="font-normal" v-if="lot.brewingValues?.hplcColupulone">
              {{ lot.brewingValues.hplcColupulone }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="mebakAlpha" described-by="mebak-alpha">
            <small class="hidden print:block">Mebak Alpha:</small>
            <small class="font-normal" v-if="lot.brewingValues?.mebakAlpha">
              {{ lot.brewingValues.mebakAlpha }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="moisture" described-by="moisture">
            <small class="hidden print:block">Moisture:</small>
            <small class="font-normal" v-if="lot.brewingValues?.moisture">
              {{ lot.brewingValues.moisture }}%
            </small>
          </lot-lookup-table-item-row>
        </div>
        <div class="grey-border-bottom" v-if="showTableOilComponents">
          <div aria-hidden="true"></div>
          <lot-lookup-table-item-row show-key-name="totalOil" described-by="total-oil">
            <small class="hidden print:block">Total Oil:</small>
            <small class="font-normal" v-if="lot.oilComponents?.totalOil">
              {{ lot.oilComponents.totalOil }} mL/100g
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="betaPinene" described-by="b-pinene">
            <small class="hidden print:block">β-Pinene:</small>
            <small class="font-normal" v-if="lot.oilComponents?.betaPinene">
              {{ lot.oilComponents.betaPinene }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="myrcene" described-by="myrcene">
            <small class="hidden print:block">Myrcene:</small>
            <small class="font-normal" v-if="lot.oilComponents?.myrcene">
              {{ lot.oilComponents.myrcene }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="linaloolOil" described-by="linalool-oil">
            <small class="hidden print:block">Linalool:</small>
            <small class="font-normal" v-if="lot.oilComponents?.linalool">
              {{ lot.oilComponents.linalool }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="caryophyllene" described-by="caryophyllene">
            <small class="hidden print:block">Caryophyllene:</small>
            <small class="font-normal" v-if="lot.oilComponents?.caryophyllene">
              {{ lot.oilComponents.caryophyllene }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="farnesene" described-by="farnesene">
            <small class="hidden print:block">Farnesene:</small>
            <small class="font-normal" v-if="lot.oilComponents?.farnesene">
              {{ lot.oilComponents.farnesene }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="humulene" described-by="humulene">
            <small class="hidden print:block">Humulene:</small>
            <small class="font-normal" v-if="lot.oilComponents?.humulene">
              {{ lot.oilComponents.humulene }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="geraniolOil" described-by="geraniol-oil">
            <small class="hidden print:block">Geraniol:</small>
            <small class="font-normal" v-if="lot.oilComponents?.geraniol">
              {{ lot.oilComponents.geraniol }}%
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="other" described-by="other-oil">
            <small class="hidden print:block">Other Oil:</small>
            <small class="font-normal" v-if="lot.oilComponents?.other">
              {{ lot.oilComponents.other }}%
            </small>
          </lot-lookup-table-item-row>
        </div>
        <div class="grey-border-bottom" v-if="showTableSurvivables">
          <lot-lookup-table-item-row show-key-name="isobutylIsobutyrate" described-by="isobutyl">
            <small class="hidden print:block">Isobutyl Isobutyrate:</small>
            <small class="font-normal" v-if="lot.survivables?.isobutylIsobutyrate">
              {{ lot.survivables?.isobutylIsobutyrate }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="isoamylIsobutyrate" described-by="isoamyl">
            <small class="hidden print:block">Isoamyl Isobutyrate:</small>
            <small class="font-normal" v-if="lot.survivables?.isoamylIsobutyrate">
              {{ lot.survivables.isoamylIsobutyrate }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            show-key-name="twoMethylbutylIsobutyrate"
            described-by="two-methyl"
          >
            <small class="hidden print:block">2-Methylbutyl Isobutyrate:</small>
            <small class="font-normal" v-if="lot.survivables?.twoMethylbutylIsobutyrate">
              {{ lot.survivables.twoMethylbutylIsobutyrate }} ppm
            </small>
          </lot-lookup-table-item-row>

          <lot-lookup-table-item-row
            show-key-name="linaloolSurvivable"
            described-by="linalool-survivable"
          >
            <small class="hidden print:block">Linalool:</small>
            <small class="font-normal" v-if="lot.survivables?.linalool">
              {{ lot.survivables.linalool }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            show-key-name="geraniolSurvivable"
            described-by="geraniol-survivable"
          >
            <small class="hidden print:block">Geraniol:</small>
            <small class="font-normal" v-if="lot.survivables?.geraniol">
              {{ lot.survivables.geraniol }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="twoNonanone" described-by="two-nonanone">
            <small class="hidden print:block">2-Nonanone:</small>
            <small class="font-normal" v-if="lot.survivables?.twoNonanone">
              {{ lot.survivables?.twoNonanone }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row show-key-name="methylGeranate" described-by="methyl-geranate">
            <small class="hidden print:block">Methyl Geranate:</small>
            <small class="font-normal" v-if="lot.survivables?.methylGeranate">
              {{ lot.survivables.methylGeranate }} ppm
            </small>
          </lot-lookup-table-item-row>
          <lot-lookup-table-item-row
            show-key-name="threeMercaptohexanol"
            described-by="three-mercapto"
          >
            <small class="hidden print:block">3-Mercaptohexanol:</small>
            <small class="font-normal" v-if="lot.survivables?.threeMercaptohexanol">
              {{ lot.survivables.threeMercaptohexanol }} ppm
            </small>
          </lot-lookup-table-item-row>
        </div>

        <lot-lookup-table-item-row
          show-key-name="aromas"
          described-by="aroma-top-five"
          class="gap-2"
        >
          <y-icon
            v-if="lot.aromas"
            v-for="(aroma, index) in lot.aromas.slice(0, 5)"
            :key="index"
            v-tooltip="{
              content: `<span class='font-medium'>${aroma.name}: </span><span>${aroma.intensity}%</span>`,
              html: true
            }"
            class="lot-lookup-multi__results__col__top-five__icons__icon__svg print:hidden"
            :icon="aroma.slug"
            color="#D22630"
            font-size="1rem"
            aria-hidden="true"
          />
          <small class="hidden print:block">Aroma Profile:</small>
          <ul class="sr-only print:not-sr-only flex">
            <li v-if="lot.aromas" v-for="(aroma, index) in lot.aromas.slice(0, 5)" :key="index">
              <small class="font-normal">
                <template v-if="index > 0">,</template>
                {{ aroma.name + ': ' + aroma.intensity }}</small
              >
            </li>
          </ul>
        </lot-lookup-table-item-row>
      </div>
    </div>
    <grower-profile :farms="lot.farms" v-model:open="openGrowerModal" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { YButton, YIcon } from '@yakimachief/ych-ts-component-library'
import { getProductSlug, getProductColor } from '@/composables/iconsAndColors'
import Lot from '@/types/Lot'
import GrowerProfile from '@/components/GrowerProfile.vue'
import useLotStore from '@/stores/LotStore'
import { getTooltip } from '@/composables/definitions'
import {
  showFirstTableGroup,
  showKey,
  showTableBrewingValues,
  showTableOilComponents,
  showTableSurvivables
} from '@/composables/filterSettings'
import LotLookupTableItemRow from '@/components/LotLookup/LotLookupTableItemRow.vue'
import {
  lotsHaveHplcAreaPurity,
  lotsHaveHplcHexaAlpha,
  lotsHaveHplcIsoAlpha,
  lotsHaveHplcRho,
  lotsHaveHplcTetraAlpha,
  lotsHaveLcvAlpha74,
  lotsHaveUvIsoAlpha
} from '@/composables/lotAlpha'
import {
  buildQueryParamObject,
  getBinDisplayString,
  useShowBinNumbers
} from '@/composables/lotBinNumber'

const router = useRouter()
const lotStore = useLotStore()

const { showBinNumbers } = useShowBinNumbers()

type TableItemProperties = {
  lot: Lot
  isChart?: boolean
  lotNumber?: string
  lotColor?: string
  count: number
}
const props = withDefaults(defineProps<TableItemProperties>(), {
  isChart: true,
  lotColor: '#D22630',
  count: 0
})

const openGrowerModal = ref(false)

const productColor = computed(() => {
  return getProductColor(props.lot.productName)
})

const bestBefore = computed(() => {
  const bestBeforeDate = new Date(props.lot.bestBefore)
  return bestBeforeDate.toLocaleString('default', { month: 'long', year: 'numeric' })
})

const farms = computed(() => {
  if (props.lot.farms.length === 1) {
    return props.lot.farms[0].grownBy
  } else return 'View All (' + props.lot.farms.length.toString() + ')'
})

const customStyles = computed(() => ({
  '--lot-color': props.lotColor,
  '--product-color': productColor.value
}))

const removeLot = (lotNumber: string) => {
  lotStore.removeLot(lotNumber)
  router.replace({
    path: 'lookup',
    query: buildQueryParamObject(showBinNumbers.value, lotStore.lotNumbers)
  })
}
</script>

<style scoped lang="scss">
.grey-border-bottom {
  border-bottom: 1px solid var(--ych-grey-200);
}

.table-item {
  background-color: var(--ych-white);
  border-radius: var(--y-radius-md);
  width: 17rem;
  min-width: 14.5rem;
  box-shadow: var(--y-shadow-grey-xl);

  &__top-row {
    &__left-content__color-block {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: var(--y-radius-md);
      background-color: var(--lot-color);
    }
  }

  &__info__lot-details__product-info {
    &__product {
      color: var(--product-color);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__cultivar {
      &:before {
        content: '\2022';
        padding-right: 0.25rem;
      }
    }
  }

  &__info__chart-table__pair,
  &__info__table-table__cell {
    width: 100%;
    height: 1.5rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;
    white-space: nowrap;

    &:nth-child(odd) {
      background-color: var(--ych-grey-100);
    }
  }

  &__info__chart-table__pair__title {
    cursor: default;
  }
}

@media print {
  .grey-border-bottom {
    border-bottom: 2px solid black;
  }
  .table-item__info__chart-table__pair {
    border-bottom: 1px solid var(--ych-grey-200);
  }
}
</style>
