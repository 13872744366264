<template>
  <h3 v-if="showFarmName">{{ farm.grownBy }}</h3>
  <div
    v-if="notJustFarmName(farm)"
    class="grower-profile__wrapper__content__main flex gap-4 w-full"
    :style="responsiveStyles"
  >
    <div
      v-if="hasInfo(farm)"
      class="grower-profile__wrapper__content__main__info flex flex-col gap-4 w-full"
    >
      <div class="grower-profile__wrapper__content__main__info__top flex gap-4 flex-wrap">
        <div
          v-if="farm.foundedDate"
          class="grower-profile__wrapper__content__main__info__top__founded flex gap-1 items-center"
        >
          <h4>Founded:</h4>
          <span>{{ parseFoundedDate(farm.foundedDate) }}</span>
        </div>
        <div
          v-if="farm.ownedBy"
          class="grower-profile__wrapper__content__main__info__top__owner flex gap-1 items-center"
        >
          <h4>Owned By:</h4>
          <span>{{ farm.ownedBy }}</span>
        </div>
      </div>
      <div
        class="grower-profile__wrapper__content__main__about flex flex-col gap-1"
        v-if="farm.aboutText"
      >
        <h4>About:</h4>
        <div
          v-html="farm.aboutText"
          class="grower-profile__wrapper__content__main__about__content"
        ></div>
      </div>
    </div>
    <div
      class="grower-profile__wrapper__content__main__map-container h-60 rounded-md overflow-hidden"
      :class="{
        'w-full': hasInfo(farm) || farm.aboutText == null,
        'd-block': farm.aboutText == null
      }"
    >
      <div
        class="map"
        :id="mapContainerSlug(farm)"
        v-if="props.farm.farmImageCenter"
        :class="farm.aboutText == null ? 'w-full' : ''"
      ></div>
      <img
        v-else-if="farm.satelliteImage"
        class="grower-profile__wrapper__content__main__map-container__map w-full flex justify-center items-center h-full object-cover"
        :src="farm.satelliteImage"
        alt="General location of farm satellite image."
      />
    </div>
  </div>
  <!-- no certifications in API
    <div
      class="grower-profile__wrapper__content__certs flex gap-1 items-center"
      v-if="false"
    >
      <h4>Certifications:</h4>
      <span>{{ certs }}</span>
    </div>
    -->
</template>
<script setup lang="ts">
import type { Farm } from '@/types/Lot'
import maplibregl, { LngLatLike } from 'maplibre-gl'

type IndividualGrowerProfileProperties = {
  farm: Farm
  showFarmName: boolean
}
const props = withDefaults(defineProps<IndividualGrowerProfileProperties>(), {
  showFarmName: true
})

const responsiveStyles = (farm: Farm) => ({
  '--flex-direction': farm.aboutText ? 'row' : 'column'
})

const parseFoundedDate = (dateString: string | undefined) => {
  if (dateString) {
    return new Date(dateString).getFullYear()
  }
}

const mapContainerSlug = (farm: Farm) => {
  return 'Map-' + farm.grownBy.replaceAll(',', '').replaceAll(' ', '-')
}

const hasInfo = (farm: Farm) => {
  return farm.foundedDate || farm.ownedBy || farm.aboutText
}
const notJustFarmName = (farm: Farm) => {
  return farm.foundedDate || farm.ownedBy || farm.aboutText || farm.satelliteImage
}

let maps: any[] = []
const buildMaps = () => {
  let container = mapContainerSlug(props.farm)
  try {
    if (props.farm.farmImageCenter) {
      let map = new maplibregl.Map({
        container: container,
        center: props.farm.farmImageCenter as LngLatLike,
        zoom: 10,
        dragPan: false,
        scrollZoom: false,
        doubleClickZoom: false,
        style:
          'https://api.maptiler.com/maps/ed90447c-87ee-4e64-99e6-db6e0bba8689/style.json?key=APmx1Kk7N1TQtqqViLXs' // style URL
      })
      map.on('load', () => {
        map.addSource('centerPoint', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {
              id: 1
            },
            geometry: {
              type: 'Point',
              coordinates: [props.farm.farmImageCenter[0] ?? 0, props.farm.farmImageCenter[1] ?? 0]
            }
          }
        })
        map.addLayer({
          id: 'centerPointCircle',
          type: 'circle',
          source: 'centerPoint',
          layout: {
            visibility: 'visible'
          },
          paint: {
            'circle-radius': 70,
            'circle-opacity': 0.25,
            'circle-color': '#D22630',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#C41621',
            'circle-stroke-opacity': 0.7
          }
        })
      })
      maps.push(map)
    }
  } catch (exception: any) {
    console.error('map error', exception)
  }
}
setTimeout(() => {
  buildMaps()
}, 500)
</script>

<style lang="scss">
.grower-profile__wrapper__content__main {
  &__about {
    &__content {
      max-height: 200px;
      overflow: auto;
      p,
      div {
        margin-bottom: 0.5rem;
      }
      ul {
        list-style: disc !important;
        margin-left: 20px;
        li {
          list-style: disc !important;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.d-block {
  display: block;
}

.map {
  width: 100%;
  height: 250px;
}

.modal-background {
  background-color: var(--ych-black-20);
}
</style>
