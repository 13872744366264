<template>
  <div v-if="showKey('aromaProfileGraph')" class="lot-result-card" :class="cssBaseClassName">
    <div class="flex justify-between p-4 items-center" :class="cssBaseClassName + '__title-row'">
      <div class="flex flex-col gap-1" :class="cssBaseClassName + '__title-row__left-content'">
        <h4>Aroma Profile</h4>
      </div>
      <div
        class="flex flex-col items-center"
        :class="cssBaseClassName + '__title-row__right-content'"
      >
        <small>Percentage Detected (%)</small>
      </div>
    </div>
    <div
      class="grid grid-cols-1 grid-rows-1 p-4 justify-center items-center"
      :class="cssBaseClassName + '__chart'"
    >
      <AromaProfileChart v-if="hasSensory" />
      <div class="flex flex-col p-4 gap-2" v-else-if="!hasSensory && lotStore.lots.length === 1">
        <p>Sensory data for this lot is currently unavailable.</p>
        <p>
          Please email <a href="mailto:sensory@yakimachief.com">sensory@yakimachief.com</a> for more
          information.
        </p>
      </div>
      <div class="flex flex-col p-4 gap-2" v-else-if="!hasSensory && lotStore.lots.length > 1">
        <p>Sensory data for these lots is currently unavailable.</p>
        <p>
          Please email <a href="mailto:sensory@yakimachief.com">sensory@yakimachief.com</a> for more
          information.
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { showKey } from '@/composables/filterSettings'
import useLotStore from '@/stores/LotStore'
import AromaProfileChart from '@/components/Charts/AromaProfileChart.vue'

const lotStore = useLotStore()

const cssBaseClassName = computed(() => {
  if (lotStore.lots.length === 1) {
    return 'lot-lookup-single__results__col__aroma'
  }
  return 'lot-lookup-multi__results__col__aroma'
})

const hasSensory = computed(() => {
  let hasSensory = false
  lotStore.lots.forEach((lot) => {
    if (lot.aromas && lot.aromas.length > 0) {
      hasSensory = true
    }
  })
  return hasSensory
})
</script>
