import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'

export const lotsHaveUvIsoAlpha = computed(() => {
  const lotStore = useLotStore()
  let hasUvIsoAlpha = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.uvIsoAlpha) {
        hasUvIsoAlpha = true
      }
    }
  })
  return hasUvIsoAlpha
})

export const lotsHaveLcvAlpha74 = computed(() => {
  const lotStore = useLotStore()
  let hasLcvAlpha74 = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.lcvAlpha) {
        hasLcvAlpha74 = true
      }
    }
  })
  return hasLcvAlpha74
})

export const lotsHaveHplcAreaPurity = computed(() => {
  const lotStore = useLotStore()
  let hasHplcAreaPurity = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.hplcAreaPurity) {
        hasHplcAreaPurity = true
      }
    }
  })
  return hasHplcAreaPurity
})

export const lotsHaveHplcRho = computed(() => {
  const lotStore = useLotStore()
  let hasHplcRho = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.hplcRho) {
        hasHplcRho = true
      }
    }
  })
  return hasHplcRho
})

export const lotsHaveHplcIsoAlpha = computed(() => {
  const lotStore = useLotStore()
  let hasHplcIsoAlpha = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.hplcIsoAlpha) {
        hasHplcIsoAlpha = true
      }
    }
  })
  return hasHplcIsoAlpha
})

export const lotsHaveHplcHexaAlpha = computed(() => {
  const lotStore = useLotStore()
  let hasHplcHexAlpha = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.hplcHex) {
        hasHplcHexAlpha = true
      }
    }
  })
  return hasHplcHexAlpha
})

export const lotsHaveHplcTetraAlpha = computed(() => {
  const lotStore = useLotStore()
  let hasHplcTetraAlpha = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lot.brewingValues) {
      if (lot.brewingValues.hplcTet) {
        hasHplcTetraAlpha = true
      }
    }
  })
  return hasHplcTetraAlpha
})
