<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-400"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-400"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 modal-background" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="transition-all flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-800"
            enter-from="opacity-0 translate-y-10 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-800"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-16 sm:scale-90"
          >
            <DialogPanel
              class="add-lots relative transform card text-left transition-all sm:my-8 mx-0 lg:mx-0 sm:mx-12 sm:p-6"
            >
              <div class="add-lots flex flex-col gap-4">
                <div class="add-lots__top-row flex justify-between items-center">
                  <DialogTitle>Add Lots to Compare?</DialogTitle>
                  <y-button
                    icon="xmark"
                    width="2.75rem"
                    background-color="transparent"
                    :mobile-fill="false"
                    @click="setIsOpen(false)"
                  />
                </div>

                <div class="add-lots__content flex flex-col relative gap-4">
                  <lot-search placeholder="Enter Lot Number..." width="30rem" add-lots />

                  <small
                    ><span class="font-semibold">Note:</span> You can add multiple lots at a time by
                    creating a comma-separated list of lot numbers.</small
                  >
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { YButton } from '@yakimachief/ych-ts-component-library'
import LotSearch from '@/components/LotSearch.vue'

type AddLotsModalProperties = {
  open: boolean
}
withDefaults(defineProps<AddLotsModalProperties>(), {
  open: false
})

const emit = defineEmits(['update:open'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: var(--ych-black-20);
}

.add-lots__content small {
  color: var(--ych-grey-400);
}
</style>
