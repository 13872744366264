<template>
  <div class="survivables lot-result-card">
    <div class="survivables__title-row flex justify-between px-4 py-2 items-center">
      <h4>Survivable Compounds</h4>
      <small>Parts per Million (ppm)</small>
    </div>
    <div
      class="lot-lookup-single__results__col__brewing-values__container flex overflow-hidden relative w-full"
      v-if="showSurvivableCompounds"
    >
      <Striping :count="itemCount" />
      <div
        class="survivables__container__row grid grid-cols-2 w-full"
        :style="{ height: chartHeight }"
      >
        <div class="survivables__container__row__labels flex flex-col w-full pl-4 pr-1">
          <SurvivableChartRowLabel
            v-if="showKey('isobutylIsobutyrate')"
            label="Isobutyl Isobutyrate"
            definition="isobutyl"
            :value="lotStore.lots[0].survivables?.isobutylIsobutyrate ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('isoamylIsobutyrate')"
            label="Isoamyl Isobutyrate"
            definition="isoamyl"
            :value="lotStore.lots[0].survivables?.isoamylIsobutyrate ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('twoMethylbutylIsobutyrate')"
            label="2-Methylbutyl Isobutyrate"
            definition="twoMethyl"
            :value="lotStore.lots[0].survivables?.twoMethylbutylIsobutyrate ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('linaloolSurvivable')"
            label="Linalool"
            definition="linalool"
            :value="lotStore.lots[0].survivables?.linalool ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('geraniolSurvivable')"
            label="Geraniol"
            definition="geraniol"
            :value="lotStore.lots[0].survivables?.geraniol ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('twoNonanone')"
            label="2-Nonanone"
            definition="twoNonanone"
            :value="lotStore.lots[0].survivables?.twoNonanone ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('methylGeranate')"
            label="Methyl Geranate"
            definition="methylGeranate"
            :value="lotStore.lots[0].survivables?.methylGeranate ?? 0"
          />
          <SurvivableChartRowLabel
            v-if="showKey('threeMercaptohexanol')"
            label="3-Mercaptohexanol"
            definition="threeMercapto"
            :value="lotStore.lots[0].survivables?.threeMercaptohexanol ?? 0"
          />
        </div>
        <SurvivableCompoundsChart :style="{ height: chartHeight }" />
      </div>
    </div>
    <div
      class="flex flex-col p-4 gap-2"
      v-else-if="!showSurvivableCompounds && lotStore.lots.length === 1"
    >
      <p>Survivable compound data for this lot is currently unavailable.</p>
      <p>
        Please email <a href="mailto:quality@yakimachief.com">quality@yakimachief.com</a> for more
        information.
      </p>
    </div>
    <div
      class="flex flex-col p-4 gap-2"
      v-else-if="!showSurvivableCompounds && lotStore.lots.length > 1"
    >
      <p>Survivable compound data for these lots is currently unavailable.</p>
      <p>
        Please email <a href="mailto:quality@yakimachief.com">quality@yakimachief.com</a> for more
        information.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLotStore from '@/stores/LotStore'
import SurvivableCompoundsChart from '@/components/Charts/SurvivableCompoundsChart.vue'
import SurvivableChartRowLabel from '@/components/LotLookup/SurvivableChartRowLabel.vue'
import { computed } from 'vue'
import {
  showKey as origShowKey,
  survivableChartItemCount as itemCount
} from '@/composables/filterSettings'
import Striping from '@/components/Charts/Striping.vue'
import AromaProfileChart from '@/components/Charts/AromaProfileChart.vue'
import type Lot from '@/types/Lot'
import { lotHasChartSurvivables } from '@/composables/hasChartSurvivableCompounds'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

const chartHeight = computed(() => {
  return (itemCount.value * 1.5).toString() + 'rem'
})

const showSurvivableCompounds = computed(() => {
  let hasSurvivables = false
  lotStore.lots.forEach((lot: Lot) => {
    if (lotHasChartSurvivables(lot)) {
      hasSurvivables = true
    }
  })
  return hasSurvivables
})
</script>

<style lang="scss">
.survivables {
  &__title-row {
    background-color: var(--ych-grey-100);
    border-bottom: 2px solid var(--ych-grey-200);
  }

  &__container__row {
    z-index: 1;

    &__labels {
      border-right: 1px solid var(--ych-grey-200);
      overflow: hidden;

      &__group {
        align-items: center;
        height: 1.5rem;
        gap: 0.5rem;

        &__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
