<template>
  <div
    class="lot-result-card"
    :class="cssPrefix + '__results__col__other-values'"
    v-if="itemCount > 0"
  >
    <div
      class="flex px-4 py-2 items-center"
      :class="[
        cssPrefix + '__results__col__other-values__title-row',
        isSingle ? 'justify-start' : 'justify-between'
      ]"
    >
      <h4>Other</h4>
      <small v-if="!isSingle">Percentage (%)</small>
    </div>
    <div
      class="flex overflow-hidden relative w-full"
      :class="cssPrefix + '__results__col__other-values__container'"
    >
      <Striping :count="itemCount" />
      <div
        class="w-full"
        :class="[
          cssPrefix + '__results__col__other-values__container__row',
          isSingle ? 'flex' : 'grid grid-cols-2'
        ]"
        :style="{ height: chartHeight }"
      >
        <div
          class="flex flex-col w-full pl-4 pr-1"
          :class="cssPrefix + '__results__col__other-values__container__row__labels'"
          :style="{ height: chartHeight }"
        >
          <div
            class="flex justify-between"
            :class="cssPrefix + '__results__col__other-values__container__row__labels__group'"
            v-if="isSingle && showKey('hsi')"
          >
            <small
              class="uppercase py-1 cursor-default"
              :class="
                cssPrefix + '__results__col__other-values__container__row__labels__group__title'
              "
              v-tooltip="getTooltip('hsi')"
            >
              HSI
            </small>
            <small class="py-1" v-if="isSingle"> {{ lotStore.lots[0].brewingValues?.hsi }}</small>
          </div>
          <div
            class="flex justify-between"
            :class="cssPrefix + '__results__col__other-values__container__row__labels__group'"
            v-if="showKey('hplcCohumulone')"
          >
            <small
              class="uppercase py-1 cursor-default"
              :class="
                cssPrefix + '__results__col__other-values__container__row__labels__group__title'
              "
            >
              HPLC Cohumulone
            </small>
            <small class="py-1" v-if="isSingle && lotStore.lots[0].brewingValues?.hplcCohumulone">
              {{ lotStore.lots[0].brewingValues?.hplcCohumulone }}%
            </small>
          </div>
          <div
            class="flex justify-between"
            :class="cssPrefix + '__results__col__other-values__container__row__labels__group'"
            v-if="showKey('hplcColupulone')"
          >
            <small
              class="uppercase py-1 cursor-default"
              :class="
                cssPrefix + '__results__col__other-values__container__row__labels__group__title'
              "
              >HPLC Colupulone</small
            >
            <small class="py-1" v-if="isSingle && lotStore.lots[0].brewingValues?.hplcColupulone"
              >{{ lotStore.lots[0].brewingValues?.hplcColupulone }}%</small
            >
          </div>
          <div
            v-if="showKey('hplcAreaPurity') && lotsHaveHplcAreaPurity"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
            >
              HPLC Area Purity
            </small>
            <small class="py-1" v-if="isSingle && lotStore.lots[0].brewingValues?.hplcAreaPurity">
              {{ lotStore.lots[0].brewingValues?.hplcAreaPurity }}%
            </small>
          </div>
          <div
            class="flex justify-between"
            :class="cssPrefix + '__results__col__other-values__container__row__labels__group'"
            v-if="showKey('moisture')"
          >
            <small
              class="uppercase py-1 cursor-default"
              :class="
                cssPrefix + '__results__col__other-values__container__row__labels__group__title'
              "
              v-tooltip="getTooltip('moisture')"
              >Moisture</small
            >
            <small class="py-1" v-if="isSingle && lotStore.lots[0].brewingValues?.moisture"
              >{{ lotStore.lots[0].brewingValues?.moisture }}%</small
            >
          </div>
          <div
            class="flex justify-between"
            :class="cssPrefix + '__results__col__other-values__container__row__labels__group'"
            v-if="isSingle && showKey('sprayNotes')"
          >
            <small
              class="uppercase py-1 cursor-default"
              v-tooltip="'Spray Record Notes'"
              :class="
                cssPrefix + '__results__col__other-values__container__row__labels__group__title'
              "
              >Spray Record Notes</small
            >
            <small
              class="py-1 truncate text-right"
              v-tooltip="lotStore.lots[0].notes.map((note) => note.text).join(', ') ?? 'N/A'"
              v-if="isSingle"
              >{{ lotStore.lots[0].notes.map((note) => note.text).join(', ') ?? 'N/A' }}</small
            >
          </div>
        </div>
        <other-values :style="{ height: chartHeight }" v-if="!isSingle" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import OtherValues from '@/components/Charts/OtherValuesChart.vue'
import useLotStore from '@/stores/LotStore'
import { getTooltip } from '@/composables/definitions'
import {
  showKey as oldShowKEy,
  otherComponentsChartItemCount as itemCount
} from '@/composables/filterSettings'
import Striping from '@/components/Charts/Striping.vue'
import { lotsHaveHplcAreaPurity } from '@/composables/lotAlpha'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

const isSingle = computed(() => {
  return lotStore.lots.length === 1
})
const cssPrefix = computed(() => {
  if (isSingle.value) {
    return 'lot-lookup-single'
  }
  return 'lot-lookup-multi'
})

const chartHeight = computed(() => {
  return (itemCount.value * 1.5).toString() + 'rem'
})
</script>
