<template>
  <div class="multi-alpha-chart flex flex-col justify-center items-center w-full">
    <div class="multi-alpha-chart__header" v-if="groupedLots">
      {{ groupedLots.variety }}
      <y-icon
        class="table-item__info__lot-details__product-info__product"
        :icon="getProductSlug(groupedLots.product)"
        :style="{ color: getProductColor(groupedLots.product) }"
        aria-hidden="true"
      />
    </div>

    <div ref="resizeRef" class="flex flex-row grow h-10 w-full">
      <svg v-tooltip="{ content: tooltipContent, html: true }" ref="svgRef" class="h-full w-full" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, watchEffect, computed } from 'vue'
import { select, scaleLinear } from 'd3'
import useLotStore from '@/stores/LotStore'
import { useResizeObserver } from '@/composables/resizeObserver'
import type { GroupedLot, GroupedLots } from '@/composables/groupLots'
import { getProductSlug, getProductColor, getLotColor } from '@/composables/iconsAndColors'
import { YIcon } from '@yakimachief/ych-ts-component-library'
import { getLotAlpha } from '@/components/LotLookup'

const props = defineProps<{
  groupedLots: GroupedLots
  highAlpha: number
}>()

const lotStore = useLotStore()

const svgRef = ref(null)
const { resizeRef, resizeState } = useResizeObserver()
const low = 0

const high = props.highAlpha

const avg = computed(() => {
  return props.groupedLots.lots[0].lot.brewingRanges?.alphaAve ?? 0
})

const avgLow = computed(() => {
  return props.groupedLots.lots[0].lot.brewingRanges?.alphaLow ?? 0
})

const avgHigh = computed(() => {
  return props.groupedLots.lots[0].lot.brewingRanges?.alphaHigh ?? 0
})

const tooltipContent = computed(() => {
  let content = '<div>'
  props.groupedLots.lots.forEach((groupedLot) => {
    const lotColor = getLotColor(groupedLot.index)
    const alpha = getLotAlpha(groupedLot.lot)
    if (alpha) {
      content += `<div class="flex gap-1 items-center"><div class="multi-alpha-chart__tooltip__lot-color-box" style="background-color:${lotColor}">&nbsp;</div><span class="font-medium">${groupedLot.lot.lotNumber}:</span> ${alpha.name} ${alpha.value}%</div>`
    } else {
      content += `<div class="flex gap-1 items-center"><div class="multi-alpha-chart__tooltip__lot-color-box" style="background-color:${lotColor}">&nbsp;</div><span class="font-medium">${groupedLot.lot.lotNumber}:</span> Unavailable</div>`
    }
  })
  content += '</div>'
  return content
})

onMounted(() => {
  const svg = select(svgRef.value)
  watchEffect(() => {
    const { width, height } = resizeState.dimensions
    // Clear out SVG on resize
    svg.text('')

    const center = height / 2

    // X axis scaler
    const xScale = scaleLinear()
      .domain([low, high])
      .range([35, width - 35])

    // Average range rectangle
    svg
      .append('rect')
      .attr('x', xScale(avgLow.value))
      .attr('y', center - 4)
      .attr('width', xScale(avgHigh.value) - xScale(avgLow.value))
      .attr('height', 8)
      .attr('stroke', 'none')
      .attr('fill', 'rgba(42,47,87,0.22)')

    // Horizontal Line
    svg
      .append('line')
      .attr('y1', center)
      .attr('y2', center)
      .attr('x1', xScale(low))
      .attr('x2', xScale(high))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Left bound line
    svg
      .append('line')
      .attr('y1', center + 8)
      .attr('y2', center - 8)
      .attr('x1', xScale(low))
      .attr('x2', xScale(low))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Right bound line
    svg
      .append('line')
      .attr('y1', center + 8)
      .attr('y2', center - 8)
      .attr('x1', xScale(high))
      .attr('x2', xScale(high))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    // Average value line
    svg
      .append('line')
      .attr('y1', center + 4)
      .attr('y2', center - 4)
      .attr('x1', xScale(avg.value))
      .attr('x2', xScale(avg.value))
      .attr('stroke-width', 2)
      .attr('stroke', 'var(--ych-grey-400)')

    props.groupedLots.lots.forEach((groupedLot: GroupedLot) => {
      const lot = groupedLot.lot
      const alpha = getLotAlpha(lot)
      if (alpha) {
        // UV Alpha
        svg
          .append('line')
          .attr('y1', center + 6)
          .attr('y2', center - 6)
          .attr('x1', xScale(alpha.value))
          .attr('x2', xScale(alpha.value))
          .attr('stroke', getLotColor(groupedLot.index))
          .attr('stroke-width', 4)
          .style('stroke-linecap', 'round')
      }
    })

    // Low Value
    svg
      .append('text')
      .attr('x', 25)
      .attr('y', center)
      .attr('dy', '.35em')
      .style('text-anchor', 'end')
      .text(low)

    // High value
    svg
      .append('text')
      .attr('x', width - 26)
      .attr('y', center)
      .attr('dy', '.35em')
      .text(high)
  })
})
</script>
<style lang="scss">
.multi-alpha-chart {
  &__header {
    font-size: var(--y-font-size-md);
    font-weight: var(--y-font-weight-medium);
    font-family: var(--y-font-family-condensed);
  }

  &__tooltip__lot-color-box {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    vertical-align: middle;
    border: solid;
    border-width: 1px;
    border-color: var(--ych-white);
  }
}
</style>
