<template>
  <div class="min-content-height flex flex-col justify-start items-center">
    <div class="header-image w-full relative">
      <div class="header-image__overlay"></div>
      <span class="header-image__title">Lot Lookup Available on Mobile</span>
      <div class="header-image__buttons">
        <a
          href="https://apps.apple.com/us/app/lot-lookup/id1530265963"
          target="_blank"
          title="Download Lot Lookup on the App Store"
          ><img src="@/../assets/app-store-badge.png" alt="Download on the App Store"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.yakimachiefhops.lotlookup&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          title="Download Lot Lookup on Google Play"
          ><img src="@/../assets/google-play-badge.png" alt="Get it on Google Play"
        /></a>
      </div>
    </div>
    <div class="lot-lookup flex flex-col w-full gap-6 py-12 px-6 justify-center items-center">
      <h2>Lot Analysis Lookup</h2>
      <div class="limit-width flex flex-col gap-4 justify-center w-full items-center">
        <p class="text-center text-pretty">
          Looking for specific hop analysis information for your Yakima Chief Hops purchase?<br /><span
            class="font-medium text-pretty"
          >
            Enter a lot number or, to compare, enter as a comma separated list</span
          >.
        </p>

        <lot-search placeholder="Enter Lot Number..." width="35.125rem" :fresh-search="true" />

        <p class="text-center text-pretty">
          Learn about the
          <a href="#" @click.prevent="openAnalysisMethodsModal = true">analysis methods</a> used by
          YCH laboratories and
          <a href="#" @click.prevent="openAnalysisLegendModal = true"
            >what the lot analysis results mean</a
          >.
        </p>
      </div>
    </div>

    <CalculatorButtons />
    <analysis-methods-modal v-model:open="openAnalysisMethodsModal" />
    <analysis-legend-modal v-model:open="openAnalysisLegendModal" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import LotSearch from '@/components/LotSearch.vue'
import AnalysisMethodsModal from '@/components/AnalysisMethodsModal.vue'
import AnalysisLegendModal from '@/components/AnalysisLegendModal.vue'
import useLotStore from '@/stores/LotStore'
import CalculatorButtons from '@/components/CalculatorButtons.vue'

const lotStore = useLotStore()

const lotSearch = ref([])

const openAnalysisMethodsModal = ref(false)
const openAnalysisLegendModal = ref(false)
</script>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_mobile.jpg);
    height: 35rem;
  }
}

@media screen and (min-width: 641px) and (max-width: 1280px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_tablet.jpg);
    height: 40rem;
  }
}

@media screen and (min-width: 1281px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_desktop.jpg);
    height: 40rem;
  }
}

.header-image {
  color: var(--ych-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  gap: 2rem;
  padding: 2rem;
  text-align: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }

  &__title {
    font-size: 3.052rem;
    letter-spacing: -0.01525rem;
    font-style: normal;
    font-family: var(--y-font-family-condensed);
    font-weight: var(--y-font-weight-bold);
    text-transform: uppercase;
    z-index: 1;
    line-height: 3.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 1;

    img {
      height: 3rem;
    }
  }
}

.lot-lookup {
  background-color: var(--ych-white);
}

@media screen and (max-width: 640px) {
  .calculators__buttons__cryo,
  .calculators__buttons__ibu,
  .calculators__buttons__extract {
    width: 100%;
  }
}
</style>
