<template>
  <div class="other-values">
    <Bar class="other-values__chart" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
import type { ChartData } from 'chart.js'
import { percentageChartOptions as chartOptions } from './chartOptions'
import { lotColors } from '@/components/LotLookup'
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'
import { showKey as origShowKey } from '@/composables/filterSettings'
import { lotsHaveHplcAreaPurity } from '@/composables/lotAlpha'

Chart.register(...registerables)

const showKey = (keyName: string) => true

const lotStore = useLotStore()

const chartData = computed(() => {
  let datasets = []
  if (lotStore.lots.length !== 1) {
    for (let i = 0; i < lotStore.lots.length; i++) {
      if (lotHasOtherChartValues(lotStore.lots[i])) {
        datasets.push({
          label: lotStore.lots[i].lotNumber ?? 'Lot ' + i + 1,
          data: getLotDataSet(lotStore.lots[i]),
          backgroundColor: lotColors[i]
        })
      }
    }
  } else {
    datasets.push({
      label: lotStore.lots[0].lotNumber ?? 'Lot 1',
      data: getLotDataSet(lotStore.lots[0]),
      backgroundColor: lotColors[2]
    })
  }
  let labels = []
  if (showKey('hplcCohumulone')) {
    labels.push('HPLC Cohumulone')
  }
  if (showKey('hplcColupulone')) {
    labels.push('HPLC Colupulone')
  }
  if (showKey('hplcAreaPurity') && lotsHaveHplcAreaPurity.value) {
    labels.push('HPLC Area Purity')
  }
  if (showKey('moisture')) {
    labels.push('Moisture')
  }

  return {
    labels,
    datasets
  } as ChartData<'bar'>
})

const getLotDataSet = (lot: Lot) => {
  let data = []
  if (showKey('hplcCohumulone')) {
    if (lot.brewingValues?.hplcCohumulone != null) {
      data.push(lot.brewingValues?.hplcCohumulone / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('hplcColupulone')) {
    if (lot.brewingValues?.hplcColupulone != null) {
      data.push(lot.brewingValues?.hplcColupulone / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('hplcAreaPurity') && lotsHaveHplcAreaPurity.value) {
    if (lot.brewingValues?.hplcAreaPurity != null) {
      data.push(lot.brewingValues?.hplcAreaPurity / 100)
    } else {
      data.push(null)
    }
  }
  if (showKey('moisture')) {
    if (lot.brewingValues?.moisture != null) {
      data.push(lot.brewingValues?.moisture / 100)
    } else {
      data.push(null)
    }
  }
  return data
}

function lotHasOtherChartValues(lot: Lot) {
  if (lot.brewingValues) {
    if (
      lot.brewingValues.hplcCohumulone ||
      lot.brewingValues.hplcColupulone ||
      lot.brewingValues.hplcAreaPurity ||
      lot.brewingValues.moisture
    ) {
      return true
    }
  }
  return false
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.other-values {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__chart {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    p {
      display: none;
    }
  }
}
</style>
