<template>
  <div
    class="lot-result-card"
    :class="cssPrefix + '__results__col__oils'"
    v-if="hasChartOilComponents"
  >
    <div
      class="flex justify-between px-4 py-2 items-center"
      :class="cssPrefix + '__results__col__oils__title-row'"
    >
      <h4>Oil Components</h4>
      <small>Percentage (%)</small>
    </div>
    <div
      class="flex justify-center overflow-hidden relative w-full"
      :class="cssPrefix + '__results__col__oils__chart'"
    >
      <Striping :count="itemCount" />
      <div
        class="grid grid-cols-2 w-full"
        :class="cssPrefix + '__results__col__oils__container__row'"
        :style="{ height: chartHeight }"
      >
        <div
          class="flex flex-col w-full pl-4 pr-1"
          :class="cssPrefix + '__results__col__oils__container__row__labels'"
        >
          <OilComponentsRowLabel
            v-if="showKey('betaPinene')"
            label="β-Pinene"
            definition="bPinene"
            :value="lotStore.lots[0].oilComponents?.betaPinene ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('myrcene')"
            label="Myrcene"
            definition="myrcene"
            :value="lotStore.lots[0].oilComponents?.myrcene ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('linaloolOil')"
            label="Linalool"
            definition="linalool"
            :value="lotStore.lots[0].oilComponents?.linalool ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('caryophyllene')"
            label="Caryophyllene"
            definition="caryophyllene"
            :value="lotStore.lots[0].oilComponents?.caryophyllene ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('farnesene')"
            label="Farnesene"
            definition="farnesene"
            :value="lotStore.lots[0].oilComponents?.farnesene ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('humulene')"
            label="Humulene"
            definition="humulene"
            :value="lotStore.lots[0].oilComponents?.humulene ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('geraniolOil')"
            label="Geraniol"
            definition="geraniol"
            :value="lotStore.lots[0].oilComponents?.geraniol ?? 0"
          />
          <OilComponentsRowLabel
            v-if="showKey('other')"
            label="Other"
            definition="other"
            :value="lotStore.lots[0].oilComponents?.other ?? 0"
          />
        </div>
        <OilComponentsChart :style="{ height: chartHeight }" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import OilComponentsRowLabel from '@/components/LotLookup/OilComponentsRowLabel.vue'
import OilComponentsChart from '@/components/Charts/OilComponentsChart.vue'
import {
  showKey as origShowKey,
  oilComponentChartItemCount as itemCount
} from '@/composables/filterSettings'
import Striping from '@/components/Charts/Striping.vue'
import hasChartOilComponents from '@/composables/hasChartOilComponents'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

const cssPrefix = computed(() => {
  if (lotStore.lots.length === 1) {
    return 'lot-lookup-single'
  }
  return 'lot-lookup-multi'
})

const chartHeight = computed(() => {
  return (itemCount.value * 1.5).toString() + 'rem'
})
</script>
