import { ref } from 'vue'
import type { Ref } from 'vue'
import type { KettleAddition } from '@/components/IbuCalculator/types/KettleAddition'

export const boilTime = ref()
export const preBoilVolume = ref()
export const postBoilVolume = ref()
export const preBoilGravity = ref()
export const postBoilGravity = ref()
export const calculateMyGravity = ref(false)
export const measurementSystem = ref('Imperial')
export const measurementScale = ref('Home Brew')
export const gravityUnits = ref('Specific Gravity')

export const additions: Ref<KettleAddition[]> = ref([])
