<template>
  <div v-if="hasChartBrewingValues" class="brewing-values lot-result-card">
    <div class="brewing-values__title-row flex justify-between px-4 py-2 items-center">
      <h4>Brewing Values</h4>
      <small>Percentage (%)</small>
    </div>
    <div class="brewing-values__container flex overflow-hidden relative w-full">
      <Striping :count="itemCount" />
      <div
        class="brewing-values__container__row grid grid-cols-2 w-full"
        :style="{ height: labelHeight }"
      >
        <div class="brewing-values__container__row__labels flex flex-col w-full pl-4 pr-1">
          <div
            v-if="showKey('uvAlpha')"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              UV Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.uvAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.uvAlpha }}%
            </small>
          </div>

          <div
            v-if="showKey('uvIsoAlpha') && lotsHaveUvIsoAlpha"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              UV Iso-Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.uvIsoAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.uvIsoAlpha }}%
            </small>
          </div>
          <div
            v-if="showKey('uvBeta')"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('betaAcids')"
            >
              UV Beta
            </small>
            <small class="py-1" v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.uvBeta">
              {{ lotStore.lots[0].brewingValues?.uvBeta }}%
            </small>
          </div>
          <div
            v-if="showKey('hplcAlpha')"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              HPLC Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.hplcAlpha }}%
            </small>
          </div>
          <div
            v-if="showKey('hplcBeta')"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('betaAcids')"
            >
              HPLC Beta
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcBeta"
            >
              {{ lotStore.lots[0].brewingValues?.hplcBeta }}%
            </small>
          </div>
          <div
            v-if="showKey('mebakAlpha')"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              Mebak Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.mebakAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.mebakAlpha }}%
            </small>
          </div>
          <div
            v-if="showKey('lcvAlpha') && lotsHaveLcvAlpha74"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              LCV Alpha 7.4
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.lcvAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.lcvAlpha }}%
            </small>
          </div>
          <div
            v-if="showKey('hplcRho') && lotsHaveHplcRho"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              HPLC Rho Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcRho"
            >
              {{ lotStore.lots[0].brewingValues?.hplcRho }}%
            </small>
          </div>
          <div
            v-if="showKey('hlpcIsoAlpha') && lotsHaveHplcIsoAlpha"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              HPLC Iso-Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcIsoAlpha"
            >
              {{ lotStore.lots[0].brewingValues?.hplcIsoAlpha }}%
            </small>
          </div>
          <div
            v-if="showKey('hexAlpha') && lotsHaveHplcHexaAlpha"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              HPLC Hexa Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcHex"
            >
              {{ lotStore.lots[0].brewingValues?.hplcHex }}%
            </small>
          </div>
          <div
            v-if="showKey('tetAlpha') && lotsHaveHplcTetraAlpha"
            class="brewing-values__container__row__labels__group flex justify-between"
          >
            <small
              class="brewing-values__container__row__labels__group__title uppercase py-1 cursor-default"
              v-tooltip="getTooltip('alphaAcids')"
            >
              HPLC Tetra Alpha
            </small>
            <small
              class="py-1"
              v-if="showValuesInLegend && lotStore.lots[0].brewingValues?.hplcTet"
            >
              {{ lotStore.lots[0].brewingValues?.hplcTet }}%
            </small>
          </div>
        </div>
        <brewing-values-chart :style="{ height: labelHeight }" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import BrewingValuesChart from '@/components/Charts/BrewingValuesChart.vue'
import useLotStore from '@/stores/LotStore'
import {
  showKey as origShowKey,
  brewingValueChartItemCount as itemCount
} from '@/composables/filterSettings'
import Striping from '@/components/Charts/Striping.vue'
import { getTooltip } from '@/composables/definitions'
import hasChartBrewingValues from '@/composables/hasChartBrewingValues'
import {
  lotsHaveHplcHexaAlpha,
  lotsHaveHplcIsoAlpha,
  lotsHaveHplcRho,
  lotsHaveHplcTetraAlpha,
  lotsHaveLcvAlpha74,
  lotsHaveUvIsoAlpha
} from '@/composables/lotAlpha'

const showKey = (keyName: string) => true

const lotStore = useLotStore()

const showValuesInLegend = computed(() => {
  return lotStore.lots.length === 1
})

const labelHeight = computed(() => {
  return (itemCount.value * 1.5).toString() + 'rem'
})
</script>
<style lang="scss">
.brewing-values {
  &__title-row {
    background-color: var(--ych-grey-100);
    border-bottom: 2px solid var(--ych-grey-200);
  }

  &__container__row {
    z-index: 1;

    &__labels {
      border-right: 1px solid var(--ych-grey-200);
      overflow: hidden;

      &__group {
        align-items: center;
        gap: 0.5rem;
        height: 1.5rem;

        small {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &__title-row {
      background-color: var(--ych-grey-100);
      border-bottom: 2px solid var(--ych-grey-200);
    }
  }
}
</style>
