<template>
  <div class="lot-lookup-multi flex flex-col gap-6 w-full">
    <div
      class="lot-lookup-multi__top-row flex flex-col flex-wrap lg:flex-row gap-2 lg:justify-between items-center w-full"
    >
      <h2 class="lot-lookup-multi__top-row__title text-center lg:text-left pb-2 lg:pb-0">
        Lot Comparison
      </h2>
      <div
        class="lot-lookup-multi__top-row__toggle flex items-center gap-2 print:hidden"
        v-if="route.name !== 'table'"
      >
        <label for="view-toggle" class="font-medium cursor-pointer">Table View</label>
        <y-toggle id="view-toggle" v-model="chartToggle" v-if="lotStore.lots.length < 4" />
        <y-toggle id="view-toggle" disabled v-else />
        <label for="view-toggle" class="font-medium cursor-pointer"
          >Chart View <small>(up to 3 lots)</small></label
        >
      </div>
      <div
        class="lot-lookup-multi__top-row__buttons flex gap-2 flex-wrap justify-center md:justify-end print:hidden"
      >
        <y-button
          icon="plus"
          background-color="transparent"
          label="Add Lot"
          :mobile-fill="false"
          @click="openLotSearchModal = true"
        />
        <div v-if="!chartToggle" class="relative" v-on-click-outside="closeFilterMenu">
          <y-button
            icon="filter-solid"
            background-color="transparent"
            label="Filters"
            :mobile-fill="false"
            @click="openFilterMenu = !openFilterMenu"
          />
          <FilterMenu :open="openFilterMenu" :is-chart="isChart" />
        </div>
        <y-button
          icon="file-csv"
          background-color="transparent"
          label="Download"
          :mobile-fill="false"
          @click="download()"
        />
        <y-button
          icon="file-pdf"
          background-color="transparent"
          label="Print"
          :mobile-fill="false"
          @click="window.print()"
        />
      </div>
    </div>
    <div class="lot-lookup-multi__lot-list">
      <lot-lookup-table :is-chart="isChart" :lot-numbers="lotStore.lotNumbers" />
    </div>
    <div
      class="lot-lookup-multi__results grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 w-full"
      v-if="isChart"
    >
      <div
        class="lot-lookup-multi__results__col flex flex-col w-full gap-4"
        v-if="showAlphaBrewingSurvivableChartColumn"
      >
        <alpha-acid />
        <brewing-values />
        <survivable-compounds />
      </div>

      <div
        class="lot-lookup-multi__results__col flex flex-col w-full gap-4"
        v-if="showAromaChartColumn"
      >
        <aroma-profile />
        <aromas />
      </div>

      <div
        class="lot-lookup-multi__results__col flex flex-col w-full gap-4"
        v-if="showOilComponentsChartColumn"
      >
        <total-oil />
        <OilComponents />
        <OtherValues />
      </div>
    </div>

    <div class="lot-lookup-multi__lower-section flex flex-col gap-4 print:hidden">
      <div class="lot-lookup-multi__lower-section__analysis-methods flex flex-col gap-1">
        <small>Analysis Methods:</small>
        <small class="font-normal pb-2"
          >UV Alpha by ASBC HOPS UV Spectro - 6A, HPLC Alpha by EBC Analytica V 7.7, Mebak Alpha 7.5
          by EBC Analytica LCV V 7.5, HPLC Beta by EBC Analytica V 7.7, UV Beta by ASBC HOPS Spectro
          - 6A, Total Oils by ASBC Hops - 13 (%v/w), Oil Profile by ASBC Hops - 17, Hop Storage
          Index by ASBC Hops - 12, HPLC by EBC Analytica V 7.7, ASBC HOPS - 4C</small
        >
        <small>Terms of Use:</small>
        <small class="font-normal small">
          <ol class="list-decimal list-inside md:list-outside">
            <li class="pb-1 font-normal">
              The YCH R&D and Quality laboratories possess the capability to analyze hop oil via
              GCMS-SCD technology and quantify a vast array of aromatic components. This technology
              allows us to explore the aroma potential of novel hop compounds—specifically
              beer-soluble compounds that survive the brewing process. For more information, view
              our
              <a href="/documents/Survivable-Compounds-Handbook-2022.pdf" target="_blank"
                >Survivable Compounds Handbook</a
              >.
            </li>
            <li class="font-normal">
              Sensory characteristics are determined by averaging the percentage of assessors who
              reported the presence of each aroma category on a “Check-All-That-Apply” (CATA)
              ballot. It should be noted that the aroma profile of a hop does not necessarily
              translate to the flavor of a beer. For questions about YCH’s Sensory Program, please
              contact your Regional Sales Manager or email
              <a href="mailto:sensory@yakimachief.com">sensory@yakimachief.com</a>.
            </li>
          </ol>
        </small>
      </div>
    </div>
    <lot-search-modal v-model:open="openLotSearchModal" />
  </div>
</template>
<script setup lang="ts">
import { YButton, YToggle } from '@yakimachief/ych-ts-component-library'
import LotLookupTable from './LotLookupTable.vue'
import useLotStore from '@/stores/LotStore'
import { ref, computed } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import BrewingValues from '@/components/LotLookup/BrewingValues.vue'
import SurvivableCompounds from '@/components/LotLookup/SurvivableCompounds.vue'
import Aromas from '@/components/LotLookup/Aromas.vue'
import OilComponents from '@/components/LotLookup/OilComponents.vue'
import OtherValues from '@/components/LotLookup/OtherValues.vue'
import FilterMenu from '@/components/FilterMenu.vue'
import AlphaAcid from '@/components/LotLookup/AlphaAcid.vue'
import AromaProfile from '@/components/LotLookup/AromaProfile.vue'
import TotalOil from '@/components/LotLookup/TotalOil.vue'
import { useRoute, useRouter } from 'vue-router'

import {
  showAlphaBrewingSurvivableChartColumn,
  showAromaChartColumn,
  showOilComponentsChartColumn
} from '@/composables/filterSettings'
import LotSearchModal from '@/components/LotSearchModal.vue'

const route = useRoute()
const router = useRouter()

const lotStore = useLotStore()

const uvAlpha = ref(5.3)
const totalOil = ref(0.9)
const chartToggle = ref(true)
const openLotSearchModal = ref(false)

const isChart = computed(() => {
  if (route.name === 'table') {
    return false
  }
  if (lotStore.lots.length > 3) {
    return false
  }
  return chartToggle.value
})

const openFilterMenu = ref(false)

function closeFilterMenu() {
  if (openFilterMenu.value == true) {
    openFilterMenu.value = false
  }
}

function download() {
  const routeData = router.resolve({
    path: 'lookup/download',
    query: { 'lotNumber[]': lotStore.lotNumbers }
  })
  window.open(routeData.href, '_blank')
}
</script>
<style lang="scss">
.lot-lookup-multi {
  &__top-row {
    &__title {
      width: 21rem;
    }

    &__toggle label small {
      color: var(--ych-grey-400);
    }
  }

  &__results {
    &__col {
      &__alpha {
        &__title-row {
          background-color: var(--ych-grey-100);
          border-bottom: 3px solid var(--ych-red);
          height: 5rem;
        }
      }

      &__aroma {
        &__title-row {
          background-color: var(--ych-grey-100);
          border-bottom: 3px solid var(--ych-blue);
          height: 5rem;
        }
      }

      &__total-oil {
        &__title-row {
          background-color: var(--ych-grey-100);
          border-bottom: 3px solid var(--ych-kraft-dark);
          height: 5rem;
        }
      }

      &__top-five,
      &__oils,
      &__other-values {
        &__title-row {
          background-color: var(--ych-grey-100);
          border-bottom: 2px solid var(--ych-grey-200);
        }
      }

      &__top-five__icons {
        &:not(:last-child) {
          border-bottom: 1px solid var(--ych-grey-200);
        }

        &__icon {
          font-size: 2rem;
        }
      }

      &__brewing-values__container__row {
        z-index: 1;
        height: 7.5rem;

        &__labels {
          border-right: 1px solid var(--ych-grey-200);

          small {
            display: flex;
            height: 1.5rem;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      &__other-values__container__row {
        z-index: 1;

        &__labels {
          border-right: 1px solid var(--ych-grey-200);

          small {
            display: flex;
            height: 1.5rem;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      &__oils__container__row,
      &__survivables__container__row {
        z-index: 1;

        &__labels {
          border-right: 1px solid var(--ych-grey-200);

          small {
            display: flex;
            height: 1.5rem;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.striping {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

  div {
    height: 1.5rem;
    width: 100%;
    background-color: var(--ych-white);

    &:nth-child(even) {
      background-color: var(--ych-grey-100);
    }
  }
}
</style>
