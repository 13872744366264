<template>
  <div class="qr-scanner" v-if="open">
    <y-spinner class="qr-scanner__loading" v-if="isLoading" width="10rem" />
    <StreamQrcodeBarcodeReader
      ref="refCamera"
      capture="shoot"
      @onloading="onLoading"
      @result="onResult"
      class="qr-scanner__full"
    >
      <!--These are empty to prevent slots from being filled automatically the StreamQrcodeBarcodeReader-->
      <template #actions="">
        <div></div>
      </template>

      <template #action-stop="">
        <div></div>
      </template>

      <template #action-facemode="{ onChangeFacemode }">
        <div class="qr-scanner__upper-area">
          <y-button
            class="qr-scanner__upper-area__facemode-button"
            icon="camera-rotate"
            @click="onChangeFacemode"
            background-color="primary"
            label=""
            :mobile-fill="false"
            width="4rem"
          ></y-button>
        </div>
        <div class="qr-scanner__middle-area">
          <hr class="qr-scanner__middle-area__red-line" />
        </div>
        <div class="qr-scanner__lower-area">
          <span class="qr-scanner__lower-area__info-text" v-if="isLoading">Loading...</span>
          <span class="qr-scanner__lower-area__info-text" v-else
            >To scan, please place the red line over your YCH barcode or QR Code</span
          >
        </div>
      </template>
    </StreamQrcodeBarcodeReader>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { type Result, StreamQrcodeBarcodeReader } from 'vue3-barcode-qrcode-reader'
import { YButton, YSpinner } from '@yakimachief/ych-ts-component-library'

type QRScannerProperties = {
  open: boolean
  modelValue: string
}
const props = withDefaults(defineProps<QRScannerProperties>(), {
  open: false
})

const emit = defineEmits(['update:open', 'update:modelValue', 'updateFormat'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}

const isLoading = ref<boolean>(false)
const result = ref('')
const refCamera = ref<InstanceType<typeof StreamQrcodeBarcodeReader> | null>(null)

watch(
  () => props.open,
  function () {
    if (props.open) {
      startStreamWhenCameraActive()
    }
  }
)

watch(isLoading, (oldValue, newValue) => {
  if (isLoading.value) {
    startStreamWhenCameraActive()
  }
})

function startStreamWhenCameraActive(): void {
  if (refCamera.value == null) {
    window.setTimeout(startStreamWhenCameraActive, 100)
  } else {
    handleOnCanPlay()
  }
}

function onResult(data: Result | undefined): void {
  if (data) {
    result.value = parseDetectedResult(data.text)

    emit('updateFormat', [data.getBarcodeFormat()])
    if (props.modelValue === '') {
      emit('update:modelValue', result.value.toUpperCase())
    } else {
      let lots = props.modelValue.split(',').map((lot) => {
        return lot.trim()
      })
      lots.push(result.value.toUpperCase())
      let lotList = [...new Set(lots)]

      emit('update:modelValue', lotList.join(', '))
    }

    emit('update:open', false)

    result.value = ''
  }
}

function parseDetectedResult(detectedResult: string) {
  let result = detectedResult.replace(/@"\s"/, '')

  if (result.includes(String.fromCharCode(29))) {
    //Parse lot from datamatrix
    const splitText = result.split(String.fromCharCode(29))
    if (splitText.length && splitText[2].startsWith('10')) {
      result = splitText[2].substring(2)
    }
  } else if (result.startsWith('https://')) {
    // Parse lot from core sample webaddress
    const last = result.split('/').pop()
    result = last !== undefined ? last : ''
  }
  return result
}

function onLoading(loaded: boolean) {
  isLoading.value = !loaded
}

function handleOnCanPlay() {
  refCamera.value?.onCanPlay()
}

function handleOnReset() {
  refCamera.value?.onReset()
}

function handleFacemode() {
  refCamera.value?.onChangeFacemode()
}

function handleOnCanStop() {
  refCamera.value?.onCanStop()
}
</script>
<style scoped lang="scss">
.qr-scanner {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--ych-grey-800);

  &__full {
    position: static;
  }

  &__upper-area {
    height: 28vh;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: var(--ych-black-75);

    &__facemode-button {
      width: 4rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 1rem;

      .y-button__button--transparent {
        color: var(--ych-white);

        &:hover {
          background-color: var(--ych-white-10);
        }
      }

      .y-button__button--disabled {
        color: var(--ych-white-20);
        pointer-events: none;
      }
    }
  }

  &__loading {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 12rem;
  }

  &__middle-area {
    height: 100%;
    width: 100%;
    padding: 29vh 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    background-color: transparent;
    pointer-events: none;

    &__red-line {
      border-top: 0.125rem solid var(--ych-red);
      width: 28rem;
      position: absolute;
      transform: translateX(-50%);
      top: 50%;
      left: 50%;
    }

    &__detected,
    &__error {
      font-size: 1rem;
      font-weight: var(--y-font-weight-bold);
      line-height: 1.25rem;
      max-width: 80%;
      text-shadow:
        0 1.5px 3px rgba(40, 41, 40, 0.15),
        0 1px 2px rgba(40, 41, 40, 0.3);
    }

    &__detected {
      text-transform: uppercase;
      font-size: var(--y-font-size-lg);
      line-height: 1.5rem;
      letter-spacing: -0.00625rem;
      color: var(--ych-white);
    }

    &__error {
      color: var(--ych-red);
    }
  }

  &__lower-area {
    height: 28vh;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: var(--ych-black-75);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;

    &__info-text {
      text-align: center;
      width: 100%;
      color: var(--ych-white);
      font-weight: var(--y-font-weight-medium);
      text-shadow:
        0 1.5px 3px rgba(40, 41, 40, 0.15),
        0 1px 2px rgba(40, 41, 40, 0.3);
    }
  }

  &__no-camera {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--y-font-weight-bold);
    line-height: 1.25rem;
    text-shadow:
      0 1.5px 3px rgba(40, 41, 40, 0.15),
      0 1px 2px rgba(40, 41, 40, 0.3);
    color: var(--ych-red);
    pointer-events: none;
  }
}
</style>
