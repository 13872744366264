<template>
  <div
    class="lot-lookup min-content-height limit-width w-full h-full flex flex-col justify-start mx-auto items-center py-8 px-4 sm:px-12 gap-6"
  >
    <div v-if="isSingleLot" class="lot-lookup__search-box flex w-full items-center">
      <lot-search placeholder="Enter Lot Number..." width="35.125rem" class="print:hidden" />
      <h1 class="hidden print:block">{{ lotStore.lotNumbers.join(', ') }}</h1>
    </div>

    <template v-if="lotStore.loaded && lotStore.lots.length > 0">
      <lot-lookup-single v-if="isSingleLot" />
      <lot-lookup-multi v-if="!isSingleLot" />
    </template>
    <LotLookupErrors />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LotLookupSingle from '../components/LotLookup'
import LotLookupMulti from '../components/LotLookupMulti.vue'
import useLotStore from '@/stores/LotStore'
import LotSearch from '@/components/LotSearch.vue'
import LotLookupErrors from '@/components/LotLookup/LotLookupErrors.vue'
import { buildQueryParamObject, useShowBinNumbers } from '@/composables/lotBinNumber'

const lotStore = useLotStore()
const { showBinNumbers } = useShowBinNumbers()

const router = useRouter()
const route = useRoute()
const entryFormat = ref(100) //Default to Hand Entered

const getCurrentUrlLots = () => {
  const lotsQuery = route.query['lots[]']
  if (Array.isArray(lotsQuery)) {
    entryFormat.value = 101 // direct link
    return lotsQuery as string[]
  } else if (lotsQuery) {
    entryFormat.value = 101 // direct link
    return [lotsQuery]
  }
  return []
}

const refreshLots = async () => {
  await lotStore.fetchLots(getCurrentUrlLots(), entryFormat.value)
  router.replace({
    path: 'lookup',
    query: buildQueryParamObject(showBinNumbers.value, lotStore.lotNumbers)
  })
}

if (JSON.stringify(getCurrentUrlLots().sort()) !== JSON.stringify(lotStore.lotNumbers.sort())) {
  refreshLots()
}
const isSingleLot = computed(() => {
  return lotStore.lotNumbers.length <= 1
})
</script>
<style lang="scss" scoped></style>
