<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-400"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-400"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 modal-background" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="transition-all flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-800"
            enter-from="opacity-0 translate-y-10 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-800"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-16 sm:scale-90"
          >
            <DialogPanel
              class="analysis-methods relative transform overflow-hidden card text-left transition-all sm:my-8 w-full lg:max-w-[50rem] mx-0 lg:mx-0 sm:mx-12 sm:p-6"
            >
              <div class="analysis-methods flex flex-col gap-4">
                <div class="analysis-methods__top-row flex justify-between items-center">
                  <DialogTitle>Hop Analysis Methods</DialogTitle>
                  <y-button
                    icon="xmark"
                    width="2.75rem"
                    background-color="transparent"
                    :mobile-fill="false"
                    @click="setIsOpen(false)"
                  />
                </div>

                <div class="analysis-methods__content flex flex-col relative">
                  <div class="analysis-methods__content__wrapper flex flex-col gap-4">
                    <p>
                      Below, you’ll find a detailed explanation of the methods we use to analyze our
                      hops. At every step in a hop’s journey from the farm to you, we’re assessing
                      each lot’s physical characteristics as well as their aromatic profile. Having
                      a thorough understanding of these tests and results should allow you to be
                      more confident when switching between different lots of hops. Most of the
                      methods we employ were developed by the American Society of Brewing Chemists
                      (ASBC) or the European Brewing Convention (EBC). These organizations can
                      provide step-by-step descriptions for each of the methods summarized below.
                      When an ASBC or EBC method is used, it will be referenced in the description.
                    </p>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>ASBC Hops - 4C</h3>
                      <p>
                        <strong>Oven Moisture</strong> is determined by measuring the change in mass
                        of a sample as water is driven off in a convection oven.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>ASBC Hops – 13</h3>
                      <p>
                        <strong>Total Oil</strong> is measured through distillation. A solution of
                        water and a given hop sample are boiled. The oil volatilizes into the gas
                        phase and is collected via an Allihn condenser. The volume of oil is
                        measured. Total Oil can be used to compare the intensity of two lots of the
                        same variety (a lot with twice the total oil will likely be more intensely
                        aromatic). However, since the majority of these oils do not survive the
                        brewing process when comparing different varieties, the specific
                        concentration of survivable compounds is a better method to use.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>ASBC Hops – 17</h3>
                      <p>
                        <strong
                          >β-Pinene, Myrcene, Linalool, Caryophyllene, Farnesene, Humulene,</strong
                        >
                        and <strong>Geraniol</strong> oil percentages are all determined using a
                        GC-FID or Gas Chromatograph with a Flame Ionization Detector. Data from a
                        GC-FID is the standard that most hop suppliers use to describe hop oil
                        profiles. A more accurate concentration can be calculated using a GC with a
                        Mass Spectrometer (see Survivables Concentration).
                      </p>
                    </div>

                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>ASBC Hops – 6A</h3>
                      <p>
                        <strong>Alpha</strong> and <strong>Beta acid</strong> concentrations can be
                        measured in multiple ways. Different regions generally utilize different
                        methods, which can give slightly different values. Our advice is to continue
                        using the results from the method you normally refer to. If you aren’t sure
                        what method you’ve been using, use the UV spectrophotometry method; it's
                        what we default to on most marketing documents. In the United States and
                        Canada, the most commonly used method for measuring
                        <strong>Alpha</strong> and <strong>Beta acid</strong> is via UV
                        Spectrophotometry. In this method, Alpha and beta acids are extracted from
                        hop samples using solvents. Absorbance readings are taken, and alpha and
                        beta acid levels are calculated using a formula.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>EBC Analytica 7.7</h3>
                      <p>
                        Alternatively, concentrations of <strong>Alpha, Beta,</strong> as well as
                        <strong>Cohumulone</strong>, and <strong>Colupulone</strong> can be
                        determined via High-Performance Liquid Chromatography (HPLC). This method is
                        more commonly used in Europe. An HPLC separates individual compounds, and a
                        diode array detector quantifies the concentration of each as they elute out
                        of the chromatography column.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>EBC Analytica 7.4</h3>
                      <p>
                        A third method for detecting <strong>Alpha</strong> and
                        <strong>Beta acids</strong> is usually called the MEBAK method, as the
                        Central European Commission developed it for Brewing Analysis. This method
                        is commonly used by brewers in Europe and Asia. The method utilizes lead
                        conductance values via titration to determine the concentration of these hop
                        compounds.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>EBC Analytica 7.8</h3>
                      <p>
                        <strong>Iso-alpha acid</strong> concentrations can also be measured using
                        HPLC.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>EBC Analytica 7.9</h3>
                      <p>
                        Concentrations of advanced products like <strong>Rho</strong>,
                        <strong>Tetra</strong>, and <strong>Hexa</strong> are measured using an
                        HPLC.
                      </p>
                    </div>
                    <div class="analysis-methods__content__wrapper__item flex flex-col gap-2">
                      <h3>GC-MS</h3>
                      <p>
                        Survivable Compounds (<strong
                          >2-Methylbutyl-isobutyrate, 2-Nonanone, 3-Mercaptohexanol, Geraniol,
                          Isoamyl isobutyrate, Isobutyl isobutyrate, Methyl geranate,
                          Linalool</strong
                        >) are currently quantified using a GC-MS. Our method allows us to precisely
                        measure the amount of each survivable compound in a hop or beer sample. This
                        data should be more predictive than the normal total oil and GC-FID results.
                        These results will always be measured in parts per million (ppm). This is an
                        exact percentage within the hop sample rather than a percentage of the Total
                        Oil (as with the GC-FID method). Our method of analysis has been validated
                        internally but not by an outside group at this time.
                      </p>
                    </div>
                  </div>
                  <div class="inner-shadow"></div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { YButton } from '@yakimachief/ych-ts-component-library'

type AnalysisMethodsProperties = {
  open: boolean
}
withDefaults(defineProps<AnalysisMethodsProperties>(), {
  open: false
})

const emit = defineEmits(['update:open'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: var(--ych-black-20);
}

.analysis-methods__content__wrapper {
  overflow-y: auto;
  max-height: 33rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}

.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.75rem;
  pointer-events: none;
  z-index: 10;
  background: -webkit-linear-gradient(90deg, rgba(22, 22, 22, 0.15), transparent);
  background: linear-gradient(0deg, rgba(22, 22, 22, 0.15), transparent);
}
</style>
