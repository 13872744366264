import type { validIconsType } from '@yakimachief/ych-ts-component-library/dist/types/components/YIcon/YIconLibrary'

const getProductSlug = (productName: string): validIconsType => {
  if (productName.toLowerCase().includes('american noble')) {
    return 'american-noble'
  }
  if (productName.toLowerCase().includes('cryo')) {
    return 'cryo'
  }
  if (productName.toLowerCase().includes('pellet')) {
    return 't90'
  }
  if (productName.toLowerCase().includes('leaf hops')) {
    return 'whole-leaf'
  }
  if (productName.toUpperCase().includes('extract')) {
    return 'extract'
  }

  return 'variety-cone'
}

const getProductColor = (productName: string) => {
  productName = productName.toLowerCase()
  if (productName.includes('90')) {
    return '#D22630'
  } else if (productName.includes('cryo')) {
    return '#1E99CC'
  } else if (productName.includes('leaf')) {
    return '#556524'
  } else if (productName.includes('extract')) {
    return '#C7531E'
  } else if (productName.includes('american noble hops')) {
    return '#772E89'
  } else return '#002B49'
}

const lotColors = ['#D22630', '#002B49', '#D2AB89']

function getLotColor(index: number) {
  if (typeof lotColors[index] !== undefined) {
    return lotColors[index]
  }
  return '#ffffff'
}

export { getProductSlug, getProductColor, lotColors, getLotColor }
