import { computed } from 'vue'
import useLotStore from '@/stores/LotStore'
import type Lot from '@/types/Lot'

export const hasChartOilComponents = computed(() => {
  const lotStore = useLotStore()
  let hasOilComponents = false
  lotStore.lots.forEach((lot) => {
    if (lotHasChartOilComponents(lot)) {
      hasOilComponents = true
    }
  })
  return hasOilComponents
})

export function lotHasChartOilComponents(lot: Lot) {
  if (lot.oilComponents) {
    if (
      lot.oilComponents.betaPinene ||
      lot.oilComponents.myrcene ||
      lot.oilComponents.linalool ||
      lot.oilComponents.caryophyllene ||
      lot.oilComponents.farnesene ||
      lot.oilComponents.humulene ||
      lot.oilComponents.geraniol ||
      lot.oilComponents.other
    ) {
      return true
    }
  }
  return false
}

export default hasChartOilComponents
