export const formula = 'Tinseth'
export const time: string = 'min'
export const ounces: string = 'oz'
export const gallons: string = 'gal'
export const barrels: string = 'bbl'
export const liters: string = 'l'
export const hectoliters: string = 'hL'
export const pounds: string = 'lb'
export const grams: string = 'g'
export const kilograms: string = 'kg'
export const fahrenheit: string = '\xB0F'
export const centigrade: string = '\xB0C'
export const specificGravity: string = 'SG'
export const degreesPlato: string = '\xB0P'
export const defaultLeafHops: number = 25
export const defaultNobleHops: number = 27
export const defaultHopPellets: number = 30
export const defaultCryoHops: number = 33
export const defaultExtract: number = 35

export const productOptions = [
  {
    utilizationPercent: defaultLeafHops,
    name: 'Leaf Hops'
  },
  {
    utilizationPercent: defaultCryoHops,
    name: 'Cryo Hops'
  },
  {
    utilizationPercent: defaultNobleHops,
    name: 'Noble Hops'
  },
  {
    utilizationPercent: defaultHopPellets,
    name: 'Hop Pellets'
  },
  {
    utilizationPercent: defaultExtract,
    name: 'Extract'
  }
]
