<template>
  <div class="calculators flex flex-col w-full gap-6 py-12 justify-center items-center flex-auto">
    <h2>Brewing Calculators</h2>
    <div
      class="calculators__buttons limit-width flex flex-col md:flex-row gap-4 justify-center items-center"
    >
      <button class="calculators__buttons__cryo" @enter="navigateToCryo" @click="navigateToCryo">
        <i class="calculators__buttons__cryo__icon fa-kit fa-cryo"></i>Cryo Calculator
      </button>
      <button class="calculators__buttons__ibu" @enter="navigateToIBU" @click="navigateToIBU">
        <i class="calculators__buttons__ibu__icon fa-kit fa-variety-cone"></i>IBU Estimator
      </button>

      <button
        class="calculators__buttons__extract"
        @enter="navigateToExtract"
        @click="navigateToExtract"
      >
        <i class="calculators__buttons__extract__icon fa-kit fa-extract"></i>Extract Conversion
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

const navigateToExtract = () => {
  router.push({ name: 'extract-conversion' })
}
const navigateToIBU = () => {
  router.push({ name: 'kettle-ibu-calculator' })
}
const navigateToCryo = () => {
  router.push({ name: 'cryo-calculator' })
}
</script>
<style lang="scss">
.calculators {
  border-top: 1px solid var(--ych-grey-200);
  background-color: var(--ych-grey-100);

  &__buttons {
    &__cryo {
      display: flex;
      width: 12.375rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      padding: 0.75rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: var(--y-radius-md);
      background: var(--ych-cryo);
      color: var(--ych-white);
      font-weight: var(--y-font-weight-medium);

      &__icon {
        font-size: var(--y-font-size-lg);
      }

      &:hover {
        box-shadow: var(--y-shadow-blue-md);
      }

      &:active {
        background-color: #0a7eac;
        box-shadow: none;
      }
    }

    &__ibu {
      display: flex;
      width: 12.375rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      padding: 0.75rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: var(--y-radius-md);
      background: var(--ych-red);
      color: var(--ych-white);
      font-weight: var(--y-font-weight-medium);

      &__icon {
        font-size: var(--y-font-size-lg);
      }

      &:hover {
        box-shadow: var(--y-shadow-red-md);
      }

      &:active {
        background-color: var(--ych-red-dark);
        box-shadow: none;
      }
    }

    &__extract {
      display: flex;
      width: 12.375rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      padding: 0.75rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: var(--y-radius-md);
      background: var(--ych-extract);
      color: var(--ych-white);
      font-weight: var(--y-font-weight-medium);

      &__icon {
        font-size: var(--y-font-size-lg);
      }

      &:hover {
        box-shadow: var(--y-shadow-grey-md);
      }

      &:active {
        background-color: #b94008;
        box-shadow: none;
      }
    }
  }
}
</style>
