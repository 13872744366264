import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { HarvestSample } from '@/types/Lot'

function useShowBinNumbers() {
  const route = useRoute()

  const showBinNumbers = ref(false)
  showBinNumbers.value = route.query['show_sample'] === '1'

  return { showBinNumbers }
}

function getBinDisplayString(harvestSample?: HarvestSample) {
  const route = useRoute()

  const binDisplayString = computed(() => {
    if (route.query['show_sample'] === '1') {
      return harvestSample?.bin ?? 'N/A'
    } else {
      return ''
    }
  })

  return binDisplayString.value
}

function buildQueryParamObject(showSample: boolean, lotNumbers: Array<string>) {
  const queryParamObject = computed(() => {
    if (showSample) {
      return { 'lots[]': lotNumbers, show_sample: '1' }
    } else {
      return { 'lots[]': lotNumbers }
    }
  })

  return queryParamObject.value
}

export { useShowBinNumbers, getBinDisplayString, buildQueryParamObject }
