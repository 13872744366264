<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-400"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-400"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 modal-background" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="transition-all flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-800"
            enter-from="opacity-0 translate-y-10 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-800"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-16 sm:scale-90"
          >
            <DialogPanel
              class="analysis-legend relative transform overflow-hidden card text-left transition-all sm:my-8 w-full lg:max-w-[50rem] mx-0 lg:mx-0 sm:mx-12 sm:p-6"
            >
              <div class="analysis-legend flex flex-col gap-4">
                <div class="analysis-legend__top-row flex justify-between items-center">
                  <DialogTitle>Hop Analysis Legend</DialogTitle>
                  <y-button
                    icon="xmark"
                    width="2.75rem"
                    background-color="transparent"
                    :mobile-fill="false"
                    @click="setIsOpen(false)"
                  />
                </div>

                <div class="analysis-legend__content flex flex-col relative">
                  <div class="analysis-legend__content__wrapper flex flex-col gap-4">
                    <p>
                      Delve into the intricacies of hop analysis with this collection of definitions
                      for key terms used in hop analysis. Enhances your understanding of analytical
                      data to make more informed decisions in the brewing process.
                    </p>
                    <div
                      class="analysis-legend__content__wrapper__item flex flex-col gap-1"
                      v-for="item in DefinitionsComposable"
                    >
                      <h3 class="normal-case">{{ uppercaseExceptBeta(item.title) }}</h3>
                      <p v-html="item.definition"></p>
                    </div>
                  </div>
                  <div class="inner-shadow"></div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { YButton } from '@yakimachief/ych-ts-component-library'
import DefinitionsComposable from '@/composables/definitions'
import { uppercaseExceptBeta } from '@/composables/definitions'

type AnalysisLegendProperties = {
  open: boolean
}
withDefaults(defineProps<AnalysisLegendProperties>(), {
  open: false
})

const emit = defineEmits(['update:open'])

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: var(--ych-black-20);
}

.analysis-legend__content__wrapper {
  overflow-y: auto;
  max-height: 33rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}

.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.75rem;
  pointer-events: none;
  z-index: 10;
  background: -webkit-linear-gradient(90deg, rgba(22, 22, 22, 0.15), transparent);
  background: linear-gradient(0deg, rgba(22, 22, 22, 0.15), transparent);
}
</style>
