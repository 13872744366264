import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import FloatingVue from 'floating-vue'
import App from './App.vue'
import { init as initFullStory, FullStory } from '@fullstory/browser'
import { createGtm } from '@gtm-support/vue-gtm'
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(FloatingVue)
app.config.globalProperties.window = window
app.mount('#app')

/**
 * FullStory
 */
// @todo: replace orgID with .env call
initFullStory({ orgId: 'D89WC' })

// These variables should allow for session replays in FullStory
const startOfPlayback = FullStory('getSession')
const playbackAtThisMomentInTime = FullStory('getSession', { format: 'url.now' })
app.config.globalProperties.$FullStory = FullStory

/**
 * Google Tag Manager (GTM)
 */
// @todo: replace GTM Container ID with .env call
app.use(
  createGtm({
    id: 'GTM-T3SCR7L',
    vueRouter: router
  })
)
