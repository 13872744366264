<template>
  <div
    v-if="lotStore.loading"
    class="flex w-full h-full justify-center align-center z-50 loading-overlay fixed inset-0 transition-opacity"
  >
    <y-spinner width="10rem" />
  </div>
  <AppHeader />
  <main class="min-content-height">
    <RouterView />
  </main>
  <AppFooter />
  <YToaster />
</template>
<script setup lang="ts">
import { RouterView } from 'vue-router'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { YSpinner, YToaster } from '@yakimachief/ych-ts-component-library'
import useLotStore from '@/stores/LotStore'

const lotStore = useLotStore()
</script>
<style lang="scss">
.loading-overlay {
  background-color: rgba(0, 43, 73, 0.5);
  z-index: 500;
}
</style>
