import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useVarietyStore = defineStore('varieties', () => {
  const varieties = ref()

  const fetchVarieties = async function () {
    const response = await fetch('/api/varieties')
    if (response.ok) {
      const responseJsonObject = await response.json()
      if (Array.isArray(responseJsonObject)) {
        varieties.value = responseJsonObject
      }
    }
  }
  return {
    varieties,
    fetchVarieties
  }
})

export default useVarietyStore
